/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/auction/v1/auction.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as dependency_2 from "./../../smartcontract/v1/smartcontract";
import * as pb_1 from "google-protobuf";
export namespace core.auction.v1 {
    export class Bid extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            symbol?: string;
            bidValue?: number;
            externalId?: string;
            userSub?: string;
            userNick?: string;
            hashBlockchain?: string;
            statusBlockchain?: dependency_2.core.smartcontract.v1.TransactionStatus;
            networkId?: string;
            managementDocument?: string;
            managementName?: string;
            isBest?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("bidValue" in data && data.bidValue != undefined) {
                    this.bidValue = data.bidValue;
                }
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
                if ("userSub" in data && data.userSub != undefined) {
                    this.userSub = data.userSub;
                }
                if ("userNick" in data && data.userNick != undefined) {
                    this.userNick = data.userNick;
                }
                if ("hashBlockchain" in data && data.hashBlockchain != undefined) {
                    this.hashBlockchain = data.hashBlockchain;
                }
                if ("statusBlockchain" in data && data.statusBlockchain != undefined) {
                    this.statusBlockchain = data.statusBlockchain;
                }
                if ("networkId" in data && data.networkId != undefined) {
                    this.networkId = data.networkId;
                }
                if ("managementDocument" in data && data.managementDocument != undefined) {
                    this.managementDocument = data.managementDocument;
                }
                if ("managementName" in data && data.managementName != undefined) {
                    this.managementName = data.managementName;
                }
                if ("isBest" in data && data.isBest != undefined) {
                    this.isBest = data.isBest;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 2) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get bidValue() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set bidValue(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get userSub() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set userSub(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get userNick() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set userNick(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get hashBlockchain() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set hashBlockchain(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get statusBlockchain() {
            return pb_1.Message.getFieldWithDefault(this, 9, dependency_2.core.smartcontract.v1.TransactionStatus.UNSPECIFIED) as dependency_2.core.smartcontract.v1.TransactionStatus;
        }
        set statusBlockchain(value: dependency_2.core.smartcontract.v1.TransactionStatus) {
            pb_1.Message.setField(this, 9, value);
        }
        get networkId() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set networkId(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get managementDocument() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set managementDocument(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        get managementName() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set managementName(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get isBest() {
            return pb_1.Message.getFieldWithDefault(this, 13, false) as boolean;
        }
        set isBest(value: boolean) {
            pb_1.Message.setField(this, 13, value);
        }
        static fromObject(data: {
            id?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            symbol?: string;
            bidValue?: number;
            externalId?: string;
            userSub?: string;
            userNick?: string;
            hashBlockchain?: string;
            statusBlockchain?: dependency_2.core.smartcontract.v1.TransactionStatus;
            networkId?: string;
            managementDocument?: string;
            managementName?: string;
            isBest?: boolean;
        }): Bid {
            const message = new Bid({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.bidValue != null) {
                message.bidValue = data.bidValue;
            }
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            if (data.userSub != null) {
                message.userSub = data.userSub;
            }
            if (data.userNick != null) {
                message.userNick = data.userNick;
            }
            if (data.hashBlockchain != null) {
                message.hashBlockchain = data.hashBlockchain;
            }
            if (data.statusBlockchain != null) {
                message.statusBlockchain = data.statusBlockchain;
            }
            if (data.networkId != null) {
                message.networkId = data.networkId;
            }
            if (data.managementDocument != null) {
                message.managementDocument = data.managementDocument;
            }
            if (data.managementName != null) {
                message.managementName = data.managementName;
            }
            if (data.isBest != null) {
                message.isBest = data.isBest;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                symbol?: string;
                bidValue?: number;
                externalId?: string;
                userSub?: string;
                userNick?: string;
                hashBlockchain?: string;
                statusBlockchain?: dependency_2.core.smartcontract.v1.TransactionStatus;
                networkId?: string;
                managementDocument?: string;
                managementName?: string;
                isBest?: boolean;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.symbol != null) {
                data.symbol = this.symbol;
            }
            if (this.bidValue != null) {
                data.bidValue = this.bidValue;
            }
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            if (this.userSub != null) {
                data.userSub = this.userSub;
            }
            if (this.userNick != null) {
                data.userNick = this.userNick;
            }
            if (this.hashBlockchain != null) {
                data.hashBlockchain = this.hashBlockchain;
            }
            if (this.statusBlockchain != null) {
                data.statusBlockchain = this.statusBlockchain;
            }
            if (this.networkId != null) {
                data.networkId = this.networkId;
            }
            if (this.managementDocument != null) {
                data.managementDocument = this.managementDocument;
            }
            if (this.managementName != null) {
                data.managementName = this.managementName;
            }
            if (this.isBest != null) {
                data.isBest = this.isBest;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.hasCreatedAt)
                writer.writeMessage(2, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.symbol.length)
                writer.writeString(3, this.symbol);
            if (this.bidValue != 0)
                writer.writeInt64(4, this.bidValue);
            if (this.externalId.length)
                writer.writeString(5, this.externalId);
            if (this.userSub.length)
                writer.writeString(6, this.userSub);
            if (this.userNick.length)
                writer.writeString(7, this.userNick);
            if (this.hashBlockchain.length)
                writer.writeString(8, this.hashBlockchain);
            if (this.statusBlockchain != dependency_2.core.smartcontract.v1.TransactionStatus.UNSPECIFIED)
                writer.writeEnum(9, this.statusBlockchain);
            if (this.networkId.length)
                writer.writeString(10, this.networkId);
            if (this.managementDocument.length)
                writer.writeString(11, this.managementDocument);
            if (this.managementName.length)
                writer.writeString(12, this.managementName);
            if (this.isBest != false)
                writer.writeBool(13, this.isBest);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Bid {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Bid();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 3:
                        message.symbol = reader.readString();
                        break;
                    case 4:
                        message.bidValue = reader.readInt64();
                        break;
                    case 5:
                        message.externalId = reader.readString();
                        break;
                    case 6:
                        message.userSub = reader.readString();
                        break;
                    case 7:
                        message.userNick = reader.readString();
                        break;
                    case 8:
                        message.hashBlockchain = reader.readString();
                        break;
                    case 9:
                        message.statusBlockchain = reader.readEnum();
                        break;
                    case 10:
                        message.networkId = reader.readString();
                        break;
                    case 11:
                        message.managementDocument = reader.readString();
                        break;
                    case 12:
                        message.managementName = reader.readString();
                        break;
                    case 13:
                        message.isBest = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Bid {
            return Bid.deserialize(bytes);
        }
    }
    export class Auction extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            externalId?: string;
            symbol?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            metaData?: Map<string, string>;
            lastSyncProduct?: dependency_1.google.protobuf.Timestamp;
            bid?: Bid[];
            lastBidUser?: Map<string, Bid>;
            finished?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("metaData" in data && data.metaData != undefined) {
                    this.metaData = data.metaData;
                }
                if ("lastSyncProduct" in data && data.lastSyncProduct != undefined) {
                    this.lastSyncProduct = data.lastSyncProduct;
                }
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
                if ("lastBidUser" in data && data.lastBidUser != undefined) {
                    this.lastBidUser = data.lastBidUser;
                }
                if ("finished" in data && data.finished != undefined) {
                    this.finished = data.finished;
                }
            }
            if (!this.metaData)
                this.metaData = new Map();
            if (!this.lastBidUser)
                this.lastBidUser = new Map();
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 4) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get metaData() {
            return pb_1.Message.getField(this, 5) as any as Map<string, string>;
        }
        set metaData(value: Map<string, string>) {
            pb_1.Message.setField(this, 5, value as any);
        }
        get lastSyncProduct() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 6) as dependency_1.google.protobuf.Timestamp;
        }
        set lastSyncProduct(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 6, value);
        }
        get hasLastSyncProduct() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get bid() {
            return pb_1.Message.getRepeatedWrapperField(this, Bid, 7) as Bid[];
        }
        set bid(value: Bid[]) {
            pb_1.Message.setRepeatedWrapperField(this, 7, value);
        }
        get lastBidUser() {
            return pb_1.Message.getField(this, 8) as any as Map<string, Bid>;
        }
        set lastBidUser(value: Map<string, Bid>) {
            pb_1.Message.setField(this, 8, value as any);
        }
        get finished() {
            return pb_1.Message.getFieldWithDefault(this, 9, false) as boolean;
        }
        set finished(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        static fromObject(data: {
            id?: string;
            externalId?: string;
            symbol?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            metaData?: {
                [key: string]: string;
            };
            lastSyncProduct?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            bid?: ReturnType<typeof Bid.prototype.toObject>[];
            lastBidUser?: {
                [key: string]: ReturnType<typeof Bid.prototype.toObject>;
            };
            finished?: boolean;
        }): Auction {
            const message = new Auction({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (typeof data.metaData == "object") {
                message.metaData = new Map(Object.entries(data.metaData));
            }
            if (data.lastSyncProduct != null) {
                message.lastSyncProduct = dependency_1.google.protobuf.Timestamp.fromObject(data.lastSyncProduct);
            }
            if (data.bid != null) {
                message.bid = data.bid.map(item => Bid.fromObject(item));
            }
            if (typeof data.lastBidUser == "object") {
                message.lastBidUser = new Map(Object.entries(data.lastBidUser).map(([key, value]) => [key, Bid.fromObject(value)]));
            }
            if (data.finished != null) {
                message.finished = data.finished;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                externalId?: string;
                symbol?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                metaData?: {
                    [key: string]: string;
                };
                lastSyncProduct?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                bid?: ReturnType<typeof Bid.prototype.toObject>[];
                lastBidUser?: {
                    [key: string]: ReturnType<typeof Bid.prototype.toObject>;
                };
                finished?: boolean;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            if (this.symbol != null) {
                data.symbol = this.symbol;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.metaData != null) {
                data.metaData = (Object.fromEntries)(this.metaData);
            }
            if (this.lastSyncProduct != null) {
                data.lastSyncProduct = this.lastSyncProduct.toObject();
            }
            if (this.bid != null) {
                data.bid = this.bid.map((item: Bid) => item.toObject());
            }
            if (this.lastBidUser != null) {
                data.lastBidUser = (Object.fromEntries)((Array.from)(this.lastBidUser).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.finished != null) {
                data.finished = this.finished;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.externalId.length)
                writer.writeString(2, this.externalId);
            if (this.symbol.length)
                writer.writeString(3, this.symbol);
            if (this.hasCreatedAt)
                writer.writeMessage(4, this.createdAt, () => this.createdAt.serialize(writer));
            for (const [key, value] of this.metaData) {
                writer.writeMessage(5, this.metaData, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (this.hasLastSyncProduct)
                writer.writeMessage(6, this.lastSyncProduct, () => this.lastSyncProduct.serialize(writer));
            if (this.bid.length)
                writer.writeRepeatedMessage(7, this.bid, (item: Bid) => item.serialize(writer));
            for (const [key, value] of this.lastBidUser) {
                writer.writeMessage(8, this.lastBidUser, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.finished != false)
                writer.writeBool(9, this.finished);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Auction {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Auction();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.externalId = reader.readString();
                        break;
                    case 3:
                        message.symbol = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.metaData as any, reader, reader.readString, reader.readString));
                        break;
                    case 6:
                        reader.readMessage(message.lastSyncProduct, () => message.lastSyncProduct = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 7:
                        reader.readMessage(message.bid, () => pb_1.Message.addToRepeatedWrapperField(message, 7, Bid.deserialize(reader), Bid));
                        break;
                    case 8:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.lastBidUser as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = Bid.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 9:
                        message.finished = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Auction {
            return Auction.deserialize(bytes);
        }
    }
    export class AuctionSummary extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            ongoingCount?: number;
            ongoingValue?: number;
            winningBidCount?: number;
            winningBidValue?: number;
            closedCount?: number;
            closedValue?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ongoingCount" in data && data.ongoingCount != undefined) {
                    this.ongoingCount = data.ongoingCount;
                }
                if ("ongoingValue" in data && data.ongoingValue != undefined) {
                    this.ongoingValue = data.ongoingValue;
                }
                if ("winningBidCount" in data && data.winningBidCount != undefined) {
                    this.winningBidCount = data.winningBidCount;
                }
                if ("winningBidValue" in data && data.winningBidValue != undefined) {
                    this.winningBidValue = data.winningBidValue;
                }
                if ("closedCount" in data && data.closedCount != undefined) {
                    this.closedCount = data.closedCount;
                }
                if ("closedValue" in data && data.closedValue != undefined) {
                    this.closedValue = data.closedValue;
                }
            }
        }
        get ongoingCount() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set ongoingCount(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get ongoingValue() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set ongoingValue(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get winningBidCount() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set winningBidCount(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get winningBidValue() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set winningBidValue(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get closedCount() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set closedCount(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get closedValue() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set closedValue(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            ongoingCount?: number;
            ongoingValue?: number;
            winningBidCount?: number;
            winningBidValue?: number;
            closedCount?: number;
            closedValue?: number;
        }): AuctionSummary {
            const message = new AuctionSummary({});
            if (data.ongoingCount != null) {
                message.ongoingCount = data.ongoingCount;
            }
            if (data.ongoingValue != null) {
                message.ongoingValue = data.ongoingValue;
            }
            if (data.winningBidCount != null) {
                message.winningBidCount = data.winningBidCount;
            }
            if (data.winningBidValue != null) {
                message.winningBidValue = data.winningBidValue;
            }
            if (data.closedCount != null) {
                message.closedCount = data.closedCount;
            }
            if (data.closedValue != null) {
                message.closedValue = data.closedValue;
            }
            return message;
        }
        toObject() {
            const data: {
                ongoingCount?: number;
                ongoingValue?: number;
                winningBidCount?: number;
                winningBidValue?: number;
                closedCount?: number;
                closedValue?: number;
            } = {};
            if (this.ongoingCount != null) {
                data.ongoingCount = this.ongoingCount;
            }
            if (this.ongoingValue != null) {
                data.ongoingValue = this.ongoingValue;
            }
            if (this.winningBidCount != null) {
                data.winningBidCount = this.winningBidCount;
            }
            if (this.winningBidValue != null) {
                data.winningBidValue = this.winningBidValue;
            }
            if (this.closedCount != null) {
                data.closedCount = this.closedCount;
            }
            if (this.closedValue != null) {
                data.closedValue = this.closedValue;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ongoingCount != 0)
                writer.writeInt64(1, this.ongoingCount);
            if (this.ongoingValue != 0)
                writer.writeInt64(2, this.ongoingValue);
            if (this.winningBidCount != 0)
                writer.writeInt64(3, this.winningBidCount);
            if (this.winningBidValue != 0)
                writer.writeInt64(4, this.winningBidValue);
            if (this.closedCount != 0)
                writer.writeInt64(5, this.closedCount);
            if (this.closedValue != 0)
                writer.writeInt64(6, this.closedValue);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuctionSummary {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuctionSummary();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.ongoingCount = reader.readInt64();
                        break;
                    case 2:
                        message.ongoingValue = reader.readInt64();
                        break;
                    case 3:
                        message.winningBidCount = reader.readInt64();
                        break;
                    case 4:
                        message.winningBidValue = reader.readInt64();
                        break;
                    case 5:
                        message.closedCount = reader.readInt64();
                        break;
                    case 6:
                        message.closedValue = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AuctionSummary {
            return AuctionSummary.deserialize(bytes);
        }
    }
}
