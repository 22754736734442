import * as Transaction from "@libs/proto/core/transaction/v1/transaction";
import * as TransactionApi from "@libs/proto/core/transaction/v1/transaction_api";
import { ActionType, TransactionType } from "@libs/models/product";
import { AuthorizationInterceptor } from "@libs/interceptors/interceptor";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";

@Injectable({
  providedIn: "root",
})
export class GrpcTransactionService {
  private client: TransactionApi.core.transaction.v1.TransactionAPIClient;

  constructor(public mainService: MainService) {
    this.client = new TransactionApi.core.transaction.v1.TransactionAPIClient(mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
  }

  createTransaction(productId: string, extraData: Map<string, string>, action: TransactionType) {
    const request = new TransactionApi.core.transaction.v1.CreateTransactionRequest({
      externalServiceId: productId,
      extraData,
      transactionType:
        action === TransactionType.PRODUCT
          ? Transaction.core.transaction.v1.TransactionType.BLOCKCHAIN_MINT_SMART_CONTRACT
          : Transaction.core.transaction.v1.TransactionType.BLOCKCHAIN_TRANSFER_SMART_CONTRACT,
    });
    return this.client.CreateTransaction(request, {});
  }

  reprocessTransaction(productIds: string[]) {
    const request = new TransactionApi.core.transaction.v1.ReprocessTransactionsBatchRequest({
      autoSigned: true,
      externalServiceIds: productIds,
    });
    return this.client.ReprocessTransactionsBatch(request, {});
  }

  createTransactionBatch(externalServiceIds: string[], actionType: ActionType, registrarC3: string) {

    console.log('chegou aqui', registrarC3);

    let transactionType = null;

    if(actionType === "burn") {
      transactionType = Transaction.core.transaction.v1.TransactionType.BLOCKCHAIN_BURN_TOKEN;
    }else if (registrarC3 == "true"){
      transactionType = Transaction.core.transaction.v1.TransactionType.BLOCKCHAIN_C3_REGISTER_TOKEN;
    } else {
      transactionType = Transaction.core.transaction.v1.TransactionType.BLOCKCHAIN_MINT_SMART_CONTRACT;
    }


    const request = new TransactionApi.core.transaction.v1.CreateTransactionBatchRequest({
      autoSigned: true,
      externalServiceIds,
      transactionType
    });
    return this.client.CreateTransactionBatch(request, {});
  }

  assign(transactionId: string) {
    const request = new TransactionApi.core.transaction.v1.SignTransactionRequest({
      transactionId,
    });
    return this.client.SignTransaction(request, {});
  }

  listTranfers(externalId: string, itemsPerPage = 10, page = 1) {
    const transactionType: Transaction.core.transaction.v1.TransactionType =
      Transaction.core.transaction.v1.TransactionType.BLOCKCHAIN_TRANSFER_SMART_CONTRACT;
    const request = new TransactionApi.core.transaction.v1.ListTransactionRequest({
      externalId,
      transactionType,
      itemsPerPage,
      page,
    });
    return this.client.ListTransactions(request, {});
  }

  listProductTransactions(externalId, itemsPerPage = 10, page = 1) {
    const request = new TransactionApi.core.transaction.v1.ListTransactionRequest({
      externalId,
      itemsPerPage,
      page,
    });
    return this.client.ListTransactions(request, {});
  }

  getTransactionDetails(transactionId: string) {
    const request = new TransactionApi.core.transaction.v1.GetTransactionRequest({
      transactionId,
    });
    return this.client.GetTransaction(request, {});
  }
}
