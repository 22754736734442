import * as Api from "@libs/proto/core/billing/v1/billing_api";
import * as Billing from "@libs/proto/core/billing/v1/billing";
import { google } from "@libs/proto/google/protobuf/timestamp";

import { AuthorizationInterceptor } from "@libs/interceptors/interceptor";
import { BillingParams } from "@libs/models/billing";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";
import { TransactionPricing } from "@libs/models/tax";

@Injectable({
  providedIn: "root",
})
export class GrpcBillingService {
  private client: Api.core.billing.v1.BillingAPIClient;

  constructor(public mainService: MainService) {
    this.client = new Api.core.billing.v1.BillingAPIClient(mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
  }

  listTickets(params: BillingParams = {}) {
    const request = new Api.core.billing.v1.GetTicketListRequest(params);
    return this.client.GetTicketList(request, {});
  }

  listTaxes() {
    const request = new Api.core.billing.v1.ListTableFeeRequest();
    return this.client.ListTableFee(request, {});
  }

  saveTableFee(companyId: string, pricingList: TransactionPricing, publishDateTimestamp: google.protobuf.Timestamp) {
    const mapPricing = new Map<string, Billing.core.billing.v1.PricingList>();
    Object.keys(pricingList).forEach((key) => {
      if (key !== "quantity") {
        const currentPriceList = pricingList[key].pricing.map((pricing) => {
          return new Billing.core.billing.v1.Pricing({
            min: pricing.min,
            max: pricing.max,
            value: pricing.value,
            registeredValue: pricing.registeredValue,
            discountPercent: Number(pricing.discountPercent),
            quantity: pricing.quantity,
            packagePrice: pricing.packagePrice,
            spent: pricing.spent,
          });
        });
        const obj = new Billing.core.billing.v1.PricingList({ pricing: currentPriceList });
        mapPricing.set(key, obj);
      }
    });
    const params = {
      companyId,
      pricingList: mapPricing,
      effectiveDate: publishDateTimestamp,
    };

    const request = new Api.core.billing.v1.SaveTableFeeRequest(params);
    return this.client.SaveTableFee(request, {});
  }

  listFinancialReport(page: number, itemsPerPage: number) {
    const request = new Api.core.billing.v1.ListBillingReportsRequest({
      page: page ?? 1,
      itemsPerPage: itemsPerPage ?? 10,
    });
    return this.client.ListBillingReports(request, {});
  }

  getTableFeeHistory(companyId: string, page?: number, itemsPerPage?: number) {
    const request = new Api.core.billing.v1.CompanyTableFeeHistoryRequest({
      companyId: companyId,
      page: page ?? 1,
      itemsPerPage: itemsPerPage ?? 10,
    });
    return this.client.CompanyTableFeeHistory(request, {});
  }

  reprocessFinancialReport(id: string) {
    const request = new Api.core.billing.v1.ReprocessBillingReportRequest({
      id,
    });
    return this.client.ReprocessBillingReport(request, {});
  }

  donwloadFinancialReport(filename: string) {
    const request = new Api.core.billing.v1.DownloadBillingReportRequest({
      filename,
    });
    return this.client.DownloadBillingReport(request, {});
  }
}
