/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/profile/v1/profile_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./profile";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.profile.v1 {
    export class CreateProfileRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            profile?: dependency_1.core.profile.v1.Profile;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
            }
        }
        get profile() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.profile.v1.Profile, 1) as dependency_1.core.profile.v1.Profile;
        }
        set profile(value: dependency_1.core.profile.v1.Profile) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProfile() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
        }): CreateProfileRequest {
            const message = new CreateProfileRequest({});
            if (data.profile != null) {
                message.profile = dependency_1.core.profile.v1.Profile.fromObject(data.profile);
            }
            return message;
        }
        toObject() {
            const data: {
                profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
            } = {};
            if (this.profile != null) {
                data.profile = this.profile.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProfile)
                writer.writeMessage(1, this.profile, () => this.profile.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateProfileRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateProfileRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.profile, () => message.profile = dependency_1.core.profile.v1.Profile.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateProfileRequest {
            return CreateProfileRequest.deserialize(bytes);
        }
    }
    export class CreateProfileResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            profile?: dependency_1.core.profile.v1.Profile;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
            }
        }
        get profile() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.profile.v1.Profile, 1) as dependency_1.core.profile.v1.Profile;
        }
        set profile(value: dependency_1.core.profile.v1.Profile) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProfile() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
        }): CreateProfileResponse {
            const message = new CreateProfileResponse({});
            if (data.profile != null) {
                message.profile = dependency_1.core.profile.v1.Profile.fromObject(data.profile);
            }
            return message;
        }
        toObject() {
            const data: {
                profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
            } = {};
            if (this.profile != null) {
                data.profile = this.profile.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProfile)
                writer.writeMessage(1, this.profile, () => this.profile.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateProfileResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateProfileResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.profile, () => message.profile = dependency_1.core.profile.v1.Profile.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateProfileResponse {
            return CreateProfileResponse.deserialize(bytes);
        }
    }
    export class UpdateProfileRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            profile?: dependency_1.core.profile.v1.Profile;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
            }
        }
        get profile() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.profile.v1.Profile, 1) as dependency_1.core.profile.v1.Profile;
        }
        set profile(value: dependency_1.core.profile.v1.Profile) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProfile() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
        }): UpdateProfileRequest {
            const message = new UpdateProfileRequest({});
            if (data.profile != null) {
                message.profile = dependency_1.core.profile.v1.Profile.fromObject(data.profile);
            }
            return message;
        }
        toObject() {
            const data: {
                profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
            } = {};
            if (this.profile != null) {
                data.profile = this.profile.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProfile)
                writer.writeMessage(1, this.profile, () => this.profile.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateProfileRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateProfileRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.profile, () => message.profile = dependency_1.core.profile.v1.Profile.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateProfileRequest {
            return UpdateProfileRequest.deserialize(bytes);
        }
    }
    export class UpdateProfileResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            profile?: dependency_1.core.profile.v1.Profile;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
            }
        }
        get profile() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.profile.v1.Profile, 1) as dependency_1.core.profile.v1.Profile;
        }
        set profile(value: dependency_1.core.profile.v1.Profile) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProfile() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
        }): UpdateProfileResponse {
            const message = new UpdateProfileResponse({});
            if (data.profile != null) {
                message.profile = dependency_1.core.profile.v1.Profile.fromObject(data.profile);
            }
            return message;
        }
        toObject() {
            const data: {
                profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
            } = {};
            if (this.profile != null) {
                data.profile = this.profile.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProfile)
                writer.writeMessage(1, this.profile, () => this.profile.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateProfileResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateProfileResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.profile, () => message.profile = dependency_1.core.profile.v1.Profile.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateProfileResponse {
            return UpdateProfileResponse.deserialize(bytes);
        }
    }
    export class GetProfileRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            profileType?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("profileType" in data && data.profileType != undefined) {
                    this.profileType = data.profileType;
                }
            }
        }
        get profileType() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set profileType(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            profileType?: string;
        }): GetProfileRequest {
            const message = new GetProfileRequest({});
            if (data.profileType != null) {
                message.profileType = data.profileType;
            }
            return message;
        }
        toObject() {
            const data: {
                profileType?: string;
            } = {};
            if (this.profileType != null) {
                data.profileType = this.profileType;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.profileType.length)
                writer.writeString(1, this.profileType);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProfileRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProfileRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.profileType = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProfileRequest {
            return GetProfileRequest.deserialize(bytes);
        }
    }
    export class GetProfileResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            profile?: dependency_1.core.profile.v1.Profile;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
            }
        }
        get profile() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.profile.v1.Profile, 1) as dependency_1.core.profile.v1.Profile;
        }
        set profile(value: dependency_1.core.profile.v1.Profile) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProfile() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
        }): GetProfileResponse {
            const message = new GetProfileResponse({});
            if (data.profile != null) {
                message.profile = dependency_1.core.profile.v1.Profile.fromObject(data.profile);
            }
            return message;
        }
        toObject() {
            const data: {
                profile?: ReturnType<typeof dependency_1.core.profile.v1.Profile.prototype.toObject>;
            } = {};
            if (this.profile != null) {
                data.profile = this.profile.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProfile)
                writer.writeMessage(1, this.profile, () => this.profile.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProfileResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProfileResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.profile, () => message.profile = dependency_1.core.profile.v1.Profile.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProfileResponse {
            return GetProfileResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedProfileServiceService {
        static definition = {
            CreateProfile: {
                path: "/core.profile.v1.ProfileService/CreateProfile",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateProfileRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateProfileRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateProfileResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateProfileResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateProfile: {
                path: "/core.profile.v1.ProfileService/UpdateProfile",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateProfileRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateProfileRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateProfileResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateProfileResponse.deserialize(new Uint8Array(bytes))
            },
            GetProfile: {
                path: "/core.profile.v1.ProfileService/GetProfile",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetProfileRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetProfileRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetProfileResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetProfileResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract CreateProfile(call: grpc_1.ServerUnaryCall<CreateProfileRequest, CreateProfileResponse>, callback: grpc_1.sendUnaryData<CreateProfileResponse>): void;
        abstract UpdateProfile(call: grpc_1.ServerUnaryCall<UpdateProfileRequest, UpdateProfileResponse>, callback: grpc_1.sendUnaryData<UpdateProfileResponse>): void;
        abstract GetProfile(call: grpc_1.ServerUnaryCall<GetProfileRequest, GetProfileResponse>, callback: grpc_1.sendUnaryData<GetProfileResponse>): void;
    }
    export class ProfileServiceClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static CreateProfile = new grpc_web_1.MethodDescriptor<CreateProfileRequest, CreateProfileResponse>("/core.profile.v1.ProfileService/CreateProfile", grpc_web_1.MethodType.UNARY, CreateProfileRequest, CreateProfileResponse, (message: CreateProfileRequest) => message.serialize(), CreateProfileResponse.deserialize);
        CreateProfile(message: CreateProfileRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateProfileRequest, CreateProfileResponse>(this._address + "/core.profile.v1.ProfileService/CreateProfile", message, metadata || {}, ProfileServiceClient.CreateProfile);
        }
        private static UpdateProfile = new grpc_web_1.MethodDescriptor<UpdateProfileRequest, UpdateProfileResponse>("/core.profile.v1.ProfileService/UpdateProfile", grpc_web_1.MethodType.UNARY, UpdateProfileRequest, UpdateProfileResponse, (message: UpdateProfileRequest) => message.serialize(), UpdateProfileResponse.deserialize);
        UpdateProfile(message: UpdateProfileRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateProfileRequest, UpdateProfileResponse>(this._address + "/core.profile.v1.ProfileService/UpdateProfile", message, metadata || {}, ProfileServiceClient.UpdateProfile);
        }
        private static GetProfile = new grpc_web_1.MethodDescriptor<GetProfileRequest, GetProfileResponse>("/core.profile.v1.ProfileService/GetProfile", grpc_web_1.MethodType.UNARY, GetProfileRequest, GetProfileResponse, (message: GetProfileRequest) => message.serialize(), GetProfileResponse.deserialize);
        GetProfile(message: GetProfileRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetProfileRequest, GetProfileResponse>(this._address + "/core.profile.v1.ProfileService/GetProfile", message, metadata || {}, ProfileServiceClient.GetProfile);
        }
    }
}
