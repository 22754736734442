/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/token_validation/v1/token_validation_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.tokenvalidation.v1 {
    export class ValidateTokenRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hash?: string;
            hashVerify?: string;
            recaptchaToken?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hash" in data && data.hash != undefined) {
                    this.hash = data.hash;
                }
                if ("hashVerify" in data && data.hashVerify != undefined) {
                    this.hashVerify = data.hashVerify;
                }
                if ("recaptchaToken" in data && data.recaptchaToken != undefined) {
                    this.recaptchaToken = data.recaptchaToken;
                }
            }
        }
        get hash() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set hash(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get hashVerify() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set hashVerify(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get recaptchaToken() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set recaptchaToken(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            hash?: string;
            hashVerify?: string;
            recaptchaToken?: string;
        }): ValidateTokenRequest {
            const message = new ValidateTokenRequest({});
            if (data.hash != null) {
                message.hash = data.hash;
            }
            if (data.hashVerify != null) {
                message.hashVerify = data.hashVerify;
            }
            if (data.recaptchaToken != null) {
                message.recaptchaToken = data.recaptchaToken;
            }
            return message;
        }
        toObject() {
            const data: {
                hash?: string;
                hashVerify?: string;
                recaptchaToken?: string;
            } = {};
            if (this.hash != null) {
                data.hash = this.hash;
            }
            if (this.hashVerify != null) {
                data.hashVerify = this.hashVerify;
            }
            if (this.recaptchaToken != null) {
                data.recaptchaToken = this.recaptchaToken;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hash.length)
                writer.writeString(1, this.hash);
            if (this.hashVerify.length)
                writer.writeString(2, this.hashVerify);
            if (this.recaptchaToken.length)
                writer.writeString(3, this.recaptchaToken);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidateTokenRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidateTokenRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hash = reader.readString();
                        break;
                    case 2:
                        message.hashVerify = reader.readString();
                        break;
                    case 3:
                        message.recaptchaToken = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ValidateTokenRequest {
            return ValidateTokenRequest.deserialize(bytes);
        }
    }
    export class ValidateTokenResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            tokenValid?: boolean;
            verificationValid?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("tokenValid" in data && data.tokenValid != undefined) {
                    this.tokenValid = data.tokenValid;
                }
                if ("verificationValid" in data && data.verificationValid != undefined) {
                    this.verificationValid = data.verificationValid;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get tokenValid() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set tokenValid(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        get verificationValid() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set verificationValid(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            tokenValid?: boolean;
            verificationValid?: boolean;
        }): ValidateTokenResponse {
            const message = new ValidateTokenResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.tokenValid != null) {
                message.tokenValid = data.tokenValid;
            }
            if (data.verificationValid != null) {
                message.verificationValid = data.verificationValid;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                tokenValid?: boolean;
                verificationValid?: boolean;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.tokenValid != null) {
                data.tokenValid = this.tokenValid;
            }
            if (this.verificationValid != null) {
                data.verificationValid = this.verificationValid;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.tokenValid != false)
                writer.writeBool(3, this.tokenValid);
            if (this.verificationValid != false)
                writer.writeBool(4, this.verificationValid);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidateTokenResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidateTokenResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.tokenValid = reader.readBool();
                        break;
                    case 4:
                        message.verificationValid = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ValidateTokenResponse {
            return ValidateTokenResponse.deserialize(bytes);
        }
    }
    export class AddHashRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hash?: string;
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hash" in data && data.hash != undefined) {
                    this.hash = data.hash;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get hash() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set hash(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            hash?: string;
            id?: string;
        }): AddHashRequest {
            const message = new AddHashRequest({});
            if (data.hash != null) {
                message.hash = data.hash;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                hash?: string;
                id?: string;
            } = {};
            if (this.hash != null) {
                data.hash = this.hash;
            }
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hash.length)
                writer.writeString(1, this.hash);
            if (this.id.length)
                writer.writeString(2, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddHashRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddHashRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hash = reader.readString();
                        break;
                    case 2:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddHashRequest {
            return AddHashRequest.deserialize(bytes);
        }
    }
    export class AddHashResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hash?: string;
            createAt?: string;
            ttl?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hash" in data && data.hash != undefined) {
                    this.hash = data.hash;
                }
                if ("createAt" in data && data.createAt != undefined) {
                    this.createAt = data.createAt;
                }
                if ("ttl" in data && data.ttl != undefined) {
                    this.ttl = data.ttl;
                }
            }
        }
        get hash() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set hash(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get createAt() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set createAt(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get ttl() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set ttl(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            hash?: string;
            createAt?: string;
            ttl?: number;
        }): AddHashResponse {
            const message = new AddHashResponse({});
            if (data.hash != null) {
                message.hash = data.hash;
            }
            if (data.createAt != null) {
                message.createAt = data.createAt;
            }
            if (data.ttl != null) {
                message.ttl = data.ttl;
            }
            return message;
        }
        toObject() {
            const data: {
                hash?: string;
                createAt?: string;
                ttl?: number;
            } = {};
            if (this.hash != null) {
                data.hash = this.hash;
            }
            if (this.createAt != null) {
                data.createAt = this.createAt;
            }
            if (this.ttl != null) {
                data.ttl = this.ttl;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hash.length)
                writer.writeString(1, this.hash);
            if (this.createAt.length)
                writer.writeString(2, this.createAt);
            if (this.ttl != 0)
                writer.writeInt64(3, this.ttl);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddHashResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddHashResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hash = reader.readString();
                        break;
                    case 2:
                        message.createAt = reader.readString();
                        break;
                    case 3:
                        message.ttl = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddHashResponse {
            return AddHashResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedTokenValidationAPIService {
        static definition = {
            Validate: {
                path: "/core.tokenvalidation.v1.TokenValidationAPI/Validate",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ValidateTokenRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ValidateTokenRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ValidateTokenResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ValidateTokenResponse.deserialize(new Uint8Array(bytes))
            },
            AddHash: {
                path: "/core.tokenvalidation.v1.TokenValidationAPI/AddHash",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: AddHashRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => AddHashRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: AddHashResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => AddHashResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract Validate(call: grpc_1.ServerUnaryCall<ValidateTokenRequest, ValidateTokenResponse>, callback: grpc_1.sendUnaryData<ValidateTokenResponse>): void;
        abstract AddHash(call: grpc_1.ServerUnaryCall<AddHashRequest, AddHashResponse>, callback: grpc_1.sendUnaryData<AddHashResponse>): void;
    }
    export class TokenValidationAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static Validate = new grpc_web_1.MethodDescriptor<ValidateTokenRequest, ValidateTokenResponse>("/core.tokenvalidation.v1.TokenValidationAPI/Validate", grpc_web_1.MethodType.UNARY, ValidateTokenRequest, ValidateTokenResponse, (message: ValidateTokenRequest) => message.serialize(), ValidateTokenResponse.deserialize);
        Validate(message: ValidateTokenRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ValidateTokenRequest, ValidateTokenResponse>(this._address + "/core.tokenvalidation.v1.TokenValidationAPI/Validate", message, metadata || {}, TokenValidationAPIClient.Validate);
        }
        private static AddHash = new grpc_web_1.MethodDescriptor<AddHashRequest, AddHashResponse>("/core.tokenvalidation.v1.TokenValidationAPI/AddHash", grpc_web_1.MethodType.UNARY, AddHashRequest, AddHashResponse, (message: AddHashRequest) => message.serialize(), AddHashResponse.deserialize);
        AddHash(message: AddHashRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<AddHashRequest, AddHashResponse>(this._address + "/core.tokenvalidation.v1.TokenValidationAPI/AddHash", message, metadata || {}, TokenValidationAPIClient.AddHash);
        }
    }
}
