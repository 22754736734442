import { ActivatedRoute, Router } from "@angular/router";
import { ChangeDetectorRef, Component, inject, NgZone } from "@angular/core";
import { GrpcAccountService } from "@libs/services/grpc-account/grpc-account.service";
import { GrpcProductService } from "@libs/services/grpc-product/grpc-product.service";
import { GrpcTransactionService } from "@libs/services/grpc-transaction/grpc-transaction.service";
import { Location, ViewportScroller } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SharedService } from "@libs/services/shared/shared.service";
import { StorageService } from "@libs/services/storage/storage.service";
import { CognitoService } from "@libs/services/cognito/cognito.service";
import { GrpcNotificationService } from "@libs/services/grpc-notification/grpc-notification.service";
import { GrpcTokenService } from "@libs/services/grpc-token/grpc-token.service";
import { GrpcAuctionService } from "@libs/services/grpc-auction/grpc-auction.service";
import { GrpcProfileService } from "@libs/services/grpc-profile/grpc-profile.service";
import { GrpcBillingService } from "@libs/services/grpc-billing/grpc-billing.service";
import { ModalAlertComponent } from "@libs/modules/nuclea/modal-alert/modal-alert.component";
import { ModalAlertParams } from "@libs/models/modal";
import { ToastComponent } from "@libs/components/toast/toast.component";

@Component({
  selector: "app-base",
  template: "",
  styles: [],
  standalone: true,
})
export class BaseComponent {
  public shared = inject(SharedService);
  public storage = inject(StorageService);
  public route = inject(ActivatedRoute);
  public router = inject(Router);
  public ref = inject(ChangeDetectorRef);
  public location = inject(Location);
  public dialog = inject(MatDialog);
  public grpcAccount = inject(GrpcAccountService);
  public grpcProduct = inject(GrpcProductService);
  public grpcTransaction = inject(GrpcTransactionService);
  public grpcNotification = inject(GrpcNotificationService);
  public grpcTokenService = inject(GrpcTokenService);
  public grpcAuctionService = inject(GrpcAuctionService);
  public grpcProfileService = inject(GrpcProfileService);
  public grpcBillingService = inject(GrpcBillingService);
  public cognitoService = inject(CognitoService);
  public ngZone = inject(NgZone);
  public viewportScroller = inject(ViewportScroller);

  private readonly snackBar = inject(MatSnackBar);

  loading: boolean = false;

  constructor() {}

  back() {
    this.location.back();
  }

  async copyClipboard(textToCopy: string, successMessage: string) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        this.showToast("success", successMessage);
      })
      .catch((error) => {
        this.showToast("error", error.message ? error.message : "Erro ao copiar ID");
      });
  }

  showAlert(params: ModalAlertParams) {
    this.dialog.open(ModalAlertComponent, {
      data: {
        titleHeader: params.titleHeader,
        layout: params.layout,
        message: params.message,
        btnLeft: params.btnLeft,
        callBackBtnLeft: params.callBackBtnLeft,
        btnRight: params.btnRight,
        callBackBtnRight: params.callBackBtnRight,
      },
    });

    this.dialog.afterAllClosed.subscribe(() => {
      if (this.router.url === '/confirm-code') {
        params.callBackBtnRight();
      }
    })
  }

  showSuccessAlert(titleHeader: string, message: string, callBack: Function) {
    this.showAlert({
      titleHeader,
      layout: "success",
      message,
      btnLeft: "Ok",
      callBackBtnLeft: callBack,
    });
  }

  showErrorAlert(titleHeader: string, message: string, callBack: Function) {
    this.showAlert({
      titleHeader,
      layout: "error",
      message,
      btnLeft: "Ok",
      callBackBtnLeft: callBack,
    });
  }

  showInfoAlert(titleHeader: string, message: string, callBack: Function) {
    this.showAlert({
      titleHeader,
      layout: "info",
      message,
      btnLeft: "Ok",
      callBackBtnLeft: callBack,
    });
  }

  showConfirmAlert(titleHeader: string, message: string, callBack: Function) {
    this.showAlert({
      titleHeader,
      layout: "info",
      message,
      btnLeft: "Cancelar",
      callBackBtnLeft: null,
      btnRight: "Confirmar",
      callBackBtnRight: callBack,
    });
  }

  public async showToast(type: "success" | "error", message: string, duration?: number) {
    this.snackBar.openFromComponent(ToastComponent, {
      duration: duration ? duration : 1500,
      panelClass: `toast-${type}`,
      data: { type, message },
    });
  }
}
