/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/product/v1/product_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./product";
import * as dependency_2 from "./../../common/v1/composite_filter";
import * as dependency_3 from "./../../../google/protobuf/timestamp";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.product.v1 {
    export class CreateProductRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            product?: dependency_1.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.Product, 1) as dependency_1.core.product.v1.Product;
        }
        set product(value: dependency_1.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
        }): CreateProductRequest {
            const message = new CreateProductRequest({});
            if (data.product != null) {
                message.product = dependency_1.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProduct)
                writer.writeMessage(1, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateProductRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateProductRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.product, () => message.product = dependency_1.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateProductRequest {
            return CreateProductRequest.deserialize(bytes);
        }
    }
    export class CreateProductResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productId?: string;
            product?: dependency_1.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.Product, 4) as dependency_1.core.product.v1.Product;
        }
        set product(value: dependency_1.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 4) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productId?: string;
            product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
        }): CreateProductResponse {
            const message = new CreateProductResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (data.product != null) {
                message.product = dependency_1.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productId?: string;
                product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productId.length)
                writer.writeString(3, this.productId);
            if (this.hasProduct)
                writer.writeMessage(4, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateProductResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateProductResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.productId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.product, () => message.product = dependency_1.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateProductResponse {
            return CreateProductResponse.deserialize(bytes);
        }
    }
    export class UpdateProductStatusRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
            status?: dependency_1.core.product.v1.Status;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
            }
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 2, dependency_1.core.product.v1.Status.PRODUCT_STATUS_UNSPECIFIED) as dependency_1.core.product.v1.Status;
        }
        set status(value: dependency_1.core.product.v1.Status) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            productId?: string;
            status?: dependency_1.core.product.v1.Status;
        }): UpdateProductStatusRequest {
            const message = new UpdateProductStatusRequest({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (data.status != null) {
                message.status = data.status;
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
                status?: dependency_1.core.product.v1.Status;
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.status != null) {
                data.status = this.status;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            if (this.status != dependency_1.core.product.v1.Status.PRODUCT_STATUS_UNSPECIFIED)
                writer.writeEnum(2, this.status);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateProductStatusRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateProductStatusRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    case 2:
                        message.status = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateProductStatusRequest {
            return UpdateProductStatusRequest.deserialize(bytes);
        }
    }
    export class UpdateProductStatusResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productId?: string;
        }): UpdateProductStatusResponse {
            const message = new UpdateProductStatusResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productId != null) {
                message.productId = data.productId;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productId?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productId != null) {
                data.productId = this.productId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productId.length)
                writer.writeString(3, this.productId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateProductStatusResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateProductStatusResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.productId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateProductStatusResponse {
            return UpdateProductStatusResponse.deserialize(bytes);
        }
    }
    export class UpdateProductFieldsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
            fields?: Map<string, string>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("fields" in data && data.fields != undefined) {
                    this.fields = data.fields;
                }
            }
            if (!this.fields)
                this.fields = new Map();
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get fields() {
            return pb_1.Message.getField(this, 2) as any as Map<string, string>;
        }
        set fields(value: Map<string, string>) {
            pb_1.Message.setField(this, 2, value as any);
        }
        static fromObject(data: {
            productId?: string;
            fields?: {
                [key: string]: string;
            };
        }): UpdateProductFieldsRequest {
            const message = new UpdateProductFieldsRequest({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (typeof data.fields == "object") {
                message.fields = new Map(Object.entries(data.fields));
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
                fields?: {
                    [key: string]: string;
                };
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.fields != null) {
                data.fields = (Object.fromEntries)(this.fields);
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            for (const [key, value] of this.fields) {
                writer.writeMessage(2, this.fields, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateProductFieldsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateProductFieldsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.fields as any, reader, reader.readString, reader.readString));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateProductFieldsRequest {
            return UpdateProductFieldsRequest.deserialize(bytes);
        }
    }
    export class UpdateProductFieldsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productId?: string;
        }): UpdateProductFieldsResponse {
            const message = new UpdateProductFieldsResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productId != null) {
                message.productId = data.productId;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productId?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productId != null) {
                data.productId = this.productId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productId.length)
                writer.writeString(3, this.productId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateProductFieldsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateProductFieldsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.productId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateProductFieldsResponse {
            return UpdateProductFieldsResponse.deserialize(bytes);
        }
    }
    export class ChangeProductParametersRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
            newValues?: Map<string, dependency_1.core.product.v1.ParameterValue>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("newValues" in data && data.newValues != undefined) {
                    this.newValues = data.newValues;
                }
            }
            if (!this.newValues)
                this.newValues = new Map();
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get newValues() {
            return pb_1.Message.getField(this, 2) as any as Map<string, dependency_1.core.product.v1.ParameterValue>;
        }
        set newValues(value: Map<string, dependency_1.core.product.v1.ParameterValue>) {
            pb_1.Message.setField(this, 2, value as any);
        }
        static fromObject(data: {
            productId?: string;
            newValues?: {
                [key: string]: ReturnType<typeof dependency_1.core.product.v1.ParameterValue.prototype.toObject>;
            };
        }): ChangeProductParametersRequest {
            const message = new ChangeProductParametersRequest({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (typeof data.newValues == "object") {
                message.newValues = new Map(Object.entries(data.newValues).map(([key, value]) => [key, dependency_1.core.product.v1.ParameterValue.fromObject(value)]));
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
                newValues?: {
                    [key: string]: ReturnType<typeof dependency_1.core.product.v1.ParameterValue.prototype.toObject>;
                };
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.newValues != null) {
                data.newValues = (Object.fromEntries)((Array.from)(this.newValues).map(([key, value]) => [key, value.toObject()]));
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            for (const [key, value] of this.newValues) {
                writer.writeMessage(2, this.newValues, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ChangeProductParametersRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ChangeProductParametersRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.newValues as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = dependency_1.core.product.v1.ParameterValue.deserialize(reader));
                            return value;
                        }));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ChangeProductParametersRequest {
            return ChangeProductParametersRequest.deserialize(bytes);
        }
    }
    export class ChangeProductParametersResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): ChangeProductParametersResponse {
            const message = new ChangeProductParametersResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ChangeProductParametersResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ChangeProductParametersResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ChangeProductParametersResponse {
            return ChangeProductParametersResponse.deserialize(bytes);
        }
    }
    export class GetProductRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            id?: string;
        }): GetProductRequest {
            const message = new GetProductRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProductRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProductRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProductRequest {
            return GetProductRequest.deserialize(bytes);
        }
    }
    export class GetProductResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            product?: dependency_1.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.Product, 3) as dependency_1.core.product.v1.Product;
        }
        set product(value: dependency_1.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
        }): GetProductResponse {
            const message = new GetProductResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.product != null) {
                message.product = dependency_1.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasProduct)
                writer.writeMessage(3, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProductResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProductResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.product, () => message.product = dependency_1.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProductResponse {
            return GetProductResponse.deserialize(bytes);
        }
    }
    export class GetProductByTokenIDRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
            }
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            tokenId?: string;
        }): GetProductByTokenIDRequest {
            const message = new GetProductByTokenIDRequest({});
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            return message;
        }
        toObject() {
            const data: {
                tokenId?: string;
            } = {};
            if (this.tokenId != null) {
                data.tokenId = this.tokenId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenId.length)
                writer.writeString(1, this.tokenId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProductByTokenIDRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProductByTokenIDRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProductByTokenIDRequest {
            return GetProductByTokenIDRequest.deserialize(bytes);
        }
    }
    export class GetProductByTokenIDResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            product?: dependency_1.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.Product, 3) as dependency_1.core.product.v1.Product;
        }
        set product(value: dependency_1.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
        }): GetProductByTokenIDResponse {
            const message = new GetProductByTokenIDResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.product != null) {
                message.product = dependency_1.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasProduct)
                writer.writeMessage(3, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProductByTokenIDResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProductByTokenIDResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.product, () => message.product = dependency_1.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProductByTokenIDResponse {
            return GetProductByTokenIDResponse.deserialize(bytes);
        }
    }
    export class DeleteProductRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
            }
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            productId?: string;
        }): DeleteProductRequest {
            const message = new DeleteProductRequest({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteProductRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteProductRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteProductRequest {
            return DeleteProductRequest.deserialize(bytes);
        }
    }
    export class DeleteProductResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productId?: string;
        }): DeleteProductResponse {
            const message = new DeleteProductResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productId != null) {
                message.productId = data.productId;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productId?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productId != null) {
                data.productId = this.productId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productId.length)
                writer.writeString(3, this.productId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteProductResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteProductResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.productId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteProductResponse {
            return DeleteProductResponse.deserialize(bytes);
        }
    }
    export class ListProductsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productInfoId?: string;
            page?: number;
            itemsPerPage?: number;
            orderField?: string;
            ordering?: string;
            originId?: string;
            listStatus?: dependency_1.core.product.v1.Status[];
            filters?: dependency_2.core.common.v1.CompositeFilter[];
            createdAtStart?: dependency_3.google.protobuf.Timestamp;
            createdAtEnd?: dependency_3.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7, 8], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
                if ("orderField" in data && data.orderField != undefined) {
                    this.orderField = data.orderField;
                }
                if ("ordering" in data && data.ordering != undefined) {
                    this.ordering = data.ordering;
                }
                if ("originId" in data && data.originId != undefined) {
                    this.originId = data.originId;
                }
                if ("listStatus" in data && data.listStatus != undefined) {
                    this.listStatus = data.listStatus;
                }
                if ("filters" in data && data.filters != undefined) {
                    this.filters = data.filters;
                }
                if ("createdAtStart" in data && data.createdAtStart != undefined) {
                    this.createdAtStart = data.createdAtStart;
                }
                if ("createdAtEnd" in data && data.createdAtEnd != undefined) {
                    this.createdAtEnd = data.createdAtEnd;
                }
            }
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get orderField() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set orderField(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get ordering() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set ordering(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get originId() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set originId(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get listStatus() {
            return pb_1.Message.getFieldWithDefault(this, 7, []) as dependency_1.core.product.v1.Status[];
        }
        set listStatus(value: dependency_1.core.product.v1.Status[]) {
            pb_1.Message.setField(this, 7, value);
        }
        get filters() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.common.v1.CompositeFilter, 8) as dependency_2.core.common.v1.CompositeFilter[];
        }
        set filters(value: dependency_2.core.common.v1.CompositeFilter[]) {
            pb_1.Message.setRepeatedWrapperField(this, 8, value);
        }
        get createdAtStart() {
            return pb_1.Message.getWrapperField(this, dependency_3.google.protobuf.Timestamp, 9) as dependency_3.google.protobuf.Timestamp;
        }
        set createdAtStart(value: dependency_3.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 9, value);
        }
        get hasCreatedAtStart() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get createdAtEnd() {
            return pb_1.Message.getWrapperField(this, dependency_3.google.protobuf.Timestamp, 10) as dependency_3.google.protobuf.Timestamp;
        }
        set createdAtEnd(value: dependency_3.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 10, value);
        }
        get hasCreatedAtEnd() {
            return pb_1.Message.getField(this, 10) != null;
        }
        static fromObject(data: {
            productInfoId?: string;
            page?: number;
            itemsPerPage?: number;
            orderField?: string;
            ordering?: string;
            originId?: string;
            listStatus?: dependency_1.core.product.v1.Status[];
            filters?: ReturnType<typeof dependency_2.core.common.v1.CompositeFilter.prototype.toObject>[];
            createdAtStart?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
            createdAtEnd?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
        }): ListProductsRequest {
            const message = new ListProductsRequest({});
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            if (data.orderField != null) {
                message.orderField = data.orderField;
            }
            if (data.ordering != null) {
                message.ordering = data.ordering;
            }
            if (data.originId != null) {
                message.originId = data.originId;
            }
            if (data.listStatus != null) {
                message.listStatus = data.listStatus;
            }
            if (data.filters != null) {
                message.filters = data.filters.map(item => dependency_2.core.common.v1.CompositeFilter.fromObject(item));
            }
            if (data.createdAtStart != null) {
                message.createdAtStart = dependency_3.google.protobuf.Timestamp.fromObject(data.createdAtStart);
            }
            if (data.createdAtEnd != null) {
                message.createdAtEnd = dependency_3.google.protobuf.Timestamp.fromObject(data.createdAtEnd);
            }
            return message;
        }
        toObject() {
            const data: {
                productInfoId?: string;
                page?: number;
                itemsPerPage?: number;
                orderField?: string;
                ordering?: string;
                originId?: string;
                listStatus?: dependency_1.core.product.v1.Status[];
                filters?: ReturnType<typeof dependency_2.core.common.v1.CompositeFilter.prototype.toObject>[];
                createdAtStart?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
                createdAtEnd?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            if (this.orderField != null) {
                data.orderField = this.orderField;
            }
            if (this.ordering != null) {
                data.ordering = this.ordering;
            }
            if (this.originId != null) {
                data.originId = this.originId;
            }
            if (this.listStatus != null) {
                data.listStatus = this.listStatus;
            }
            if (this.filters != null) {
                data.filters = this.filters.map((item: dependency_2.core.common.v1.CompositeFilter) => item.toObject());
            }
            if (this.createdAtStart != null) {
                data.createdAtStart = this.createdAtStart.toObject();
            }
            if (this.createdAtEnd != null) {
                data.createdAtEnd = this.createdAtEnd.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productInfoId.length)
                writer.writeString(1, this.productInfoId);
            if (this.page != 0)
                writer.writeInt32(2, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt32(3, this.itemsPerPage);
            if (this.orderField.length)
                writer.writeString(4, this.orderField);
            if (this.ordering.length)
                writer.writeString(5, this.ordering);
            if (this.originId.length)
                writer.writeString(6, this.originId);
            if (this.listStatus.length)
                writer.writePackedEnum(7, this.listStatus);
            if (this.filters.length)
                writer.writeRepeatedMessage(8, this.filters, (item: dependency_2.core.common.v1.CompositeFilter) => item.serialize(writer));
            if (this.hasCreatedAtStart)
                writer.writeMessage(9, this.createdAtStart, () => this.createdAtStart.serialize(writer));
            if (this.hasCreatedAtEnd)
                writer.writeMessage(10, this.createdAtEnd, () => this.createdAtEnd.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListProductsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListProductsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productInfoId = reader.readString();
                        break;
                    case 2:
                        message.page = reader.readInt32();
                        break;
                    case 3:
                        message.itemsPerPage = reader.readInt32();
                        break;
                    case 4:
                        message.orderField = reader.readString();
                        break;
                    case 5:
                        message.ordering = reader.readString();
                        break;
                    case 6:
                        message.originId = reader.readString();
                        break;
                    case 7:
                        message.listStatus = reader.readPackedEnum();
                        break;
                    case 8:
                        reader.readMessage(message.filters, () => pb_1.Message.addToRepeatedWrapperField(message, 8, dependency_2.core.common.v1.CompositeFilter.deserialize(reader), dependency_2.core.common.v1.CompositeFilter));
                        break;
                    case 9:
                        reader.readMessage(message.createdAtStart, () => message.createdAtStart = dependency_3.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 10:
                        reader.readMessage(message.createdAtEnd, () => message.createdAtEnd = dependency_3.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListProductsRequest {
            return ListProductsRequest.deserialize(bytes);
        }
    }
    export class ListProductsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            products?: dependency_1.core.product.v1.Product[];
            totalItemsGenerated?: number;
            totalItemsPending?: number;
            totalItemsErrors?: number;
            totalItemsRegistered?: number;
            minValueProducts?: number;
            maxValueProducts?: number;
            totalItemsExpired?: number;
            totalItemsBurned?: number;
            totalItemsFiltered?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
                if ("products" in data && data.products != undefined) {
                    this.products = data.products;
                }
                if ("totalItemsGenerated" in data && data.totalItemsGenerated != undefined) {
                    this.totalItemsGenerated = data.totalItemsGenerated;
                }
                if ("totalItemsPending" in data && data.totalItemsPending != undefined) {
                    this.totalItemsPending = data.totalItemsPending;
                }
                if ("totalItemsErrors" in data && data.totalItemsErrors != undefined) {
                    this.totalItemsErrors = data.totalItemsErrors;
                }
                if ("totalItemsRegistered" in data && data.totalItemsRegistered != undefined) {
                    this.totalItemsRegistered = data.totalItemsRegistered;
                }
                if ("minValueProducts" in data && data.minValueProducts != undefined) {
                    this.minValueProducts = data.minValueProducts;
                }
                if ("maxValueProducts" in data && data.maxValueProducts != undefined) {
                    this.maxValueProducts = data.maxValueProducts;
                }
                if ("totalItemsExpired" in data && data.totalItemsExpired != undefined) {
                    this.totalItemsExpired = data.totalItemsExpired;
                }
                if ("totalItemsBurned" in data && data.totalItemsBurned != undefined) {
                    this.totalItemsBurned = data.totalItemsBurned;
                }
                if ("totalItemsFiltered" in data && data.totalItemsFiltered != undefined) {
                    this.totalItemsFiltered = data.totalItemsFiltered;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get products() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.product.v1.Product, 6) as dependency_1.core.product.v1.Product[];
        }
        set products(value: dependency_1.core.product.v1.Product[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get totalItemsGenerated() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set totalItemsGenerated(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get totalItemsPending() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set totalItemsPending(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get totalItemsErrors() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set totalItemsErrors(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get totalItemsRegistered() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set totalItemsRegistered(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        get minValueProducts() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set minValueProducts(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get maxValueProducts() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set maxValueProducts(value: number) {
            pb_1.Message.setField(this, 12, value);
        }
        get totalItemsExpired() {
            return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
        }
        set totalItemsExpired(value: number) {
            pb_1.Message.setField(this, 13, value);
        }
        get totalItemsBurned() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set totalItemsBurned(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        get totalItemsFiltered() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set totalItemsFiltered(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            products?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>[];
            totalItemsGenerated?: number;
            totalItemsPending?: number;
            totalItemsErrors?: number;
            totalItemsRegistered?: number;
            minValueProducts?: number;
            maxValueProducts?: number;
            totalItemsExpired?: number;
            totalItemsBurned?: number;
            totalItemsFiltered?: number;
        }): ListProductsResponse {
            const message = new ListProductsResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            if (data.products != null) {
                message.products = data.products.map(item => dependency_1.core.product.v1.Product.fromObject(item));
            }
            if (data.totalItemsGenerated != null) {
                message.totalItemsGenerated = data.totalItemsGenerated;
            }
            if (data.totalItemsPending != null) {
                message.totalItemsPending = data.totalItemsPending;
            }
            if (data.totalItemsErrors != null) {
                message.totalItemsErrors = data.totalItemsErrors;
            }
            if (data.totalItemsRegistered != null) {
                message.totalItemsRegistered = data.totalItemsRegistered;
            }
            if (data.minValueProducts != null) {
                message.minValueProducts = data.minValueProducts;
            }
            if (data.maxValueProducts != null) {
                message.maxValueProducts = data.maxValueProducts;
            }
            if (data.totalItemsExpired != null) {
                message.totalItemsExpired = data.totalItemsExpired;
            }
            if (data.totalItemsBurned != null) {
                message.totalItemsBurned = data.totalItemsBurned;
            }
            if (data.totalItemsFiltered != null) {
                message.totalItemsFiltered = data.totalItemsFiltered;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
                products?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>[];
                totalItemsGenerated?: number;
                totalItemsPending?: number;
                totalItemsErrors?: number;
                totalItemsRegistered?: number;
                minValueProducts?: number;
                maxValueProducts?: number;
                totalItemsExpired?: number;
                totalItemsBurned?: number;
                totalItemsFiltered?: number;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            if (this.products != null) {
                data.products = this.products.map((item: dependency_1.core.product.v1.Product) => item.toObject());
            }
            if (this.totalItemsGenerated != null) {
                data.totalItemsGenerated = this.totalItemsGenerated;
            }
            if (this.totalItemsPending != null) {
                data.totalItemsPending = this.totalItemsPending;
            }
            if (this.totalItemsErrors != null) {
                data.totalItemsErrors = this.totalItemsErrors;
            }
            if (this.totalItemsRegistered != null) {
                data.totalItemsRegistered = this.totalItemsRegistered;
            }
            if (this.minValueProducts != null) {
                data.minValueProducts = this.minValueProducts;
            }
            if (this.maxValueProducts != null) {
                data.maxValueProducts = this.maxValueProducts;
            }
            if (this.totalItemsExpired != null) {
                data.totalItemsExpired = this.totalItemsExpired;
            }
            if (this.totalItemsBurned != null) {
                data.totalItemsBurned = this.totalItemsBurned;
            }
            if (this.totalItemsFiltered != null) {
                data.totalItemsFiltered = this.totalItemsFiltered;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.currentPage != 0)
                writer.writeInt32(3, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(4, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt64(5, this.totalItems);
            if (this.products.length)
                writer.writeRepeatedMessage(6, this.products, (item: dependency_1.core.product.v1.Product) => item.serialize(writer));
            if (this.totalItemsGenerated != 0)
                writer.writeInt64(7, this.totalItemsGenerated);
            if (this.totalItemsPending != 0)
                writer.writeInt64(8, this.totalItemsPending);
            if (this.totalItemsErrors != 0)
                writer.writeInt64(9, this.totalItemsErrors);
            if (this.totalItemsRegistered != 0)
                writer.writeInt64(10, this.totalItemsRegistered);
            if (this.minValueProducts != 0)
                writer.writeInt64(11, this.minValueProducts);
            if (this.maxValueProducts != 0)
                writer.writeInt64(12, this.maxValueProducts);
            if (this.totalItemsExpired != 0)
                writer.writeInt64(13, this.totalItemsExpired);
            if (this.totalItemsBurned != 0)
                writer.writeInt64(14, this.totalItemsBurned);
            if (this.totalItemsFiltered != 0)
                writer.writeInt64(15, this.totalItemsFiltered);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListProductsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListProductsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.currentPage = reader.readInt32();
                        break;
                    case 4:
                        message.totalPages = reader.readInt32();
                        break;
                    case 5:
                        message.totalItems = reader.readInt64();
                        break;
                    case 6:
                        reader.readMessage(message.products, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_1.core.product.v1.Product.deserialize(reader), dependency_1.core.product.v1.Product));
                        break;
                    case 7:
                        message.totalItemsGenerated = reader.readInt64();
                        break;
                    case 8:
                        message.totalItemsPending = reader.readInt64();
                        break;
                    case 9:
                        message.totalItemsErrors = reader.readInt64();
                        break;
                    case 10:
                        message.totalItemsRegistered = reader.readInt64();
                        break;
                    case 11:
                        message.minValueProducts = reader.readInt64();
                        break;
                    case 12:
                        message.maxValueProducts = reader.readInt64();
                        break;
                    case 13:
                        message.totalItemsExpired = reader.readInt64();
                        break;
                    case 14:
                        message.totalItemsBurned = reader.readInt64();
                        break;
                    case 15:
                        message.totalItemsFiltered = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListProductsResponse {
            return ListProductsResponse.deserialize(bytes);
        }
    }
    export class PushTransactionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productId?: string;
            transactionId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productId" in data && data.productId != undefined) {
                    this.productId = data.productId;
                }
                if ("transactionId" in data && data.transactionId != undefined) {
                    this.transactionId = data.transactionId;
                }
            }
        }
        get productId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transactionId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set transactionId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            productId?: string;
            transactionId?: string;
        }): PushTransactionRequest {
            const message = new PushTransactionRequest({});
            if (data.productId != null) {
                message.productId = data.productId;
            }
            if (data.transactionId != null) {
                message.transactionId = data.transactionId;
            }
            return message;
        }
        toObject() {
            const data: {
                productId?: string;
                transactionId?: string;
            } = {};
            if (this.productId != null) {
                data.productId = this.productId;
            }
            if (this.transactionId != null) {
                data.transactionId = this.transactionId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productId.length)
                writer.writeString(1, this.productId);
            if (this.transactionId.length)
                writer.writeString(2, this.transactionId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PushTransactionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PushTransactionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productId = reader.readString();
                        break;
                    case 2:
                        message.transactionId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PushTransactionRequest {
            return PushTransactionRequest.deserialize(bytes);
        }
    }
    export class PushTransactionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): PushTransactionResponse {
            const message = new PushTransactionResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PushTransactionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PushTransactionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PushTransactionResponse {
            return PushTransactionResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedProductAPIService {
        static definition = {
            CreateProduct: {
                path: "/core.product.v1.ProductAPI/CreateProduct",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateProductRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateProductRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateProductResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateProductResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateProductStatus: {
                path: "/core.product.v1.ProductAPI/UpdateProductStatus",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateProductStatusRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateProductStatusRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateProductStatusResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateProductStatusResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateProductFields: {
                path: "/core.product.v1.ProductAPI/UpdateProductFields",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateProductFieldsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateProductFieldsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateProductFieldsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateProductFieldsResponse.deserialize(new Uint8Array(bytes))
            },
            ChangeProductParameters: {
                path: "/core.product.v1.ProductAPI/ChangeProductParameters",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ChangeProductParametersRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ChangeProductParametersRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ChangeProductParametersResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ChangeProductParametersResponse.deserialize(new Uint8Array(bytes))
            },
            GetProduct: {
                path: "/core.product.v1.ProductAPI/GetProduct",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetProductRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetProductRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetProductResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetProductResponse.deserialize(new Uint8Array(bytes))
            },
            GetProductByTokenID: {
                path: "/core.product.v1.ProductAPI/GetProductByTokenID",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetProductByTokenIDRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetProductByTokenIDRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetProductByTokenIDResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetProductByTokenIDResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteProduct: {
                path: "/core.product.v1.ProductAPI/DeleteProduct",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteProductRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteProductRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteProductResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteProductResponse.deserialize(new Uint8Array(bytes))
            },
            ListProducts: {
                path: "/core.product.v1.ProductAPI/ListProducts",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListProductsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListProductsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListProductsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListProductsResponse.deserialize(new Uint8Array(bytes))
            },
            PushTransaction: {
                path: "/core.product.v1.ProductAPI/PushTransaction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: PushTransactionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => PushTransactionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: PushTransactionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => PushTransactionResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract CreateProduct(call: grpc_1.ServerUnaryCall<CreateProductRequest, CreateProductResponse>, callback: grpc_1.sendUnaryData<CreateProductResponse>): void;
        abstract UpdateProductStatus(call: grpc_1.ServerUnaryCall<UpdateProductStatusRequest, UpdateProductStatusResponse>, callback: grpc_1.sendUnaryData<UpdateProductStatusResponse>): void;
        abstract UpdateProductFields(call: grpc_1.ServerUnaryCall<UpdateProductFieldsRequest, UpdateProductFieldsResponse>, callback: grpc_1.sendUnaryData<UpdateProductFieldsResponse>): void;
        abstract ChangeProductParameters(call: grpc_1.ServerUnaryCall<ChangeProductParametersRequest, ChangeProductParametersResponse>, callback: grpc_1.sendUnaryData<ChangeProductParametersResponse>): void;
        abstract GetProduct(call: grpc_1.ServerUnaryCall<GetProductRequest, GetProductResponse>, callback: grpc_1.sendUnaryData<GetProductResponse>): void;
        abstract GetProductByTokenID(call: grpc_1.ServerUnaryCall<GetProductByTokenIDRequest, GetProductByTokenIDResponse>, callback: grpc_1.sendUnaryData<GetProductByTokenIDResponse>): void;
        abstract DeleteProduct(call: grpc_1.ServerUnaryCall<DeleteProductRequest, DeleteProductResponse>, callback: grpc_1.sendUnaryData<DeleteProductResponse>): void;
        abstract ListProducts(call: grpc_1.ServerUnaryCall<ListProductsRequest, ListProductsResponse>, callback: grpc_1.sendUnaryData<ListProductsResponse>): void;
        abstract PushTransaction(call: grpc_1.ServerUnaryCall<PushTransactionRequest, PushTransactionResponse>, callback: grpc_1.sendUnaryData<PushTransactionResponse>): void;
    }
    export class ProductAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static CreateProduct = new grpc_web_1.MethodDescriptor<CreateProductRequest, CreateProductResponse>("/core.product.v1.ProductAPI/CreateProduct", grpc_web_1.MethodType.UNARY, CreateProductRequest, CreateProductResponse, (message: CreateProductRequest) => message.serialize(), CreateProductResponse.deserialize);
        CreateProduct(message: CreateProductRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateProductRequest, CreateProductResponse>(this._address + "/core.product.v1.ProductAPI/CreateProduct", message, metadata || {}, ProductAPIClient.CreateProduct);
        }
        private static UpdateProductStatus = new grpc_web_1.MethodDescriptor<UpdateProductStatusRequest, UpdateProductStatusResponse>("/core.product.v1.ProductAPI/UpdateProductStatus", grpc_web_1.MethodType.UNARY, UpdateProductStatusRequest, UpdateProductStatusResponse, (message: UpdateProductStatusRequest) => message.serialize(), UpdateProductStatusResponse.deserialize);
        UpdateProductStatus(message: UpdateProductStatusRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateProductStatusRequest, UpdateProductStatusResponse>(this._address + "/core.product.v1.ProductAPI/UpdateProductStatus", message, metadata || {}, ProductAPIClient.UpdateProductStatus);
        }
        private static UpdateProductFields = new grpc_web_1.MethodDescriptor<UpdateProductFieldsRequest, UpdateProductFieldsResponse>("/core.product.v1.ProductAPI/UpdateProductFields", grpc_web_1.MethodType.UNARY, UpdateProductFieldsRequest, UpdateProductFieldsResponse, (message: UpdateProductFieldsRequest) => message.serialize(), UpdateProductFieldsResponse.deserialize);
        UpdateProductFields(message: UpdateProductFieldsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateProductFieldsRequest, UpdateProductFieldsResponse>(this._address + "/core.product.v1.ProductAPI/UpdateProductFields", message, metadata || {}, ProductAPIClient.UpdateProductFields);
        }
        private static ChangeProductParameters = new grpc_web_1.MethodDescriptor<ChangeProductParametersRequest, ChangeProductParametersResponse>("/core.product.v1.ProductAPI/ChangeProductParameters", grpc_web_1.MethodType.UNARY, ChangeProductParametersRequest, ChangeProductParametersResponse, (message: ChangeProductParametersRequest) => message.serialize(), ChangeProductParametersResponse.deserialize);
        ChangeProductParameters(message: ChangeProductParametersRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ChangeProductParametersRequest, ChangeProductParametersResponse>(this._address + "/core.product.v1.ProductAPI/ChangeProductParameters", message, metadata || {}, ProductAPIClient.ChangeProductParameters);
        }
        private static GetProduct = new grpc_web_1.MethodDescriptor<GetProductRequest, GetProductResponse>("/core.product.v1.ProductAPI/GetProduct", grpc_web_1.MethodType.UNARY, GetProductRequest, GetProductResponse, (message: GetProductRequest) => message.serialize(), GetProductResponse.deserialize);
        GetProduct(message: GetProductRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetProductRequest, GetProductResponse>(this._address + "/core.product.v1.ProductAPI/GetProduct", message, metadata || {}, ProductAPIClient.GetProduct);
        }
        private static GetProductByTokenID = new grpc_web_1.MethodDescriptor<GetProductByTokenIDRequest, GetProductByTokenIDResponse>("/core.product.v1.ProductAPI/GetProductByTokenID", grpc_web_1.MethodType.UNARY, GetProductByTokenIDRequest, GetProductByTokenIDResponse, (message: GetProductByTokenIDRequest) => message.serialize(), GetProductByTokenIDResponse.deserialize);
        GetProductByTokenID(message: GetProductByTokenIDRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetProductByTokenIDRequest, GetProductByTokenIDResponse>(this._address + "/core.product.v1.ProductAPI/GetProductByTokenID", message, metadata || {}, ProductAPIClient.GetProductByTokenID);
        }
        private static DeleteProduct = new grpc_web_1.MethodDescriptor<DeleteProductRequest, DeleteProductResponse>("/core.product.v1.ProductAPI/DeleteProduct", grpc_web_1.MethodType.UNARY, DeleteProductRequest, DeleteProductResponse, (message: DeleteProductRequest) => message.serialize(), DeleteProductResponse.deserialize);
        DeleteProduct(message: DeleteProductRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteProductRequest, DeleteProductResponse>(this._address + "/core.product.v1.ProductAPI/DeleteProduct", message, metadata || {}, ProductAPIClient.DeleteProduct);
        }
        private static ListProducts = new grpc_web_1.MethodDescriptor<ListProductsRequest, ListProductsResponse>("/core.product.v1.ProductAPI/ListProducts", grpc_web_1.MethodType.UNARY, ListProductsRequest, ListProductsResponse, (message: ListProductsRequest) => message.serialize(), ListProductsResponse.deserialize);
        ListProducts(message: ListProductsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListProductsRequest, ListProductsResponse>(this._address + "/core.product.v1.ProductAPI/ListProducts", message, metadata || {}, ProductAPIClient.ListProducts);
        }
        private static PushTransaction = new grpc_web_1.MethodDescriptor<PushTransactionRequest, PushTransactionResponse>("/core.product.v1.ProductAPI/PushTransaction", grpc_web_1.MethodType.UNARY, PushTransactionRequest, PushTransactionResponse, (message: PushTransactionRequest) => message.serialize(), PushTransactionResponse.deserialize);
        PushTransaction(message: PushTransactionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<PushTransactionRequest, PushTransactionResponse>(this._address + "/core.product.v1.ProductAPI/PushTransaction", message, metadata || {}, ProductAPIClient.PushTransaction);
        }
    }
}
