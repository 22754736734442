import * as XLSX from "xlsx";
import { catchError, combineLatest, from, map, Observable, of, tap } from "rxjs";
import { CognitoService } from "@libs/services/cognito/cognito.service";
import { GrpcAccountService } from "@libs/services/grpc-account/grpc-account.service";
import { GrpcProductService } from "@libs/services/grpc-product/grpc-product.service";
import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalTokenizacaoTermsComponent } from "@components/modal-tokenizacao-terms/modal-tokenizacao-terms.component";
import { NUCLEA_ID } from "@libs/main.service";
import { Product } from "@libs/models/product";
import { SharedService } from "@libs/services/shared/shared.service";
import { User } from "@libs/models/user";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public dialog = inject(MatDialog);
  products: Product[];

  lastUpdateDate: string; //"2024-08-01T00:00:00Z"
  termsHtml: string;
  modalTermsIsOpen = false;

  userRegister: User = {};

  constructor(
    public grpcProduct: GrpcProductService,
    public cognitoService: CognitoService,
    public shared: SharedService,
    public grpcAccount: GrpcAccountService
  ) {}

  loadDataWithUser(user: User): Observable<any> {
    return this.loadData(user);
  }

  loadData(user?: User) {
    console.log("Usuário no loadData: ", user);
    console.log("Produtos no loadData: ", this.products);
    if (!user) {
      return of(null);
    }
    if (user && user.company && this.termsHtml && this.products) {
      return of(null);
    }
    return combineLatest([
      from(this.grpcProduct.getProducts()).pipe(map((resProducts: any) => resProducts.toObject())),
      from(this.grpcAccount.getTerms()).pipe(map((resTerms: any) => resTerms.toObject())),
      from(this.grpcAccount.getCompany(user.companyId ?? NUCLEA_ID)).pipe(map((resCompany: any) => resCompany.toObject())),
    ]).pipe(
      tap(([responseProducts, responseTerms, resCompany]) => {
        console.log("responseProducts: ", responseProducts);
        console.log("responseTerms: ", responseTerms);
        console.log("resCompany: ", resCompany);
        this.products = responseProducts.productInfos as Product[];
        this.shared.resetProductInfoSelected$.next(this.products);
        this.termsHtml = responseTerms.termsHtml;
        this.lastUpdateDate = responseTerms.lastUpdateDate;
        if (resCompany && resCompany.company) {
          user.company = resCompany.company;
          this.shared.user$.next({ ...user });
        }
        this.handleTerms(user);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  handleTerms(user) {
    if (user && this.termsHtml && this.lastUpdateDate) {
      if (!user.termsAcceptedAt) {
        this.openModalTokenizacaoTerms();
      } else {
        const lastUpdateDate = new Date(this.lastUpdateDate);
        const termsAcceptedDate = new Date(user.termsAcceptedAt);
        const lastUpdateDateOnly = new Date(lastUpdateDate.getFullYear(), lastUpdateDate.getMonth(), lastUpdateDate.getDate());
        const termsAcceptedDateOnly = new Date(termsAcceptedDate.getFullYear(), termsAcceptedDate.getMonth(), termsAcceptedDate.getDate());
        if (lastUpdateDateOnly.getTime() !== termsAcceptedDateOnly.getTime()) {
          this.openModalTokenizacaoTerms();
        }
      }
    }
  }

  openModalTokenizacaoTerms() {
    if (!this.modalTermsIsOpen) {
      this.modalTermsIsOpen = true;
      this.dialog.open(ModalTokenizacaoTermsComponent, {
        width: "600px",
        disableClose: true,
        data: { termsHtml: this.termsHtml, lastUpdateDate: this.lastUpdateDate },
      });
    }
  }

  /**
   * Converte recursivamente um valor (primitivo, objeto ou array) para XML.
   */
  convertToXML(value: any): string {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value !== "object") {
      return this.escapeXML(value.toString());
    }
    let xml = "";
    if (Array.isArray(value)) {
      // Para arrays, cria uma tag <item> para cada elemento
      value.forEach((item) => {
        xml += `<item>${this.convertToXML(item)}</item>`;
      });
    } else {
      // Para objetos, percorre cada propriedade
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          xml += `<${key}>${this.convertToXML(value[key])}</${key}>`;
        }
      }
    }
    return xml;
  }
  /**
   * Escapa caracteres especiais para que o XML seja válido.
   */
  escapeXML(str: string): string {
    return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;");
  }

  // =====================================================================
  // Export para JSON
  // =====================================================================
  exportToJSON(products: Product[]): void {
    const json = JSON.stringify(products, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "products.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  // =====================================================================
  // Export para XML
  // =====================================================================
  exportToXML(products: Product[]): void {
    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n<products>\n';
    products.forEach((product) => {
      xml += "  <product>\n";
      // Percorre cada propriedade do objeto product
      for (const key in product) {
        if (product.hasOwnProperty(key)) {
          const value = product[key];
          xml += `    <${key}>${this.convertToXML(value)}</${key}>\n`;
        }
      }
      xml += "  </product>\n";
    });
    xml += "</products>";

    const blob = new Blob([xml], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "products.xml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  // =====================================================================
  // Export para XLS
  // ==================================================================

  /**
   * "Achatamos" (flatten) o objeto para que cada propriedade (mesmo as aninhadas)
   * se torne uma coluna. Caso a propriedade seja um array, os valores são unidos por vírgula.
   */
  flattenObject(obj: any, parentKey: string = "", res: any = {}): any {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}_${key}` : key;
        const value = obj[key];
        if (value !== null && typeof value === "object") {
          if (Array.isArray(value)) {
            // Junta os valores do array em uma string separada por vírgulas
            res[newKey] = value.join(", ");
          } else {
            // Se for objeto, chama recursivamente
            this.flattenObject(value, newKey, res);
          }
        } else {
          res[newKey] = value;
        }
      }
    }
    return res;
  }

  /**
   * Exporta os produtos para um arquivo XLSX.
   */
  exportToXLS(products: Product[]): void {
    // Cria um array de objetos "achatados"
    const data = products.map((product) => this.flattenObject(product));

    // Cria a planilha a partir dos dados
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Cria um novo workbook e adiciona a planilha
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // Salva o arquivo XLSX (no navegador o arquivo é feito para download)
    XLSX.writeFile(workbook, "products.xlsx");
  }

  resetData() {
    this.modalTermsIsOpen = false;
    this.termsHtml = null;
    this.products = null;
    this.lastUpdateDate = null;
  }
}
