import { inject, Injectable } from "@angular/core";
import { CognitoService } from "@libs/services/cognito/cognito.service";
import { ClientReadableStream, RpcError } from "grpc-web";
import { Subject } from "rxjs";

export const ERRORS_CODE_UNAUTHENTICATED = [16];
export const streamSubject = new Subject<ClientReadableStream<any>>();

@Injectable({
  providedIn: "root",
})
export class AuthorizationStreamInterceptor {
  public cognitoService = inject(CognitoService);
  private maxRetries = 100;

  constructor() {}

  intercept = (request, invoker): ClientReadableStream<any> => {
    let authorization: string | null;

    authorization = this.getAuthorization();

    if (authorization) {
      request.getMetadata()["Authorization"] = authorization;
    }

    const originalStream = invoker(request);
    const interceptedStream = new InterceptedStream(originalStream, request, invoker, this);

    return interceptedStream;
  };

  getAuthorization = (forceValue?: string): string | null => {
    const accessToken = forceValue ? forceValue : localStorage.getItem("accessToken");
    if (accessToken) {
      return `Bearer ${accessToken}`;
    }
    return null;
  };

  public async tryRenewToken(): Promise<boolean> {
    try {
      await this.cognitoService.refreshSession();
      const newToken = localStorage.getItem("accessToken");
      if (!newToken) {
        throw new Error("Token not found after refresh.");
      }

      return true;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return false;
    }
  }

  public getDelay(attempt: number): number {
    if (attempt === 1) {
      return 0;
    } else {
      return Math.pow(3, attempt) * 1000;
    }
  }

  public sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getMaxRetries(): number {
    return this.maxRetries;
  }
}

class InterceptedStream<RESPONSE> implements ClientReadableStream<RESPONSE> {
  private isRetrying = false;

  constructor(
    private readonly stream: ClientReadableStream<RESPONSE>,
    private readonly request: any,
    private readonly invoker: any,
    private readonly interceptor: AuthorizationStreamInterceptor
  ) {}

  cancel(): void {
    this.stream.cancel();
  }

  on(eventType: any, callback: any): ClientReadableStream<RESPONSE> {
    if (eventType === "data") {
      this.stream.on(eventType, callback);
    } else if (eventType === "error") {
      this.stream.on(eventType, async (error: RpcError) => {
        if (!this.isRetrying) {
          this.isRetrying = true;
          await this.handleRetry(callback, 1);
        } else {
          callback(error);
        }
      });
    } else {
      this.stream.on(eventType, callback);
    }
    return this;
  }

  private async handleRetry(callback: any, attempt: number): Promise<void> {
    if (attempt > this.interceptor.getMaxRetries()) {
      callback(new Error("Failed to renew token after multiple attempts."));
      return;
    }

    const renewed = await this.interceptor.tryRenewToken();

    if (renewed) {
      const authorization = this.interceptor.getAuthorization();

      if (authorization) {
        this.request.getMetadata()["Authorization"] = authorization;
      }

      const delay = this.interceptor.getDelay(attempt);

      await this.interceptor.sleep(delay);

      try {
        console.log("Criação de novo stream");
        this.stream.cancel();
        const newStream = this.invoker(this.request);
        streamSubject.next(newStream);
        this.isRetrying = false;
      } catch (error) {
        console.log("Error tratamento de newStream:", error);
        callback(new Error("Failed to reestablish stream."));
      }
    } else {
      console.error("Failed to renew token");
      callback(new Error("Failed to renew token."));
    }
  }

  removeListener(eventType: any, callback: any) {
    this.stream.removeListener(eventType, callback);
  }
}
