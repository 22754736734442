/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/profile/v1/profile.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../custom_attributes";
import * as pb_1 from "google-protobuf";
export namespace core.profile.v1 {
    export enum BankCode {
        BANK_CODE_UNSPECIFIED = 0,
        BANK_CODE_SANTANDER = 33
    }
    export class Profile extends pb_1.Message {
        #one_of_decls: number[][] = [[4, 5, 7]];
        constructor(data?: any[] | ({
            id?: string;
            configSettings?: dependency_1.core.CustomAttributes[];
        } & (({
            confirmAction?: ConfirmActionConfiguration;
            accountBank?: never;
            managementConfiguration?: never;
        } | {
            confirmAction?: never;
            accountBank?: AccountBankConfiguration;
            managementConfiguration?: never;
        } | {
            confirmAction?: never;
            accountBank?: never;
            managementConfiguration?: ManagementConfiguration;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("configSettings" in data && data.configSettings != undefined) {
                    this.configSettings = data.configSettings;
                }
                if ("confirmAction" in data && data.confirmAction != undefined) {
                    this.confirmAction = data.confirmAction;
                }
                if ("accountBank" in data && data.accountBank != undefined) {
                    this.accountBank = data.accountBank;
                }
                if ("managementConfiguration" in data && data.managementConfiguration != undefined) {
                    this.managementConfiguration = data.managementConfiguration;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get configSettings() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.CustomAttributes, 6) as dependency_1.core.CustomAttributes[];
        }
        set configSettings(value: dependency_1.core.CustomAttributes[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get confirmAction() {
            return pb_1.Message.getWrapperField(this, ConfirmActionConfiguration, 4) as ConfirmActionConfiguration;
        }
        set confirmAction(value: ConfirmActionConfiguration) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
        }
        get hasConfirmAction() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get accountBank() {
            return pb_1.Message.getWrapperField(this, AccountBankConfiguration, 5) as AccountBankConfiguration;
        }
        set accountBank(value: AccountBankConfiguration) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
        }
        get hasAccountBank() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get managementConfiguration() {
            return pb_1.Message.getWrapperField(this, ManagementConfiguration, 7) as ManagementConfiguration;
        }
        set managementConfiguration(value: ManagementConfiguration) {
            pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
        }
        get hasManagementConfiguration() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get configuration_instance() {
            const cases: {
                [index: number]: "none" | "confirmAction" | "accountBank" | "managementConfiguration";
            } = {
                0: "none",
                4: "confirmAction",
                5: "accountBank",
                7: "managementConfiguration"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4, 5, 7])];
        }
        static fromObject(data: {
            id?: string;
            configSettings?: ReturnType<typeof dependency_1.core.CustomAttributes.prototype.toObject>[];
            confirmAction?: ReturnType<typeof ConfirmActionConfiguration.prototype.toObject>;
            accountBank?: ReturnType<typeof AccountBankConfiguration.prototype.toObject>;
            managementConfiguration?: ReturnType<typeof ManagementConfiguration.prototype.toObject>;
        }): Profile {
            const message = new Profile({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.configSettings != null) {
                message.configSettings = data.configSettings.map(item => dependency_1.core.CustomAttributes.fromObject(item));
            }
            if (data.confirmAction != null) {
                message.confirmAction = ConfirmActionConfiguration.fromObject(data.confirmAction);
            }
            if (data.accountBank != null) {
                message.accountBank = AccountBankConfiguration.fromObject(data.accountBank);
            }
            if (data.managementConfiguration != null) {
                message.managementConfiguration = ManagementConfiguration.fromObject(data.managementConfiguration);
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                configSettings?: ReturnType<typeof dependency_1.core.CustomAttributes.prototype.toObject>[];
                confirmAction?: ReturnType<typeof ConfirmActionConfiguration.prototype.toObject>;
                accountBank?: ReturnType<typeof AccountBankConfiguration.prototype.toObject>;
                managementConfiguration?: ReturnType<typeof ManagementConfiguration.prototype.toObject>;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.configSettings != null) {
                data.configSettings = this.configSettings.map((item: dependency_1.core.CustomAttributes) => item.toObject());
            }
            if (this.confirmAction != null) {
                data.confirmAction = this.confirmAction.toObject();
            }
            if (this.accountBank != null) {
                data.accountBank = this.accountBank.toObject();
            }
            if (this.managementConfiguration != null) {
                data.managementConfiguration = this.managementConfiguration.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.configSettings.length)
                writer.writeRepeatedMessage(6, this.configSettings, (item: dependency_1.core.CustomAttributes) => item.serialize(writer));
            if (this.hasConfirmAction)
                writer.writeMessage(4, this.confirmAction, () => this.confirmAction.serialize(writer));
            if (this.hasAccountBank)
                writer.writeMessage(5, this.accountBank, () => this.accountBank.serialize(writer));
            if (this.hasManagementConfiguration)
                writer.writeMessage(7, this.managementConfiguration, () => this.managementConfiguration.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Profile {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Profile();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 6:
                        reader.readMessage(message.configSettings, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_1.core.CustomAttributes.deserialize(reader), dependency_1.core.CustomAttributes));
                        break;
                    case 4:
                        reader.readMessage(message.confirmAction, () => message.confirmAction = ConfirmActionConfiguration.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.accountBank, () => message.accountBank = AccountBankConfiguration.deserialize(reader));
                        break;
                    case 7:
                        reader.readMessage(message.managementConfiguration, () => message.managementConfiguration = ManagementConfiguration.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Profile {
            return Profile.deserialize(bytes);
        }
    }
    export class ConfirmActionConfiguration extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            confirmAction?: Map<string, ConfirmAction>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("confirmAction" in data && data.confirmAction != undefined) {
                    this.confirmAction = data.confirmAction;
                }
            }
            if (!this.confirmAction)
                this.confirmAction = new Map();
        }
        get confirmAction() {
            return pb_1.Message.getField(this, 1) as any as Map<string, ConfirmAction>;
        }
        set confirmAction(value: Map<string, ConfirmAction>) {
            pb_1.Message.setField(this, 1, value as any);
        }
        static fromObject(data: {
            confirmAction?: {
                [key: string]: ReturnType<typeof ConfirmAction.prototype.toObject>;
            };
        }): ConfirmActionConfiguration {
            const message = new ConfirmActionConfiguration({});
            if (typeof data.confirmAction == "object") {
                message.confirmAction = new Map(Object.entries(data.confirmAction).map(([key, value]) => [key, ConfirmAction.fromObject(value)]));
            }
            return message;
        }
        toObject() {
            const data: {
                confirmAction?: {
                    [key: string]: ReturnType<typeof ConfirmAction.prototype.toObject>;
                };
            } = {};
            if (this.confirmAction != null) {
                data.confirmAction = (Object.fromEntries)((Array.from)(this.confirmAction).map(([key, value]) => [key, value.toObject()]));
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            for (const [key, value] of this.confirmAction) {
                writer.writeMessage(1, this.confirmAction, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ConfirmActionConfiguration {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ConfirmActionConfiguration();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.confirmAction as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = ConfirmAction.deserialize(reader));
                            return value;
                        }));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ConfirmActionConfiguration {
            return ConfirmActionConfiguration.deserialize(bytes);
        }
    }
    export class AccountBankConfiguration extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            accountsBankByCode?: Map<string, BankAccounts>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("accountsBankByCode" in data && data.accountsBankByCode != undefined) {
                    this.accountsBankByCode = data.accountsBankByCode;
                }
            }
            if (!this.accountsBankByCode)
                this.accountsBankByCode = new Map();
        }
        get accountsBankByCode() {
            return pb_1.Message.getField(this, 1) as any as Map<string, BankAccounts>;
        }
        set accountsBankByCode(value: Map<string, BankAccounts>) {
            pb_1.Message.setField(this, 1, value as any);
        }
        static fromObject(data: {
            accountsBankByCode?: {
                [key: string]: ReturnType<typeof BankAccounts.prototype.toObject>;
            };
        }): AccountBankConfiguration {
            const message = new AccountBankConfiguration({});
            if (typeof data.accountsBankByCode == "object") {
                message.accountsBankByCode = new Map(Object.entries(data.accountsBankByCode).map(([key, value]) => [key, BankAccounts.fromObject(value)]));
            }
            return message;
        }
        toObject() {
            const data: {
                accountsBankByCode?: {
                    [key: string]: ReturnType<typeof BankAccounts.prototype.toObject>;
                };
            } = {};
            if (this.accountsBankByCode != null) {
                data.accountsBankByCode = (Object.fromEntries)((Array.from)(this.accountsBankByCode).map(([key, value]) => [key, value.toObject()]));
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            for (const [key, value] of this.accountsBankByCode) {
                writer.writeMessage(1, this.accountsBankByCode, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AccountBankConfiguration {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AccountBankConfiguration();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.accountsBankByCode as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = BankAccounts.deserialize(reader));
                            return value;
                        }));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AccountBankConfiguration {
            return AccountBankConfiguration.deserialize(bytes);
        }
    }
    export class ManagementConfiguration extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            managementId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("managementId" in data && data.managementId != undefined) {
                    this.managementId = data.managementId;
                }
            }
        }
        get managementId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set managementId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            managementId?: string;
        }): ManagementConfiguration {
            const message = new ManagementConfiguration({});
            if (data.managementId != null) {
                message.managementId = data.managementId;
            }
            return message;
        }
        toObject() {
            const data: {
                managementId?: string;
            } = {};
            if (this.managementId != null) {
                data.managementId = this.managementId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.managementId.length)
                writer.writeString(1, this.managementId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ManagementConfiguration {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ManagementConfiguration();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.managementId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ManagementConfiguration {
            return ManagementConfiguration.deserialize(bytes);
        }
    }
    export class BankAccounts extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            accountBank?: AccountBank[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("accountBank" in data && data.accountBank != undefined) {
                    this.accountBank = data.accountBank;
                }
            }
        }
        get accountBank() {
            return pb_1.Message.getRepeatedWrapperField(this, AccountBank, 1) as AccountBank[];
        }
        set accountBank(value: AccountBank[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            accountBank?: ReturnType<typeof AccountBank.prototype.toObject>[];
        }): BankAccounts {
            const message = new BankAccounts({});
            if (data.accountBank != null) {
                message.accountBank = data.accountBank.map(item => AccountBank.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                accountBank?: ReturnType<typeof AccountBank.prototype.toObject>[];
            } = {};
            if (this.accountBank != null) {
                data.accountBank = this.accountBank.map((item: AccountBank) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.accountBank.length)
                writer.writeRepeatedMessage(1, this.accountBank, (item: AccountBank) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BankAccounts {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BankAccounts();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.accountBank, () => pb_1.Message.addToRepeatedWrapperField(message, 1, AccountBank.deserialize(reader), AccountBank));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BankAccounts {
            return BankAccounts.deserialize(bytes);
        }
    }
    export class AccountBank extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bankCode?: BankCode;
            branchCode?: string;
            accountNumber?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bankCode" in data && data.bankCode != undefined) {
                    this.bankCode = data.bankCode;
                }
                if ("branchCode" in data && data.branchCode != undefined) {
                    this.branchCode = data.branchCode;
                }
                if ("accountNumber" in data && data.accountNumber != undefined) {
                    this.accountNumber = data.accountNumber;
                }
            }
        }
        get bankCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, BankCode.BANK_CODE_UNSPECIFIED) as BankCode;
        }
        set bankCode(value: BankCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get branchCode() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set branchCode(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get accountNumber() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set accountNumber(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            bankCode?: BankCode;
            branchCode?: string;
            accountNumber?: string;
        }): AccountBank {
            const message = new AccountBank({});
            if (data.bankCode != null) {
                message.bankCode = data.bankCode;
            }
            if (data.branchCode != null) {
                message.branchCode = data.branchCode;
            }
            if (data.accountNumber != null) {
                message.accountNumber = data.accountNumber;
            }
            return message;
        }
        toObject() {
            const data: {
                bankCode?: BankCode;
                branchCode?: string;
                accountNumber?: string;
            } = {};
            if (this.bankCode != null) {
                data.bankCode = this.bankCode;
            }
            if (this.branchCode != null) {
                data.branchCode = this.branchCode;
            }
            if (this.accountNumber != null) {
                data.accountNumber = this.accountNumber;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.bankCode != BankCode.BANK_CODE_UNSPECIFIED)
                writer.writeEnum(1, this.bankCode);
            if (this.branchCode.length)
                writer.writeString(2, this.branchCode);
            if (this.accountNumber.length)
                writer.writeString(3, this.accountNumber);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AccountBank {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AccountBank();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.bankCode = reader.readEnum();
                        break;
                    case 2:
                        message.branchCode = reader.readString();
                        break;
                    case 3:
                        message.accountNumber = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AccountBank {
            return AccountBank.deserialize(bytes);
        }
    }
    export class ConfirmAction extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            action?: Map<string, Action>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("action" in data && data.action != undefined) {
                    this.action = data.action;
                }
            }
            if (!this.action)
                this.action = new Map();
        }
        get action() {
            return pb_1.Message.getField(this, 1) as any as Map<string, Action>;
        }
        set action(value: Map<string, Action>) {
            pb_1.Message.setField(this, 1, value as any);
        }
        static fromObject(data: {
            action?: {
                [key: string]: ReturnType<typeof Action.prototype.toObject>;
            };
        }): ConfirmAction {
            const message = new ConfirmAction({});
            if (typeof data.action == "object") {
                message.action = new Map(Object.entries(data.action).map(([key, value]) => [key, Action.fromObject(value)]));
            }
            return message;
        }
        toObject() {
            const data: {
                action?: {
                    [key: string]: ReturnType<typeof Action.prototype.toObject>;
                };
            } = {};
            if (this.action != null) {
                data.action = (Object.fromEntries)((Array.from)(this.action).map(([key, value]) => [key, value.toObject()]));
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            for (const [key, value] of this.action) {
                writer.writeMessage(1, this.action, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ConfirmAction {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ConfirmAction();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.action as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = Action.deserialize(reader));
                            return value;
                        }));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ConfirmAction {
            return ConfirmAction.deserialize(bytes);
        }
    }
    export class Action extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hide?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hide" in data && data.hide != undefined) {
                    this.hide = data.hide;
                }
            }
        }
        get hide() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set hide(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            hide?: boolean;
        }): Action {
            const message = new Action({});
            if (data.hide != null) {
                message.hide = data.hide;
            }
            return message;
        }
        toObject() {
            const data: {
                hide?: boolean;
            } = {};
            if (this.hide != null) {
                data.hide = this.hide;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hide != false)
                writer.writeBool(1, this.hide);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Action {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Action();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hide = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Action {
            return Action.deserialize(bytes);
        }
    }
}
