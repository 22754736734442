/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/smartcontract/v1/smartcontract.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace core.smartcontract.v1 {
    export enum TransactionStatus {
        UNSPECIFIED = 0,
        PENDING = 1,
        SUCCESS = 2,
        FAILED = 3
    }
    export class SmartContract extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            contractAddress?: string;
            abiContent?: string;
            networkId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("contractAddress" in data && data.contractAddress != undefined) {
                    this.contractAddress = data.contractAddress;
                }
                if ("abiContent" in data && data.abiContent != undefined) {
                    this.abiContent = data.abiContent;
                }
                if ("networkId" in data && data.networkId != undefined) {
                    this.networkId = data.networkId;
                }
            }
        }
        get contractAddress() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set contractAddress(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get abiContent() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set abiContent(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get networkId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set networkId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            contractAddress?: string;
            abiContent?: string;
            networkId?: string;
        }): SmartContract {
            const message = new SmartContract({});
            if (data.contractAddress != null) {
                message.contractAddress = data.contractAddress;
            }
            if (data.abiContent != null) {
                message.abiContent = data.abiContent;
            }
            if (data.networkId != null) {
                message.networkId = data.networkId;
            }
            return message;
        }
        toObject() {
            const data: {
                contractAddress?: string;
                abiContent?: string;
                networkId?: string;
            } = {};
            if (this.contractAddress != null) {
                data.contractAddress = this.contractAddress;
            }
            if (this.abiContent != null) {
                data.abiContent = this.abiContent;
            }
            if (this.networkId != null) {
                data.networkId = this.networkId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.contractAddress.length)
                writer.writeString(1, this.contractAddress);
            if (this.abiContent.length)
                writer.writeString(2, this.abiContent);
            if (this.networkId.length)
                writer.writeString(3, this.networkId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SmartContract {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SmartContract();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.contractAddress = reader.readString();
                        break;
                    case 2:
                        message.abiContent = reader.readString();
                        break;
                    case 3:
                        message.networkId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SmartContract {
            return SmartContract.deserialize(bytes);
        }
    }
    export class TransactionReceipt extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            type?: number;
            postState?: Uint8Array;
            status?: TransactionStatus;
            cumulativeGasUsed?: number;
            bloom?: Uint8Array;
            logs?: Log[];
            txHash?: string;
            contractAddress?: string;
            gasUsed?: number;
            effectiveGasPrice?: string;
            blobGasUsed?: number;
            blobGasPrice?: string;
            blockHash?: string;
            blockNumber?: string;
            transactionIndex?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("postState" in data && data.postState != undefined) {
                    this.postState = data.postState;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("cumulativeGasUsed" in data && data.cumulativeGasUsed != undefined) {
                    this.cumulativeGasUsed = data.cumulativeGasUsed;
                }
                if ("bloom" in data && data.bloom != undefined) {
                    this.bloom = data.bloom;
                }
                if ("logs" in data && data.logs != undefined) {
                    this.logs = data.logs;
                }
                if ("txHash" in data && data.txHash != undefined) {
                    this.txHash = data.txHash;
                }
                if ("contractAddress" in data && data.contractAddress != undefined) {
                    this.contractAddress = data.contractAddress;
                }
                if ("gasUsed" in data && data.gasUsed != undefined) {
                    this.gasUsed = data.gasUsed;
                }
                if ("effectiveGasPrice" in data && data.effectiveGasPrice != undefined) {
                    this.effectiveGasPrice = data.effectiveGasPrice;
                }
                if ("blobGasUsed" in data && data.blobGasUsed != undefined) {
                    this.blobGasUsed = data.blobGasUsed;
                }
                if ("blobGasPrice" in data && data.blobGasPrice != undefined) {
                    this.blobGasPrice = data.blobGasPrice;
                }
                if ("blockHash" in data && data.blockHash != undefined) {
                    this.blockHash = data.blockHash;
                }
                if ("blockNumber" in data && data.blockNumber != undefined) {
                    this.blockNumber = data.blockNumber;
                }
                if ("transactionIndex" in data && data.transactionIndex != undefined) {
                    this.transactionIndex = data.transactionIndex;
                }
            }
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set type(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get postState() {
            return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
        }
        set postState(value: Uint8Array) {
            pb_1.Message.setField(this, 2, value);
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 3, TransactionStatus.UNSPECIFIED) as TransactionStatus;
        }
        set status(value: TransactionStatus) {
            pb_1.Message.setField(this, 3, value);
        }
        get cumulativeGasUsed() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set cumulativeGasUsed(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get bloom() {
            return pb_1.Message.getFieldWithDefault(this, 5, new Uint8Array(0)) as Uint8Array;
        }
        set bloom(value: Uint8Array) {
            pb_1.Message.setField(this, 5, value);
        }
        get logs() {
            return pb_1.Message.getRepeatedWrapperField(this, Log, 6) as Log[];
        }
        set logs(value: Log[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get txHash() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set txHash(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get contractAddress() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set contractAddress(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get gasUsed() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set gasUsed(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get effectiveGasPrice() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set effectiveGasPrice(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get blobGasUsed() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set blobGasUsed(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get blobGasPrice() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set blobGasPrice(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get blockHash() {
            return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
        }
        set blockHash(value: string) {
            pb_1.Message.setField(this, 13, value);
        }
        get blockNumber() {
            return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
        }
        set blockNumber(value: string) {
            pb_1.Message.setField(this, 14, value);
        }
        get transactionIndex() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set transactionIndex(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        static fromObject(data: {
            type?: number;
            postState?: Uint8Array;
            status?: TransactionStatus;
            cumulativeGasUsed?: number;
            bloom?: Uint8Array;
            logs?: ReturnType<typeof Log.prototype.toObject>[];
            txHash?: string;
            contractAddress?: string;
            gasUsed?: number;
            effectiveGasPrice?: string;
            blobGasUsed?: number;
            blobGasPrice?: string;
            blockHash?: string;
            blockNumber?: string;
            transactionIndex?: number;
        }): TransactionReceipt {
            const message = new TransactionReceipt({});
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.postState != null) {
                message.postState = data.postState;
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.cumulativeGasUsed != null) {
                message.cumulativeGasUsed = data.cumulativeGasUsed;
            }
            if (data.bloom != null) {
                message.bloom = data.bloom;
            }
            if (data.logs != null) {
                message.logs = data.logs.map(item => Log.fromObject(item));
            }
            if (data.txHash != null) {
                message.txHash = data.txHash;
            }
            if (data.contractAddress != null) {
                message.contractAddress = data.contractAddress;
            }
            if (data.gasUsed != null) {
                message.gasUsed = data.gasUsed;
            }
            if (data.effectiveGasPrice != null) {
                message.effectiveGasPrice = data.effectiveGasPrice;
            }
            if (data.blobGasUsed != null) {
                message.blobGasUsed = data.blobGasUsed;
            }
            if (data.blobGasPrice != null) {
                message.blobGasPrice = data.blobGasPrice;
            }
            if (data.blockHash != null) {
                message.blockHash = data.blockHash;
            }
            if (data.blockNumber != null) {
                message.blockNumber = data.blockNumber;
            }
            if (data.transactionIndex != null) {
                message.transactionIndex = data.transactionIndex;
            }
            return message;
        }
        toObject() {
            const data: {
                type?: number;
                postState?: Uint8Array;
                status?: TransactionStatus;
                cumulativeGasUsed?: number;
                bloom?: Uint8Array;
                logs?: ReturnType<typeof Log.prototype.toObject>[];
                txHash?: string;
                contractAddress?: string;
                gasUsed?: number;
                effectiveGasPrice?: string;
                blobGasUsed?: number;
                blobGasPrice?: string;
                blockHash?: string;
                blockNumber?: string;
                transactionIndex?: number;
            } = {};
            if (this.type != null) {
                data.type = this.type;
            }
            if (this.postState != null) {
                data.postState = this.postState;
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.cumulativeGasUsed != null) {
                data.cumulativeGasUsed = this.cumulativeGasUsed;
            }
            if (this.bloom != null) {
                data.bloom = this.bloom;
            }
            if (this.logs != null) {
                data.logs = this.logs.map((item: Log) => item.toObject());
            }
            if (this.txHash != null) {
                data.txHash = this.txHash;
            }
            if (this.contractAddress != null) {
                data.contractAddress = this.contractAddress;
            }
            if (this.gasUsed != null) {
                data.gasUsed = this.gasUsed;
            }
            if (this.effectiveGasPrice != null) {
                data.effectiveGasPrice = this.effectiveGasPrice;
            }
            if (this.blobGasUsed != null) {
                data.blobGasUsed = this.blobGasUsed;
            }
            if (this.blobGasPrice != null) {
                data.blobGasPrice = this.blobGasPrice;
            }
            if (this.blockHash != null) {
                data.blockHash = this.blockHash;
            }
            if (this.blockNumber != null) {
                data.blockNumber = this.blockNumber;
            }
            if (this.transactionIndex != null) {
                data.transactionIndex = this.transactionIndex;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.type != 0)
                writer.writeUint32(1, this.type);
            if (this.postState.length)
                writer.writeBytes(2, this.postState);
            if (this.status != TransactionStatus.UNSPECIFIED)
                writer.writeEnum(3, this.status);
            if (this.cumulativeGasUsed != 0)
                writer.writeUint64(4, this.cumulativeGasUsed);
            if (this.bloom.length)
                writer.writeBytes(5, this.bloom);
            if (this.logs.length)
                writer.writeRepeatedMessage(6, this.logs, (item: Log) => item.serialize(writer));
            if (this.txHash.length)
                writer.writeString(7, this.txHash);
            if (this.contractAddress.length)
                writer.writeString(8, this.contractAddress);
            if (this.gasUsed != 0)
                writer.writeUint64(9, this.gasUsed);
            if (this.effectiveGasPrice.length)
                writer.writeString(10, this.effectiveGasPrice);
            if (this.blobGasUsed != 0)
                writer.writeUint64(11, this.blobGasUsed);
            if (this.blobGasPrice.length)
                writer.writeString(12, this.blobGasPrice);
            if (this.blockHash.length)
                writer.writeString(13, this.blockHash);
            if (this.blockNumber.length)
                writer.writeString(14, this.blockNumber);
            if (this.transactionIndex != 0)
                writer.writeUint32(15, this.transactionIndex);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionReceipt {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionReceipt();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.type = reader.readUint32();
                        break;
                    case 2:
                        message.postState = reader.readBytes();
                        break;
                    case 3:
                        message.status = reader.readEnum();
                        break;
                    case 4:
                        message.cumulativeGasUsed = reader.readUint64();
                        break;
                    case 5:
                        message.bloom = reader.readBytes();
                        break;
                    case 6:
                        reader.readMessage(message.logs, () => pb_1.Message.addToRepeatedWrapperField(message, 6, Log.deserialize(reader), Log));
                        break;
                    case 7:
                        message.txHash = reader.readString();
                        break;
                    case 8:
                        message.contractAddress = reader.readString();
                        break;
                    case 9:
                        message.gasUsed = reader.readUint64();
                        break;
                    case 10:
                        message.effectiveGasPrice = reader.readString();
                        break;
                    case 11:
                        message.blobGasUsed = reader.readUint64();
                        break;
                    case 12:
                        message.blobGasPrice = reader.readString();
                        break;
                    case 13:
                        message.blockHash = reader.readString();
                        break;
                    case 14:
                        message.blockNumber = reader.readString();
                        break;
                    case 15:
                        message.transactionIndex = reader.readUint32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransactionReceipt {
            return TransactionReceipt.deserialize(bytes);
        }
    }
    export class Log extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            topics?: string[];
            data?: Uint8Array;
            blockNumber?: number;
            txHash?: string;
            txIndex?: number;
            blockHash?: string;
            index?: number;
            removed?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("topics" in data && data.topics != undefined) {
                    this.topics = data.topics;
                }
                if ("data" in data && data.data != undefined) {
                    this.data = data.data;
                }
                if ("blockNumber" in data && data.blockNumber != undefined) {
                    this.blockNumber = data.blockNumber;
                }
                if ("txHash" in data && data.txHash != undefined) {
                    this.txHash = data.txHash;
                }
                if ("txIndex" in data && data.txIndex != undefined) {
                    this.txIndex = data.txIndex;
                }
                if ("blockHash" in data && data.blockHash != undefined) {
                    this.blockHash = data.blockHash;
                }
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
                if ("removed" in data && data.removed != undefined) {
                    this.removed = data.removed;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get topics() {
            return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
        }
        set topics(value: string[]) {
            pb_1.Message.setField(this, 2, value);
        }
        get data() {
            return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
        }
        set data(value: Uint8Array) {
            pb_1.Message.setField(this, 3, value);
        }
        get blockNumber() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set blockNumber(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get txHash() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set txHash(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get txIndex() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set txIndex(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get blockHash() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set blockHash(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get removed() {
            return pb_1.Message.getFieldWithDefault(this, 9, false) as boolean;
        }
        set removed(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        static fromObject(data: {
            address?: string;
            topics?: string[];
            data?: Uint8Array;
            blockNumber?: number;
            txHash?: string;
            txIndex?: number;
            blockHash?: string;
            index?: number;
            removed?: boolean;
        }): Log {
            const message = new Log({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.topics != null) {
                message.topics = data.topics;
            }
            if (data.data != null) {
                message.data = data.data;
            }
            if (data.blockNumber != null) {
                message.blockNumber = data.blockNumber;
            }
            if (data.txHash != null) {
                message.txHash = data.txHash;
            }
            if (data.txIndex != null) {
                message.txIndex = data.txIndex;
            }
            if (data.blockHash != null) {
                message.blockHash = data.blockHash;
            }
            if (data.index != null) {
                message.index = data.index;
            }
            if (data.removed != null) {
                message.removed = data.removed;
            }
            return message;
        }
        toObject() {
            const data: {
                address?: string;
                topics?: string[];
                data?: Uint8Array;
                blockNumber?: number;
                txHash?: string;
                txIndex?: number;
                blockHash?: string;
                index?: number;
                removed?: boolean;
            } = {};
            if (this.address != null) {
                data.address = this.address;
            }
            if (this.topics != null) {
                data.topics = this.topics;
            }
            if (this.data != null) {
                data.data = this.data;
            }
            if (this.blockNumber != null) {
                data.blockNumber = this.blockNumber;
            }
            if (this.txHash != null) {
                data.txHash = this.txHash;
            }
            if (this.txIndex != null) {
                data.txIndex = this.txIndex;
            }
            if (this.blockHash != null) {
                data.blockHash = this.blockHash;
            }
            if (this.index != null) {
                data.index = this.index;
            }
            if (this.removed != null) {
                data.removed = this.removed;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.topics.length)
                writer.writeRepeatedString(2, this.topics);
            if (this.data.length)
                writer.writeBytes(3, this.data);
            if (this.blockNumber != 0)
                writer.writeUint64(4, this.blockNumber);
            if (this.txHash.length)
                writer.writeString(5, this.txHash);
            if (this.txIndex != 0)
                writer.writeUint64(6, this.txIndex);
            if (this.blockHash.length)
                writer.writeString(7, this.blockHash);
            if (this.index != 0)
                writer.writeUint64(8, this.index);
            if (this.removed != false)
                writer.writeBool(9, this.removed);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Log {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Log();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                        break;
                    case 3:
                        message.data = reader.readBytes();
                        break;
                    case 4:
                        message.blockNumber = reader.readUint64();
                        break;
                    case 5:
                        message.txHash = reader.readString();
                        break;
                    case 6:
                        message.txIndex = reader.readUint64();
                        break;
                    case 7:
                        message.blockHash = reader.readString();
                        break;
                    case 8:
                        message.index = reader.readUint64();
                        break;
                    case 9:
                        message.removed = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Log {
            return Log.deserialize(bytes);
        }
    }
}
