import * as CompositeFilter from "@libs/proto/core/common/v1/composite_filter";
import * as Product from "@libs/proto/core/product/v1/product";
import * as ProductApi from "@libs/proto/core/product/v1/product_api";
import * as ProductInfoApi from "@libs/proto/core/product/v1/product_info_api";

import { ProductParams, Template, typeOfInput } from "@libs/models/product";

import { AppName } from "@libs/models/appName";
import { AuthorizationInterceptor } from "@libs/interceptors/interceptor";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";
import { SharedService } from "../shared/shared.service";
import { google } from "@libs/proto/google/protobuf/timestamp";

@Injectable({
  providedIn: "root",
})
export class GrpcProductService {
  private productClient: ProductApi.core.product.v1.ProductAPIClient;
  private productInfoClient: ProductInfoApi.core.product.v1.ProductInfoAPIClient;

  appName: AppName;

  constructor(public mainService: MainService, public shared: SharedService) {
    this.productClient = new ProductApi.core.product.v1.ProductAPIClient(this.mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
    this.productInfoClient = new ProductInfoApi.core.product.v1.ProductInfoAPIClient(this.mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
    this.appName = this.mainService.application;
  }

  getProducts() {
    const request = new ProductInfoApi.core.product.v1.ListProductInfosRequest({
      tokenizable: true,
    });
    return this.productInfoClient.ListProductInfos(request, {});
  }

  getProductDetails(id: string) {
    const request = new ProductApi.core.product.v1.GetProductRequest({ id });
    return this.productClient.GetProduct(request, {});
  }

  getProductSections(id: string) {
    const request = new ProductInfoApi.core.product.v1.GetSectionsProductRequest({ id: id });
    return this.productInfoClient.GetSectionsProductInfo(request, {});
  }

  createProduct(id: string, values: Map<string, string>) {
    const product: Product.core.product.v1.Product = new Product.core.product.v1.Product({
      productInfoId: id,
      values,
    });
    const request = new ProductApi.core.product.v1.CreateProductRequest({
      product,
    });
    return this.productClient.CreateProduct(request, {});
  }

  getProductFilters(productParams: ProductParams): CompositeFilter.core.common.v1.CompositeFilter[] {
    const listCompositeFilter: CompositeFilter.core.common.v1.CompositeFilter[] = [];
    if (this.appName === "ecotas") {
      const isFinishedFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "is_finished",
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
          value: productParams.isFinished ? "true" : "false",
        }),
      });
      listCompositeFilter.push(isFinishedFilter);
    }
    if (productParams.cpfCnpjEmissor) {
      const cpfCnpjEmissorFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "cpf_cnpj_emissor",
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
          value: productParams.cpfCnpjEmissor,
        }),
      });
      listCompositeFilter.push(cpfCnpjEmissorFilter);
    }
    if (productParams.minValue) {
      const minValueFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "valor_nominal",
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_GREATER_THAN_OR_EQUAL_TO,
          value: productParams.minValue.toString(),
        }),
      });
      listCompositeFilter.push(minValueFilter);
    }
    if (productParams.maxValue) {
      const maxValueFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "valor_nominal",
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_LESS_THAN_OR_EQUAL_TO,
          value: productParams.maxValue.toString(),
        }),
      });
      listCompositeFilter.push(maxValueFilter);
    }
    if (productParams.dueDateStart) {
      const dueDateStartFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "data_vencimento",
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_GREATER_THAN_OR_EQUAL_TO,
          value: productParams.dueDateStart,
        }),
      });
      listCompositeFilter.push(dueDateStartFilter);
    }
    if (productParams.dueDateEnd) {
      const dueDateEndFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "data_vencimento",
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_LESS_THAN_OR_EQUAL_TO,
          value: productParams.dueDateEnd,
        }),
      });
      listCompositeFilter.push(dueDateEndFilter);
    }

    return listCompositeFilter;
  }

  listProducts(productParams: ProductParams) {
    if (productParams.productInfoId === "all") {
      productParams.productInfoId = undefined;
    }
    if (productParams.tokenizable === undefined) {
      productParams.tokenizable = true;
    }
    const params = {
      productInfoId: productParams.productInfoId,
      page: productParams.page ? productParams.page : 1,
      itemsPerPage: productParams.itemsPerPage ? productParams.itemsPerPage : 100,
      originId: productParams.originId,
      listStatus: productParams.status?.length > 0 ? productParams.status : undefined,
      createdAtStart: productParams.createdAtStart,
      createdAtEnd: productParams.createdAtEnd,
      tokenizable: productParams.tokenizable,
      filters: this.getProductFilters(productParams),
    };
    const request = new ProductApi.core.product.v1.ListProductsRequest(params);
    return this.productClient.ListProducts(request, {});
  }

  private mapTypeOfInputToParameterType(type: typeOfInput): Product.core.product.v1.ParameterType {
    switch (type) {
      case "unspecified":
        return Product.core.product.v1.ParameterType.unspecified;
      case "string":
        return Product.core.product.v1.ParameterType.string;
      case "number":
        return Product.core.product.v1.ParameterType.number;
      case "date":
        return Product.core.product.v1.ParameterType.date;
      case "tenor":
        return Product.core.product.v1.ParameterType.tenor;
      case "object":
        return Product.core.product.v1.ParameterType.object;
      case "money":
        return Product.core.product.v1.ParameterType.money;
      case "face_value":
        return Product.core.product.v1.ParameterType.face_value;
      case "hour":
        return Product.core.product.v1.ParameterType.hour;
      case "cpf":
        return Product.core.product.v1.ParameterType.cpf;
      case "cnpj":
        return Product.core.product.v1.ParameterType.cnpj;
      case "options_multiple":
        return Product.core.product.v1.ParameterType.options_multiple;
      case "options_single":
        return Product.core.product.v1.ParameterType.options_single;
      case "number_decimal":
        return Product.core.product.v1.ParameterType.number_decimal;
      case "cep":
        return Product.core.product.v1.ParameterType.cep;
      case "phone":
        return Product.core.product.v1.ParameterType.phone;
      case "email":
        return Product.core.product.v1.ParameterType.email;
      case "url":
        return Product.core.product.v1.ParameterType.url;
      case "checkbox":
        return Product.core.product.v1.ParameterType.checkbox;
      case "file":
        return Product.core.product.v1.ParameterType.file;
      case "toggle":
        return Product.core.product.v1.ParameterType.toggle;
      case "document":
        return Product.core.product.v1.ParameterType.document;
      case "":
        return Product.core.product.v1.ParameterType.unspecified;
      default:
        return Product.core.product.v1.ParameterType.unspecified;
    }
  }

  createTemplate(template: Template, tokenizable = false) {
    const grpcSections: Product.core.product.v1.Section[] = template.sections.map((section) => {
      return new Product.core.product.v1.Section({
        id: section.id,
        title: section.title,
        rows: section.rows.map((row) => {
          return new Product.core.product.v1.Row({
            inputs: row.inputs.map((input) => {
              return new Product.core.product.v1.Input({
                id: input.id,
                displayName: input.displayName,
                name: input.id,
                min: input.min,
                max: input.max,
                type: this.mapTypeOfInputToParameterType(input.type),
                required: input.required,
              });
            }),
          });
        }),
      });
    });
    const objRequest = {
      id: template.id,
      displayName: template.displayName,
      sections: grpcSections,
      tokenizable,
    };
    const request = new ProductInfoApi.core.product.v1.CreateSectionsProductInfoRequest(objRequest);
    return this.productInfoClient.CreateSectionsProductInfo(request, {});
  }
}

function googleTimestampToISOString(timestampObj: google.protobuf.Timestamp): string {
  const seconds = timestampObj.seconds;
  const nanos = timestampObj.nanos;

  // Converter segundos e nanossegundos para milissegundos
  const date = new Date(seconds * 1000 + nanos / 1000000);

  // Retornar a data no formato ISO String
  return date.toISOString();
}
