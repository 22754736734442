/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/billing/v1/billing_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./billing";
import * as dependency_2 from "./../../transaction/v1/transaction";
import * as dependency_3 from "./../../../google/protobuf/timestamp";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.billing.v1 {
    export class RegisterTicketRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            transaction?: dependency_2.core.transaction.v1.Transaction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.transaction.v1.Transaction, 2) as dependency_2.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_2.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            companyId?: string;
            transaction?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>;
        }): RegisterTicketRequest {
            const message = new RegisterTicketRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.transaction != null) {
                message.transaction = dependency_2.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                transaction?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.hasTransaction)
                writer.writeMessage(2, this.transaction, () => this.transaction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RegisterTicketRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RegisterTicketRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_2.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): RegisterTicketRequest {
            return RegisterTicketRequest.deserialize(bytes);
        }
    }
    export class RegisterTicketResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            ticket?: dependency_1.core.billing.v1.Ticket;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("ticket" in data && data.ticket != undefined) {
                    this.ticket = data.ticket;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get ticket() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.billing.v1.Ticket, 3) as dependency_1.core.billing.v1.Ticket;
        }
        set ticket(value: dependency_1.core.billing.v1.Ticket) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTicket() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            ticket?: ReturnType<typeof dependency_1.core.billing.v1.Ticket.prototype.toObject>;
        }): RegisterTicketResponse {
            const message = new RegisterTicketResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.ticket != null) {
                message.ticket = dependency_1.core.billing.v1.Ticket.fromObject(data.ticket);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                ticket?: ReturnType<typeof dependency_1.core.billing.v1.Ticket.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.ticket != null) {
                data.ticket = this.ticket.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTicket)
                writer.writeMessage(3, this.ticket, () => this.ticket.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RegisterTicketResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RegisterTicketResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.ticket, () => message.ticket = dependency_1.core.billing.v1.Ticket.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): RegisterTicketResponse {
            return RegisterTicketResponse.deserialize(bytes);
        }
    }
    export class UpdateTicketRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            ticket?: dependency_1.core.billing.v1.Ticket;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ticket" in data && data.ticket != undefined) {
                    this.ticket = data.ticket;
                }
            }
        }
        get ticket() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.billing.v1.Ticket, 1) as dependency_1.core.billing.v1.Ticket;
        }
        set ticket(value: dependency_1.core.billing.v1.Ticket) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasTicket() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            ticket?: ReturnType<typeof dependency_1.core.billing.v1.Ticket.prototype.toObject>;
        }): UpdateTicketRequest {
            const message = new UpdateTicketRequest({});
            if (data.ticket != null) {
                message.ticket = dependency_1.core.billing.v1.Ticket.fromObject(data.ticket);
            }
            return message;
        }
        toObject() {
            const data: {
                ticket?: ReturnType<typeof dependency_1.core.billing.v1.Ticket.prototype.toObject>;
            } = {};
            if (this.ticket != null) {
                data.ticket = this.ticket.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasTicket)
                writer.writeMessage(1, this.ticket, () => this.ticket.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateTicketRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateTicketRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.ticket, () => message.ticket = dependency_1.core.billing.v1.Ticket.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateTicketRequest {
            return UpdateTicketRequest.deserialize(bytes);
        }
    }
    export class UpdateTicketResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            ticket?: dependency_1.core.billing.v1.Ticket;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("ticket" in data && data.ticket != undefined) {
                    this.ticket = data.ticket;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get ticket() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.billing.v1.Ticket, 3) as dependency_1.core.billing.v1.Ticket;
        }
        set ticket(value: dependency_1.core.billing.v1.Ticket) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTicket() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            ticket?: ReturnType<typeof dependency_1.core.billing.v1.Ticket.prototype.toObject>;
        }): UpdateTicketResponse {
            const message = new UpdateTicketResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.ticket != null) {
                message.ticket = dependency_1.core.billing.v1.Ticket.fromObject(data.ticket);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                ticket?: ReturnType<typeof dependency_1.core.billing.v1.Ticket.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.ticket != null) {
                data.ticket = this.ticket.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTicket)
                writer.writeMessage(3, this.ticket, () => this.ticket.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateTicketResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateTicketResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.ticket, () => message.ticket = dependency_1.core.billing.v1.Ticket.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateTicketResponse {
            return UpdateTicketResponse.deserialize(bytes);
        }
    }
    export class SaveTableFeeRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            pricingList?: Map<string, dependency_1.core.billing.v1.PricingList>;
            effectiveDateStart?: dependency_3.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("pricingList" in data && data.pricingList != undefined) {
                    this.pricingList = data.pricingList;
                }
                if ("effectiveDateStart" in data && data.effectiveDateStart != undefined) {
                    this.effectiveDateStart = data.effectiveDateStart;
                }
            }
            if (!this.pricingList)
                this.pricingList = new Map();
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get pricingList() {
            return pb_1.Message.getField(this, 2) as any as Map<string, dependency_1.core.billing.v1.PricingList>;
        }
        set pricingList(value: Map<string, dependency_1.core.billing.v1.PricingList>) {
            pb_1.Message.setField(this, 2, value as any);
        }
        get effectiveDateStart() {
            return pb_1.Message.getWrapperField(this, dependency_3.google.protobuf.Timestamp, 3) as dependency_3.google.protobuf.Timestamp;
        }
        set effectiveDateStart(value: dependency_3.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasEffectiveDateStart() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            companyId?: string;
            pricingList?: {
                [key: string]: ReturnType<typeof dependency_1.core.billing.v1.PricingList.prototype.toObject>;
            };
            effectiveDateStart?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
        }): SaveTableFeeRequest {
            const message = new SaveTableFeeRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (typeof data.pricingList == "object") {
                message.pricingList = new Map(Object.entries(data.pricingList).map(([key, value]) => [key, dependency_1.core.billing.v1.PricingList.fromObject(value)]));
            }
            if (data.effectiveDateStart != null) {
                message.effectiveDateStart = dependency_3.google.protobuf.Timestamp.fromObject(data.effectiveDateStart);
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                pricingList?: {
                    [key: string]: ReturnType<typeof dependency_1.core.billing.v1.PricingList.prototype.toObject>;
                };
                effectiveDateStart?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.pricingList != null) {
                data.pricingList = (Object.fromEntries)((Array.from)(this.pricingList).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.effectiveDateStart != null) {
                data.effectiveDateStart = this.effectiveDateStart.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            for (const [key, value] of this.pricingList) {
                writer.writeMessage(2, this.pricingList, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.hasEffectiveDateStart)
                writer.writeMessage(3, this.effectiveDateStart, () => this.effectiveDateStart.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveTableFeeRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveTableFeeRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.pricingList as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = dependency_1.core.billing.v1.PricingList.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 3:
                        reader.readMessage(message.effectiveDateStart, () => message.effectiveDateStart = dependency_3.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SaveTableFeeRequest {
            return SaveTableFeeRequest.deserialize(bytes);
        }
    }
    export class SaveTableFeeResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            tableFee?: dependency_1.core.billing.v1.TableFee;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("tableFee" in data && data.tableFee != undefined) {
                    this.tableFee = data.tableFee;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get tableFee() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.billing.v1.TableFee, 3) as dependency_1.core.billing.v1.TableFee;
        }
        set tableFee(value: dependency_1.core.billing.v1.TableFee) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTableFee() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            tableFee?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>;
        }): SaveTableFeeResponse {
            const message = new SaveTableFeeResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.tableFee != null) {
                message.tableFee = dependency_1.core.billing.v1.TableFee.fromObject(data.tableFee);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                tableFee?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.tableFee != null) {
                data.tableFee = this.tableFee.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTableFee)
                writer.writeMessage(3, this.tableFee, () => this.tableFee.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveTableFeeResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveTableFeeResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.tableFee, () => message.tableFee = dependency_1.core.billing.v1.TableFee.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SaveTableFeeResponse {
            return SaveTableFeeResponse.deserialize(bytes);
        }
    }
    export class DeleteTableFeeRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            id?: string;
        }): DeleteTableFeeRequest {
            const message = new DeleteTableFeeRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteTableFeeRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteTableFeeRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteTableFeeRequest {
            return DeleteTableFeeRequest.deserialize(bytes);
        }
    }
    export class DeleteTableFeeResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): DeleteTableFeeResponse {
            const message = new DeleteTableFeeResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteTableFeeResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteTableFeeResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteTableFeeResponse {
            return DeleteTableFeeResponse.deserialize(bytes);
        }
    }
    export class GetTableFeeRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            id?: string;
        }): GetTableFeeRequest {
            const message = new GetTableFeeRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTableFeeRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTableFeeRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTableFeeRequest {
            return GetTableFeeRequest.deserialize(bytes);
        }
    }
    export class GetTableFeeResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            tableFee?: dependency_1.core.billing.v1.TableFee;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("tableFee" in data && data.tableFee != undefined) {
                    this.tableFee = data.tableFee;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get tableFee() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.billing.v1.TableFee, 3) as dependency_1.core.billing.v1.TableFee;
        }
        set tableFee(value: dependency_1.core.billing.v1.TableFee) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTableFee() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            tableFee?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>;
        }): GetTableFeeResponse {
            const message = new GetTableFeeResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.tableFee != null) {
                message.tableFee = dependency_1.core.billing.v1.TableFee.fromObject(data.tableFee);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                tableFee?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.tableFee != null) {
                data.tableFee = this.tableFee.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTableFee)
                writer.writeMessage(3, this.tableFee, () => this.tableFee.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTableFeeResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTableFeeResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.tableFee, () => message.tableFee = dependency_1.core.billing.v1.TableFee.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTableFeeResponse {
            return GetTableFeeResponse.deserialize(bytes);
        }
    }
    export class ListTableFeeRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): ListTableFeeRequest {
            const message = new ListTableFeeRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListTableFeeRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListTableFeeRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListTableFeeRequest {
            return ListTableFeeRequest.deserialize(bytes);
        }
    }
    export class ListTableFeeResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            tableFeeList?: dependency_1.core.billing.v1.TableFee[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("tableFeeList" in data && data.tableFeeList != undefined) {
                    this.tableFeeList = data.tableFeeList;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get tableFeeList() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.billing.v1.TableFee, 3) as dependency_1.core.billing.v1.TableFee[];
        }
        set tableFeeList(value: dependency_1.core.billing.v1.TableFee[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            tableFeeList?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>[];
        }): ListTableFeeResponse {
            const message = new ListTableFeeResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.tableFeeList != null) {
                message.tableFeeList = data.tableFeeList.map(item => dependency_1.core.billing.v1.TableFee.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                tableFeeList?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.tableFeeList != null) {
                data.tableFeeList = this.tableFeeList.map((item: dependency_1.core.billing.v1.TableFee) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.tableFeeList.length)
                writer.writeRepeatedMessage(3, this.tableFeeList, (item: dependency_1.core.billing.v1.TableFee) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListTableFeeResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListTableFeeResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.tableFeeList, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.billing.v1.TableFee.deserialize(reader), dependency_1.core.billing.v1.TableFee));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListTableFeeResponse {
            return ListTableFeeResponse.deserialize(bytes);
        }
    }
    export class CompanyTableFeeHistoryRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            page?: number;
            itemsPerPage?: number;
            orderField?: string;
            ordering?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
                if ("orderField" in data && data.orderField != undefined) {
                    this.orderField = data.orderField;
                }
                if ("ordering" in data && data.ordering != undefined) {
                    this.ordering = data.ordering;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get orderField() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set orderField(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get ordering() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set ordering(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            companyId?: string;
            page?: number;
            itemsPerPage?: number;
            orderField?: string;
            ordering?: string;
        }): CompanyTableFeeHistoryRequest {
            const message = new CompanyTableFeeHistoryRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            if (data.orderField != null) {
                message.orderField = data.orderField;
            }
            if (data.ordering != null) {
                message.ordering = data.ordering;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                page?: number;
                itemsPerPage?: number;
                orderField?: string;
                ordering?: string;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            if (this.orderField != null) {
                data.orderField = this.orderField;
            }
            if (this.ordering != null) {
                data.ordering = this.ordering;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.page != 0)
                writer.writeInt64(2, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt64(3, this.itemsPerPage);
            if (this.orderField.length)
                writer.writeString(4, this.orderField);
            if (this.ordering.length)
                writer.writeString(5, this.ordering);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CompanyTableFeeHistoryRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CompanyTableFeeHistoryRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.page = reader.readInt64();
                        break;
                    case 3:
                        message.itemsPerPage = reader.readInt64();
                        break;
                    case 4:
                        message.orderField = reader.readString();
                        break;
                    case 5:
                        message.ordering = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CompanyTableFeeHistoryRequest {
            return CompanyTableFeeHistoryRequest.deserialize(bytes);
        }
    }
    export class CompanyTableFeeHistoryResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            tableFeeList?: dependency_1.core.billing.v1.TableFee[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
                if ("tableFeeList" in data && data.tableFeeList != undefined) {
                    this.tableFeeList = data.tableFeeList;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get tableFeeList() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.billing.v1.TableFee, 6) as dependency_1.core.billing.v1.TableFee[];
        }
        set tableFeeList(value: dependency_1.core.billing.v1.TableFee[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            tableFeeList?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>[];
        }): CompanyTableFeeHistoryResponse {
            const message = new CompanyTableFeeHistoryResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            if (data.tableFeeList != null) {
                message.tableFeeList = data.tableFeeList.map(item => dependency_1.core.billing.v1.TableFee.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
                tableFeeList?: ReturnType<typeof dependency_1.core.billing.v1.TableFee.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            if (this.tableFeeList != null) {
                data.tableFeeList = this.tableFeeList.map((item: dependency_1.core.billing.v1.TableFee) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.currentPage != 0)
                writer.writeInt32(3, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(4, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt32(5, this.totalItems);
            if (this.tableFeeList.length)
                writer.writeRepeatedMessage(6, this.tableFeeList, (item: dependency_1.core.billing.v1.TableFee) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CompanyTableFeeHistoryResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CompanyTableFeeHistoryResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.currentPage = reader.readInt32();
                        break;
                    case 4:
                        message.totalPages = reader.readInt32();
                        break;
                    case 5:
                        message.totalItems = reader.readInt32();
                        break;
                    case 6:
                        reader.readMessage(message.tableFeeList, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_1.core.billing.v1.TableFee.deserialize(reader), dependency_1.core.billing.v1.TableFee));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CompanyTableFeeHistoryResponse {
            return CompanyTableFeeHistoryResponse.deserialize(bytes);
        }
    }
    export class ListBillingReportsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            page?: number;
            itemsPerPage?: number;
            orderField?: string;
            ordering?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
                if ("orderField" in data && data.orderField != undefined) {
                    this.orderField = data.orderField;
                }
                if ("ordering" in data && data.ordering != undefined) {
                    this.ordering = data.ordering;
                }
            }
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get orderField() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set orderField(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get ordering() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set ordering(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            page?: number;
            itemsPerPage?: number;
            orderField?: string;
            ordering?: string;
        }): ListBillingReportsRequest {
            const message = new ListBillingReportsRequest({});
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            if (data.orderField != null) {
                message.orderField = data.orderField;
            }
            if (data.ordering != null) {
                message.ordering = data.ordering;
            }
            return message;
        }
        toObject() {
            const data: {
                page?: number;
                itemsPerPage?: number;
                orderField?: string;
                ordering?: string;
            } = {};
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            if (this.orderField != null) {
                data.orderField = this.orderField;
            }
            if (this.ordering != null) {
                data.ordering = this.ordering;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.page != 0)
                writer.writeInt32(1, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt32(2, this.itemsPerPage);
            if (this.orderField.length)
                writer.writeString(3, this.orderField);
            if (this.ordering.length)
                writer.writeString(4, this.ordering);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListBillingReportsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListBillingReportsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.page = reader.readInt32();
                        break;
                    case 2:
                        message.itemsPerPage = reader.readInt32();
                        break;
                    case 3:
                        message.orderField = reader.readString();
                        break;
                    case 4:
                        message.ordering = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListBillingReportsRequest {
            return ListBillingReportsRequest.deserialize(bytes);
        }
    }
    export class ListBillingReportsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            billingReports?: dependency_1.core.billing.v1.BillingReport[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
                if ("billingReports" in data && data.billingReports != undefined) {
                    this.billingReports = data.billingReports;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get billingReports() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.billing.v1.BillingReport, 6) as dependency_1.core.billing.v1.BillingReport[];
        }
        set billingReports(value: dependency_1.core.billing.v1.BillingReport[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            billingReports?: ReturnType<typeof dependency_1.core.billing.v1.BillingReport.prototype.toObject>[];
        }): ListBillingReportsResponse {
            const message = new ListBillingReportsResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            if (data.billingReports != null) {
                message.billingReports = data.billingReports.map(item => dependency_1.core.billing.v1.BillingReport.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
                billingReports?: ReturnType<typeof dependency_1.core.billing.v1.BillingReport.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            if (this.billingReports != null) {
                data.billingReports = this.billingReports.map((item: dependency_1.core.billing.v1.BillingReport) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.currentPage != 0)
                writer.writeInt32(3, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(4, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt32(5, this.totalItems);
            if (this.billingReports.length)
                writer.writeRepeatedMessage(6, this.billingReports, (item: dependency_1.core.billing.v1.BillingReport) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListBillingReportsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListBillingReportsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.currentPage = reader.readInt32();
                        break;
                    case 4:
                        message.totalPages = reader.readInt32();
                        break;
                    case 5:
                        message.totalItems = reader.readInt32();
                        break;
                    case 6:
                        reader.readMessage(message.billingReports, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_1.core.billing.v1.BillingReport.deserialize(reader), dependency_1.core.billing.v1.BillingReport));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListBillingReportsResponse {
            return ListBillingReportsResponse.deserialize(bytes);
        }
    }
    export class DownloadBillingReportRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            filename?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("filename" in data && data.filename != undefined) {
                    this.filename = data.filename;
                }
            }
        }
        get filename() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set filename(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            filename?: string;
        }): DownloadBillingReportRequest {
            const message = new DownloadBillingReportRequest({});
            if (data.filename != null) {
                message.filename = data.filename;
            }
            return message;
        }
        toObject() {
            const data: {
                filename?: string;
            } = {};
            if (this.filename != null) {
                data.filename = this.filename;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.filename.length)
                writer.writeString(1, this.filename);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DownloadBillingReportRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DownloadBillingReportRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.filename = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DownloadBillingReportRequest {
            return DownloadBillingReportRequest.deserialize(bytes);
        }
    }
    export class DownloadBillingReportResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            filename?: string;
            fileContent?: Uint8Array;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("filename" in data && data.filename != undefined) {
                    this.filename = data.filename;
                }
                if ("fileContent" in data && data.fileContent != undefined) {
                    this.fileContent = data.fileContent;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get filename() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set filename(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get fileContent() {
            return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
        }
        set fileContent(value: Uint8Array) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            filename?: string;
            fileContent?: Uint8Array;
        }): DownloadBillingReportResponse {
            const message = new DownloadBillingReportResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.filename != null) {
                message.filename = data.filename;
            }
            if (data.fileContent != null) {
                message.fileContent = data.fileContent;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                filename?: string;
                fileContent?: Uint8Array;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.filename != null) {
                data.filename = this.filename;
            }
            if (this.fileContent != null) {
                data.fileContent = this.fileContent;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.filename.length)
                writer.writeString(3, this.filename);
            if (this.fileContent.length)
                writer.writeBytes(4, this.fileContent);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DownloadBillingReportResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DownloadBillingReportResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.filename = reader.readString();
                        break;
                    case 4:
                        message.fileContent = reader.readBytes();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DownloadBillingReportResponse {
            return DownloadBillingReportResponse.deserialize(bytes);
        }
    }
    export class ReprocessBillingReportRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            id?: string;
        }): ReprocessBillingReportRequest {
            const message = new ReprocessBillingReportRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReprocessBillingReportRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReprocessBillingReportRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ReprocessBillingReportRequest {
            return ReprocessBillingReportRequest.deserialize(bytes);
        }
    }
    export class ReprocessBillingReportResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): ReprocessBillingReportResponse {
            const message = new ReprocessBillingReportResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReprocessBillingReportResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReprocessBillingReportResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ReprocessBillingReportResponse {
            return ReprocessBillingReportResponse.deserialize(bytes);
        }
    }
    export class GetTicketListRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            productInfoId?: string;
            transactionType?: dependency_2.core.transaction.v1.TransactionType;
            createdAtStart?: dependency_3.google.protobuf.Timestamp;
            createdAtEnd?: dependency_3.google.protobuf.Timestamp;
            page?: number;
            itemsPerPage?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("createdAtStart" in data && data.createdAtStart != undefined) {
                    this.createdAtStart = data.createdAtStart;
                }
                if ("createdAtEnd" in data && data.createdAtEnd != undefined) {
                    this.createdAtEnd = data.createdAtEnd;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 3, dependency_2.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as dependency_2.core.transaction.v1.TransactionType;
        }
        set transactionType(value: dependency_2.core.transaction.v1.TransactionType) {
            pb_1.Message.setField(this, 3, value);
        }
        get createdAtStart() {
            return pb_1.Message.getWrapperField(this, dependency_3.google.protobuf.Timestamp, 4) as dependency_3.google.protobuf.Timestamp;
        }
        set createdAtStart(value: dependency_3.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasCreatedAtStart() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get createdAtEnd() {
            return pb_1.Message.getWrapperField(this, dependency_3.google.protobuf.Timestamp, 5) as dependency_3.google.protobuf.Timestamp;
        }
        set createdAtEnd(value: dependency_3.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasCreatedAtEnd() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            companyId?: string;
            productInfoId?: string;
            transactionType?: dependency_2.core.transaction.v1.TransactionType;
            createdAtStart?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
            createdAtEnd?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
            page?: number;
            itemsPerPage?: number;
        }): GetTicketListRequest {
            const message = new GetTicketListRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (data.createdAtStart != null) {
                message.createdAtStart = dependency_3.google.protobuf.Timestamp.fromObject(data.createdAtStart);
            }
            if (data.createdAtEnd != null) {
                message.createdAtEnd = dependency_3.google.protobuf.Timestamp.fromObject(data.createdAtEnd);
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                productInfoId?: string;
                transactionType?: dependency_2.core.transaction.v1.TransactionType;
                createdAtStart?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
                createdAtEnd?: ReturnType<typeof dependency_3.google.protobuf.Timestamp.prototype.toObject>;
                page?: number;
                itemsPerPage?: number;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.createdAtStart != null) {
                data.createdAtStart = this.createdAtStart.toObject();
            }
            if (this.createdAtEnd != null) {
                data.createdAtEnd = this.createdAtEnd.toObject();
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.productInfoId.length)
                writer.writeString(2, this.productInfoId);
            if (this.transactionType != dependency_2.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(3, this.transactionType);
            if (this.hasCreatedAtStart)
                writer.writeMessage(4, this.createdAtStart, () => this.createdAtStart.serialize(writer));
            if (this.hasCreatedAtEnd)
                writer.writeMessage(5, this.createdAtEnd, () => this.createdAtEnd.serialize(writer));
            if (this.page != 0)
                writer.writeInt32(6, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt32(7, this.itemsPerPage);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTicketListRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTicketListRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.productInfoId = reader.readString();
                        break;
                    case 3:
                        message.transactionType = reader.readEnum();
                        break;
                    case 4:
                        reader.readMessage(message.createdAtStart, () => message.createdAtStart = dependency_3.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.createdAtEnd, () => message.createdAtEnd = dependency_3.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 6:
                        message.page = reader.readInt32();
                        break;
                    case 7:
                        message.itemsPerPage = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTicketListRequest {
            return GetTicketListRequest.deserialize(bytes);
        }
    }
    export class GetTicketListResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            ticketSummary?: dependency_1.core.billing.v1.TicketSummary;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
                if ("ticketSummary" in data && data.ticketSummary != undefined) {
                    this.ticketSummary = data.ticketSummary;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get ticketSummary() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.billing.v1.TicketSummary, 6) as dependency_1.core.billing.v1.TicketSummary;
        }
        set ticketSummary(value: dependency_1.core.billing.v1.TicketSummary) {
            pb_1.Message.setWrapperField(this, 6, value);
        }
        get hasTicketSummary() {
            return pb_1.Message.getField(this, 6) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            ticketSummary?: ReturnType<typeof dependency_1.core.billing.v1.TicketSummary.prototype.toObject>;
        }): GetTicketListResponse {
            const message = new GetTicketListResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            if (data.ticketSummary != null) {
                message.ticketSummary = dependency_1.core.billing.v1.TicketSummary.fromObject(data.ticketSummary);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
                ticketSummary?: ReturnType<typeof dependency_1.core.billing.v1.TicketSummary.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            if (this.ticketSummary != null) {
                data.ticketSummary = this.ticketSummary.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.currentPage != 0)
                writer.writeInt32(3, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(4, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt32(5, this.totalItems);
            if (this.hasTicketSummary)
                writer.writeMessage(6, this.ticketSummary, () => this.ticketSummary.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTicketListResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTicketListResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.currentPage = reader.readInt32();
                        break;
                    case 4:
                        message.totalPages = reader.readInt32();
                        break;
                    case 5:
                        message.totalItems = reader.readInt32();
                        break;
                    case 6:
                        reader.readMessage(message.ticketSummary, () => message.ticketSummary = dependency_1.core.billing.v1.TicketSummary.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTicketListResponse {
            return GetTicketListResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedBillingAPIService {
        static definition = {
            RegisterTicket: {
                path: "/core.billing.v1.BillingAPI/RegisterTicket",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: RegisterTicketRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => RegisterTicketRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: RegisterTicketResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => RegisterTicketResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateTicket: {
                path: "/core.billing.v1.BillingAPI/UpdateTicket",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateTicketRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateTicketRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateTicketResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateTicketResponse.deserialize(new Uint8Array(bytes))
            },
            GetTicketList: {
                path: "/core.billing.v1.BillingAPI/GetTicketList",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTicketListRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTicketListRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTicketListResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTicketListResponse.deserialize(new Uint8Array(bytes))
            },
            SaveTableFee: {
                path: "/core.billing.v1.BillingAPI/SaveTableFee",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: SaveTableFeeRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => SaveTableFeeRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: SaveTableFeeResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => SaveTableFeeResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteTableFee: {
                path: "/core.billing.v1.BillingAPI/DeleteTableFee",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteTableFeeRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteTableFeeRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteTableFeeResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteTableFeeResponse.deserialize(new Uint8Array(bytes))
            },
            GetTableFee: {
                path: "/core.billing.v1.BillingAPI/GetTableFee",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTableFeeRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTableFeeRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTableFeeResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTableFeeResponse.deserialize(new Uint8Array(bytes))
            },
            ListTableFee: {
                path: "/core.billing.v1.BillingAPI/ListTableFee",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListTableFeeRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListTableFeeRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListTableFeeResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListTableFeeResponse.deserialize(new Uint8Array(bytes))
            },
            ListBillingReports: {
                path: "/core.billing.v1.BillingAPI/ListBillingReports",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListBillingReportsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListBillingReportsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListBillingReportsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListBillingReportsResponse.deserialize(new Uint8Array(bytes))
            },
            DownloadBillingReport: {
                path: "/core.billing.v1.BillingAPI/DownloadBillingReport",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DownloadBillingReportRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DownloadBillingReportRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DownloadBillingReportResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DownloadBillingReportResponse.deserialize(new Uint8Array(bytes))
            },
            ReprocessBillingReport: {
                path: "/core.billing.v1.BillingAPI/ReprocessBillingReport",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ReprocessBillingReportRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ReprocessBillingReportRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ReprocessBillingReportResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ReprocessBillingReportResponse.deserialize(new Uint8Array(bytes))
            },
            CompanyTableFeeHistory: {
                path: "/core.billing.v1.BillingAPI/CompanyTableFeeHistory",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CompanyTableFeeHistoryRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CompanyTableFeeHistoryRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CompanyTableFeeHistoryResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CompanyTableFeeHistoryResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract RegisterTicket(call: grpc_1.ServerUnaryCall<RegisterTicketRequest, RegisterTicketResponse>, callback: grpc_1.sendUnaryData<RegisterTicketResponse>): void;
        abstract UpdateTicket(call: grpc_1.ServerUnaryCall<UpdateTicketRequest, UpdateTicketResponse>, callback: grpc_1.sendUnaryData<UpdateTicketResponse>): void;
        abstract GetTicketList(call: grpc_1.ServerUnaryCall<GetTicketListRequest, GetTicketListResponse>, callback: grpc_1.sendUnaryData<GetTicketListResponse>): void;
        abstract SaveTableFee(call: grpc_1.ServerUnaryCall<SaveTableFeeRequest, SaveTableFeeResponse>, callback: grpc_1.sendUnaryData<SaveTableFeeResponse>): void;
        abstract DeleteTableFee(call: grpc_1.ServerUnaryCall<DeleteTableFeeRequest, DeleteTableFeeResponse>, callback: grpc_1.sendUnaryData<DeleteTableFeeResponse>): void;
        abstract GetTableFee(call: grpc_1.ServerUnaryCall<GetTableFeeRequest, GetTableFeeResponse>, callback: grpc_1.sendUnaryData<GetTableFeeResponse>): void;
        abstract ListTableFee(call: grpc_1.ServerUnaryCall<ListTableFeeRequest, ListTableFeeResponse>, callback: grpc_1.sendUnaryData<ListTableFeeResponse>): void;
        abstract ListBillingReports(call: grpc_1.ServerUnaryCall<ListBillingReportsRequest, ListBillingReportsResponse>, callback: grpc_1.sendUnaryData<ListBillingReportsResponse>): void;
        abstract DownloadBillingReport(call: grpc_1.ServerUnaryCall<DownloadBillingReportRequest, DownloadBillingReportResponse>, callback: grpc_1.sendUnaryData<DownloadBillingReportResponse>): void;
        abstract ReprocessBillingReport(call: grpc_1.ServerUnaryCall<ReprocessBillingReportRequest, ReprocessBillingReportResponse>, callback: grpc_1.sendUnaryData<ReprocessBillingReportResponse>): void;
        abstract CompanyTableFeeHistory(call: grpc_1.ServerUnaryCall<CompanyTableFeeHistoryRequest, CompanyTableFeeHistoryResponse>, callback: grpc_1.sendUnaryData<CompanyTableFeeHistoryResponse>): void;
    }
    export class BillingAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static RegisterTicket = new grpc_web_1.MethodDescriptor<RegisterTicketRequest, RegisterTicketResponse>("/core.billing.v1.BillingAPI/RegisterTicket", grpc_web_1.MethodType.UNARY, RegisterTicketRequest, RegisterTicketResponse, (message: RegisterTicketRequest) => message.serialize(), RegisterTicketResponse.deserialize);
        RegisterTicket(message: RegisterTicketRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<RegisterTicketRequest, RegisterTicketResponse>(this._address + "/core.billing.v1.BillingAPI/RegisterTicket", message, metadata || {}, BillingAPIClient.RegisterTicket);
        }
        private static UpdateTicket = new grpc_web_1.MethodDescriptor<UpdateTicketRequest, UpdateTicketResponse>("/core.billing.v1.BillingAPI/UpdateTicket", grpc_web_1.MethodType.UNARY, UpdateTicketRequest, UpdateTicketResponse, (message: UpdateTicketRequest) => message.serialize(), UpdateTicketResponse.deserialize);
        UpdateTicket(message: UpdateTicketRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateTicketRequest, UpdateTicketResponse>(this._address + "/core.billing.v1.BillingAPI/UpdateTicket", message, metadata || {}, BillingAPIClient.UpdateTicket);
        }
        private static GetTicketList = new grpc_web_1.MethodDescriptor<GetTicketListRequest, GetTicketListResponse>("/core.billing.v1.BillingAPI/GetTicketList", grpc_web_1.MethodType.UNARY, GetTicketListRequest, GetTicketListResponse, (message: GetTicketListRequest) => message.serialize(), GetTicketListResponse.deserialize);
        GetTicketList(message: GetTicketListRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTicketListRequest, GetTicketListResponse>(this._address + "/core.billing.v1.BillingAPI/GetTicketList", message, metadata || {}, BillingAPIClient.GetTicketList);
        }
        private static SaveTableFee = new grpc_web_1.MethodDescriptor<SaveTableFeeRequest, SaveTableFeeResponse>("/core.billing.v1.BillingAPI/SaveTableFee", grpc_web_1.MethodType.UNARY, SaveTableFeeRequest, SaveTableFeeResponse, (message: SaveTableFeeRequest) => message.serialize(), SaveTableFeeResponse.deserialize);
        SaveTableFee(message: SaveTableFeeRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<SaveTableFeeRequest, SaveTableFeeResponse>(this._address + "/core.billing.v1.BillingAPI/SaveTableFee", message, metadata || {}, BillingAPIClient.SaveTableFee);
        }
        private static DeleteTableFee = new grpc_web_1.MethodDescriptor<DeleteTableFeeRequest, DeleteTableFeeResponse>("/core.billing.v1.BillingAPI/DeleteTableFee", grpc_web_1.MethodType.UNARY, DeleteTableFeeRequest, DeleteTableFeeResponse, (message: DeleteTableFeeRequest) => message.serialize(), DeleteTableFeeResponse.deserialize);
        DeleteTableFee(message: DeleteTableFeeRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteTableFeeRequest, DeleteTableFeeResponse>(this._address + "/core.billing.v1.BillingAPI/DeleteTableFee", message, metadata || {}, BillingAPIClient.DeleteTableFee);
        }
        private static GetTableFee = new grpc_web_1.MethodDescriptor<GetTableFeeRequest, GetTableFeeResponse>("/core.billing.v1.BillingAPI/GetTableFee", grpc_web_1.MethodType.UNARY, GetTableFeeRequest, GetTableFeeResponse, (message: GetTableFeeRequest) => message.serialize(), GetTableFeeResponse.deserialize);
        GetTableFee(message: GetTableFeeRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTableFeeRequest, GetTableFeeResponse>(this._address + "/core.billing.v1.BillingAPI/GetTableFee", message, metadata || {}, BillingAPIClient.GetTableFee);
        }
        private static ListTableFee = new grpc_web_1.MethodDescriptor<ListTableFeeRequest, ListTableFeeResponse>("/core.billing.v1.BillingAPI/ListTableFee", grpc_web_1.MethodType.UNARY, ListTableFeeRequest, ListTableFeeResponse, (message: ListTableFeeRequest) => message.serialize(), ListTableFeeResponse.deserialize);
        ListTableFee(message: ListTableFeeRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListTableFeeRequest, ListTableFeeResponse>(this._address + "/core.billing.v1.BillingAPI/ListTableFee", message, metadata || {}, BillingAPIClient.ListTableFee);
        }
        private static ListBillingReports = new grpc_web_1.MethodDescriptor<ListBillingReportsRequest, ListBillingReportsResponse>("/core.billing.v1.BillingAPI/ListBillingReports", grpc_web_1.MethodType.UNARY, ListBillingReportsRequest, ListBillingReportsResponse, (message: ListBillingReportsRequest) => message.serialize(), ListBillingReportsResponse.deserialize);
        ListBillingReports(message: ListBillingReportsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListBillingReportsRequest, ListBillingReportsResponse>(this._address + "/core.billing.v1.BillingAPI/ListBillingReports", message, metadata || {}, BillingAPIClient.ListBillingReports);
        }
        private static DownloadBillingReport = new grpc_web_1.MethodDescriptor<DownloadBillingReportRequest, DownloadBillingReportResponse>("/core.billing.v1.BillingAPI/DownloadBillingReport", grpc_web_1.MethodType.UNARY, DownloadBillingReportRequest, DownloadBillingReportResponse, (message: DownloadBillingReportRequest) => message.serialize(), DownloadBillingReportResponse.deserialize);
        DownloadBillingReport(message: DownloadBillingReportRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DownloadBillingReportRequest, DownloadBillingReportResponse>(this._address + "/core.billing.v1.BillingAPI/DownloadBillingReport", message, metadata || {}, BillingAPIClient.DownloadBillingReport);
        }
        private static ReprocessBillingReport = new grpc_web_1.MethodDescriptor<ReprocessBillingReportRequest, ReprocessBillingReportResponse>("/core.billing.v1.BillingAPI/ReprocessBillingReport", grpc_web_1.MethodType.UNARY, ReprocessBillingReportRequest, ReprocessBillingReportResponse, (message: ReprocessBillingReportRequest) => message.serialize(), ReprocessBillingReportResponse.deserialize);
        ReprocessBillingReport(message: ReprocessBillingReportRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ReprocessBillingReportRequest, ReprocessBillingReportResponse>(this._address + "/core.billing.v1.BillingAPI/ReprocessBillingReport", message, metadata || {}, BillingAPIClient.ReprocessBillingReport);
        }
        private static CompanyTableFeeHistory = new grpc_web_1.MethodDescriptor<CompanyTableFeeHistoryRequest, CompanyTableFeeHistoryResponse>("/core.billing.v1.BillingAPI/CompanyTableFeeHistory", grpc_web_1.MethodType.UNARY, CompanyTableFeeHistoryRequest, CompanyTableFeeHistoryResponse, (message: CompanyTableFeeHistoryRequest) => message.serialize(), CompanyTableFeeHistoryResponse.deserialize);
        CompanyTableFeeHistory(message: CompanyTableFeeHistoryRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CompanyTableFeeHistoryRequest, CompanyTableFeeHistoryResponse>(this._address + "/core.billing.v1.BillingAPI/CompanyTableFeeHistory", message, metadata || {}, BillingAPIClient.CompanyTableFeeHistory);
        }
    }
}
