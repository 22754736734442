/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/product/v1/product_info_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./product";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.product.v1 {
    export class ListProductInfosRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): ListProductInfosRequest {
            const message = new ListProductInfosRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListProductInfosRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListProductInfosRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListProductInfosRequest {
            return ListProductInfosRequest.deserialize(bytes);
        }
    }
    export class ListProductInfosResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productInfos?: dependency_1.core.product.v1.ProductInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productInfos" in data && data.productInfos != undefined) {
                    this.productInfos = data.productInfos;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productInfos() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.product.v1.ProductInfo, 3) as dependency_1.core.product.v1.ProductInfo[];
        }
        set productInfos(value: dependency_1.core.product.v1.ProductInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productInfos?: ReturnType<typeof dependency_1.core.product.v1.ProductInfo.prototype.toObject>[];
        }): ListProductInfosResponse {
            const message = new ListProductInfosResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productInfos != null) {
                message.productInfos = data.productInfos.map(item => dependency_1.core.product.v1.ProductInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productInfos?: ReturnType<typeof dependency_1.core.product.v1.ProductInfo.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productInfos != null) {
                data.productInfos = this.productInfos.map((item: dependency_1.core.product.v1.ProductInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productInfos.length)
                writer.writeRepeatedMessage(3, this.productInfos, (item: dependency_1.core.product.v1.ProductInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListProductInfosResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListProductInfosResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.productInfos, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.product.v1.ProductInfo.deserialize(reader), dependency_1.core.product.v1.ProductInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListProductInfosResponse {
            return ListProductInfosResponse.deserialize(bytes);
        }
    }
    export class ListSectionsProductInfosRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): ListSectionsProductInfosRequest {
            const message = new ListSectionsProductInfosRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListSectionsProductInfosRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListSectionsProductInfosRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListSectionsProductInfosRequest {
            return ListSectionsProductInfosRequest.deserialize(bytes);
        }
    }
    export class ListSectionsProductInfosResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            sectionsProductInfo?: dependency_1.core.product.v1.SectionsProductInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("sectionsProductInfo" in data && data.sectionsProductInfo != undefined) {
                    this.sectionsProductInfo = data.sectionsProductInfo;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sectionsProductInfo() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.product.v1.SectionsProductInfo, 3) as dependency_1.core.product.v1.SectionsProductInfo[];
        }
        set sectionsProductInfo(value: dependency_1.core.product.v1.SectionsProductInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>[];
        }): ListSectionsProductInfosResponse {
            const message = new ListSectionsProductInfosResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.sectionsProductInfo != null) {
                message.sectionsProductInfo = data.sectionsProductInfo.map(item => dependency_1.core.product.v1.SectionsProductInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.sectionsProductInfo != null) {
                data.sectionsProductInfo = this.sectionsProductInfo.map((item: dependency_1.core.product.v1.SectionsProductInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.sectionsProductInfo.length)
                writer.writeRepeatedMessage(3, this.sectionsProductInfo, (item: dependency_1.core.product.v1.SectionsProductInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListSectionsProductInfosResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListSectionsProductInfosResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.sectionsProductInfo, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.product.v1.SectionsProductInfo.deserialize(reader), dependency_1.core.product.v1.SectionsProductInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListSectionsProductInfosResponse {
            return ListSectionsProductInfosResponse.deserialize(bytes);
        }
    }
    export class GetProductInfoRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productCategory?: dependency_1.core.product.v1.ProductCategory;
            productInfoId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productCategory" in data && data.productCategory != undefined) {
                    this.productCategory = data.productCategory;
                }
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
            }
        }
        get productCategory() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.ProductCategory, 1) as dependency_1.core.product.v1.ProductCategory;
        }
        set productCategory(value: dependency_1.core.product.v1.ProductCategory) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProductCategory() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            productCategory?: ReturnType<typeof dependency_1.core.product.v1.ProductCategory.prototype.toObject>;
            productInfoId?: string;
        }): GetProductInfoRequest {
            const message = new GetProductInfoRequest({});
            if (data.productCategory != null) {
                message.productCategory = dependency_1.core.product.v1.ProductCategory.fromObject(data.productCategory);
            }
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            return message;
        }
        toObject() {
            const data: {
                productCategory?: ReturnType<typeof dependency_1.core.product.v1.ProductCategory.prototype.toObject>;
                productInfoId?: string;
            } = {};
            if (this.productCategory != null) {
                data.productCategory = this.productCategory.toObject();
            }
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProductCategory)
                writer.writeMessage(1, this.productCategory, () => this.productCategory.serialize(writer));
            if (this.productInfoId.length)
                writer.writeString(2, this.productInfoId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProductInfoRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProductInfoRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.productCategory, () => message.productCategory = dependency_1.core.product.v1.ProductCategory.deserialize(reader));
                        break;
                    case 2:
                        message.productInfoId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProductInfoRequest {
            return GetProductInfoRequest.deserialize(bytes);
        }
    }
    export class GetProductInfoResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productInfo?: dependency_1.core.product.v1.ProductInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productInfo" in data && data.productInfo != undefined) {
                    this.productInfo = data.productInfo;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productInfo() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.ProductInfo, 3) as dependency_1.core.product.v1.ProductInfo;
        }
        set productInfo(value: dependency_1.core.product.v1.ProductInfo) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasProductInfo() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productInfo?: ReturnType<typeof dependency_1.core.product.v1.ProductInfo.prototype.toObject>;
        }): GetProductInfoResponse {
            const message = new GetProductInfoResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productInfo != null) {
                message.productInfo = dependency_1.core.product.v1.ProductInfo.fromObject(data.productInfo);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productInfo?: ReturnType<typeof dependency_1.core.product.v1.ProductInfo.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productInfo != null) {
                data.productInfo = this.productInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasProductInfo)
                writer.writeMessage(3, this.productInfo, () => this.productInfo.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetProductInfoResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetProductInfoResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.productInfo, () => message.productInfo = dependency_1.core.product.v1.ProductInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetProductInfoResponse {
            return GetProductInfoResponse.deserialize(bytes);
        }
    }
    export class CreateProductInfoRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            product?: dependency_1.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.Product, 1) as dependency_1.core.product.v1.Product;
        }
        set product(value: dependency_1.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
        }): CreateProductInfoRequest {
            const message = new CreateProductInfoRequest({});
            if (data.product != null) {
                message.product = dependency_1.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProduct)
                writer.writeMessage(1, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateProductInfoRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateProductInfoRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.product, () => message.product = dependency_1.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateProductInfoRequest {
            return CreateProductInfoRequest.deserialize(bytes);
        }
    }
    export class AddContractAddressRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productInfoId?: string;
            contractAddress?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("contractAddress" in data && data.contractAddress != undefined) {
                    this.contractAddress = data.contractAddress;
                }
            }
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get contractAddress() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set contractAddress(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            productInfoId?: string;
            contractAddress?: string;
        }): AddContractAddressRequest {
            const message = new AddContractAddressRequest({});
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.contractAddress != null) {
                message.contractAddress = data.contractAddress;
            }
            return message;
        }
        toObject() {
            const data: {
                productInfoId?: string;
                contractAddress?: string;
            } = {};
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.contractAddress != null) {
                data.contractAddress = this.contractAddress;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productInfoId.length)
                writer.writeString(1, this.productInfoId);
            if (this.contractAddress.length)
                writer.writeString(2, this.contractAddress);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddContractAddressRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddContractAddressRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productInfoId = reader.readString();
                        break;
                    case 2:
                        message.contractAddress = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddContractAddressRequest {
            return AddContractAddressRequest.deserialize(bytes);
        }
    }
    export class AddContractAddressResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): AddContractAddressResponse {
            const message = new AddContractAddressResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddContractAddressResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddContractAddressResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddContractAddressResponse {
            return AddContractAddressResponse.deserialize(bytes);
        }
    }
    export class CreateProductInfoResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            product?: dependency_1.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.Product, 3) as dependency_1.core.product.v1.Product;
        }
        set product(value: dependency_1.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
        }): CreateProductInfoResponse {
            const message = new CreateProductInfoResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.product != null) {
                message.product = dependency_1.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                product?: ReturnType<typeof dependency_1.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasProduct)
                writer.writeMessage(3, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateProductInfoResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateProductInfoResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.product, () => message.product = dependency_1.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateProductInfoResponse {
            return CreateProductInfoResponse.deserialize(bytes);
        }
    }
    export class GetSectionsProductRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            id?: string;
        }): GetSectionsProductRequest {
            const message = new GetSectionsProductRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSectionsProductRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetSectionsProductRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetSectionsProductRequest {
            return GetSectionsProductRequest.deserialize(bytes);
        }
    }
    export class GetSectionsProductResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            sectionsProductInfo?: dependency_1.core.product.v1.SectionsProductInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("sectionsProductInfo" in data && data.sectionsProductInfo != undefined) {
                    this.sectionsProductInfo = data.sectionsProductInfo;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sectionsProductInfo() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.SectionsProductInfo, 3) as dependency_1.core.product.v1.SectionsProductInfo;
        }
        set sectionsProductInfo(value: dependency_1.core.product.v1.SectionsProductInfo) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasSectionsProductInfo() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>;
        }): GetSectionsProductResponse {
            const message = new GetSectionsProductResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.sectionsProductInfo != null) {
                message.sectionsProductInfo = dependency_1.core.product.v1.SectionsProductInfo.fromObject(data.sectionsProductInfo);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.sectionsProductInfo != null) {
                data.sectionsProductInfo = this.sectionsProductInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasSectionsProductInfo)
                writer.writeMessage(3, this.sectionsProductInfo, () => this.sectionsProductInfo.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSectionsProductResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetSectionsProductResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.sectionsProductInfo, () => message.sectionsProductInfo = dependency_1.core.product.v1.SectionsProductInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetSectionsProductResponse {
            return GetSectionsProductResponse.deserialize(bytes);
        }
    }
    export class CreateSectionsProductInfoRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            displayName?: string;
            sections?: dependency_1.core.product.v1.Section[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("displayName" in data && data.displayName != undefined) {
                    this.displayName = data.displayName;
                }
                if ("sections" in data && data.sections != undefined) {
                    this.sections = data.sections;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get displayName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set displayName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sections() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.product.v1.Section, 3) as dependency_1.core.product.v1.Section[];
        }
        set sections(value: dependency_1.core.product.v1.Section[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            id?: string;
            displayName?: string;
            sections?: ReturnType<typeof dependency_1.core.product.v1.Section.prototype.toObject>[];
        }): CreateSectionsProductInfoRequest {
            const message = new CreateSectionsProductInfoRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.displayName != null) {
                message.displayName = data.displayName;
            }
            if (data.sections != null) {
                message.sections = data.sections.map(item => dependency_1.core.product.v1.Section.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                displayName?: string;
                sections?: ReturnType<typeof dependency_1.core.product.v1.Section.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.displayName != null) {
                data.displayName = this.displayName;
            }
            if (this.sections != null) {
                data.sections = this.sections.map((item: dependency_1.core.product.v1.Section) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.displayName.length)
                writer.writeString(2, this.displayName);
            if (this.sections.length)
                writer.writeRepeatedMessage(3, this.sections, (item: dependency_1.core.product.v1.Section) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateSectionsProductInfoRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateSectionsProductInfoRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.displayName = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.sections, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.product.v1.Section.deserialize(reader), dependency_1.core.product.v1.Section));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateSectionsProductInfoRequest {
            return CreateSectionsProductInfoRequest.deserialize(bytes);
        }
    }
    export class CreateSectionsProductInfoResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            sections?: dependency_1.core.product.v1.Section[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("sections" in data && data.sections != undefined) {
                    this.sections = data.sections;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sections() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.product.v1.Section, 3) as dependency_1.core.product.v1.Section[];
        }
        set sections(value: dependency_1.core.product.v1.Section[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            sections?: ReturnType<typeof dependency_1.core.product.v1.Section.prototype.toObject>[];
        }): CreateSectionsProductInfoResponse {
            const message = new CreateSectionsProductInfoResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.sections != null) {
                message.sections = data.sections.map(item => dependency_1.core.product.v1.Section.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                sections?: ReturnType<typeof dependency_1.core.product.v1.Section.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.sections != null) {
                data.sections = this.sections.map((item: dependency_1.core.product.v1.Section) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.sections.length)
                writer.writeRepeatedMessage(3, this.sections, (item: dependency_1.core.product.v1.Section) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateSectionsProductInfoResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateSectionsProductInfoResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.sections, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.product.v1.Section.deserialize(reader), dependency_1.core.product.v1.Section));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateSectionsProductInfoResponse {
            return CreateSectionsProductInfoResponse.deserialize(bytes);
        }
    }
    export class DeleteProductInfoRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productInfoId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
            }
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            productInfoId?: string;
        }): DeleteProductInfoRequest {
            const message = new DeleteProductInfoRequest({});
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            return message;
        }
        toObject() {
            const data: {
                productInfoId?: string;
            } = {};
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productInfoId.length)
                writer.writeString(1, this.productInfoId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteProductInfoRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteProductInfoRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productInfoId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteProductInfoRequest {
            return DeleteProductInfoRequest.deserialize(bytes);
        }
    }
    export class DeleteProductInfoResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productInfoId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productInfoId?: string;
        }): DeleteProductInfoResponse {
            const message = new DeleteProductInfoResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productInfoId?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productInfoId.length)
                writer.writeString(3, this.productInfoId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteProductInfoResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteProductInfoResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.productInfoId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteProductInfoResponse {
            return DeleteProductInfoResponse.deserialize(bytes);
        }
    }
    export class DeleteSectionsProductInfoRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productInfoId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
            }
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            productInfoId?: string;
        }): DeleteSectionsProductInfoRequest {
            const message = new DeleteSectionsProductInfoRequest({});
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            return message;
        }
        toObject() {
            const data: {
                productInfoId?: string;
            } = {};
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productInfoId.length)
                writer.writeString(1, this.productInfoId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteSectionsProductInfoRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteSectionsProductInfoRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productInfoId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteSectionsProductInfoRequest {
            return DeleteSectionsProductInfoRequest.deserialize(bytes);
        }
    }
    export class DeleteSectionsProductInfoResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            productInfoId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            productInfoId?: string;
        }): DeleteSectionsProductInfoResponse {
            const message = new DeleteSectionsProductInfoResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                productInfoId?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.productInfoId.length)
                writer.writeString(3, this.productInfoId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteSectionsProductInfoResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteSectionsProductInfoResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.productInfoId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteSectionsProductInfoResponse {
            return DeleteSectionsProductInfoResponse.deserialize(bytes);
        }
    }
    export class UpdateSectionsProductInfoRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sectionsProductInfo?: dependency_1.core.product.v1.SectionsProductInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sectionsProductInfo" in data && data.sectionsProductInfo != undefined) {
                    this.sectionsProductInfo = data.sectionsProductInfo;
                }
            }
        }
        get sectionsProductInfo() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.SectionsProductInfo, 1) as dependency_1.core.product.v1.SectionsProductInfo;
        }
        set sectionsProductInfo(value: dependency_1.core.product.v1.SectionsProductInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasSectionsProductInfo() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>;
        }): UpdateSectionsProductInfoRequest {
            const message = new UpdateSectionsProductInfoRequest({});
            if (data.sectionsProductInfo != null) {
                message.sectionsProductInfo = dependency_1.core.product.v1.SectionsProductInfo.fromObject(data.sectionsProductInfo);
            }
            return message;
        }
        toObject() {
            const data: {
                sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>;
            } = {};
            if (this.sectionsProductInfo != null) {
                data.sectionsProductInfo = this.sectionsProductInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasSectionsProductInfo)
                writer.writeMessage(1, this.sectionsProductInfo, () => this.sectionsProductInfo.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateSectionsProductInfoRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateSectionsProductInfoRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.sectionsProductInfo, () => message.sectionsProductInfo = dependency_1.core.product.v1.SectionsProductInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateSectionsProductInfoRequest {
            return UpdateSectionsProductInfoRequest.deserialize(bytes);
        }
    }
    export class UpdateSectionsProductInfoResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            sectionsProductInfo?: dependency_1.core.product.v1.SectionsProductInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("sectionsProductInfo" in data && data.sectionsProductInfo != undefined) {
                    this.sectionsProductInfo = data.sectionsProductInfo;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sectionsProductInfo() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.product.v1.SectionsProductInfo, 3) as dependency_1.core.product.v1.SectionsProductInfo;
        }
        set sectionsProductInfo(value: dependency_1.core.product.v1.SectionsProductInfo) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasSectionsProductInfo() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>;
        }): UpdateSectionsProductInfoResponse {
            const message = new UpdateSectionsProductInfoResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.sectionsProductInfo != null) {
                message.sectionsProductInfo = dependency_1.core.product.v1.SectionsProductInfo.fromObject(data.sectionsProductInfo);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                sectionsProductInfo?: ReturnType<typeof dependency_1.core.product.v1.SectionsProductInfo.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.sectionsProductInfo != null) {
                data.sectionsProductInfo = this.sectionsProductInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasSectionsProductInfo)
                writer.writeMessage(3, this.sectionsProductInfo, () => this.sectionsProductInfo.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateSectionsProductInfoResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateSectionsProductInfoResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.sectionsProductInfo, () => message.sectionsProductInfo = dependency_1.core.product.v1.SectionsProductInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateSectionsProductInfoResponse {
            return UpdateSectionsProductInfoResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedProductInfoAPIService {
        static definition = {
            ListProductInfos: {
                path: "/core.product.v1.ProductInfoAPI/ListProductInfos",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListProductInfosRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListProductInfosRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListProductInfosResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListProductInfosResponse.deserialize(new Uint8Array(bytes))
            },
            GetProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/GetProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetProductInfoRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetProductInfoRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetProductInfoResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetProductInfoResponse.deserialize(new Uint8Array(bytes))
            },
            CreateProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/CreateProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateProductInfoRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateProductInfoRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateProductInfoResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateProductInfoResponse.deserialize(new Uint8Array(bytes))
            },
            CreateSectionsProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/CreateSectionsProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateSectionsProductInfoRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateSectionsProductInfoRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateSectionsProductInfoResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateSectionsProductInfoResponse.deserialize(new Uint8Array(bytes))
            },
            GetSectionsProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/GetSectionsProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetSectionsProductRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetSectionsProductRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetSectionsProductResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetSectionsProductResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/DeleteProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteProductInfoRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteProductInfoRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteProductInfoResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteProductInfoResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteSectionsProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/DeleteSectionsProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteSectionsProductInfoRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteSectionsProductInfoRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteSectionsProductInfoResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteSectionsProductInfoResponse.deserialize(new Uint8Array(bytes))
            },
            AddContractAddress: {
                path: "/core.product.v1.ProductInfoAPI/AddContractAddress",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: AddContractAddressRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => AddContractAddressRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: AddContractAddressResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => AddContractAddressResponse.deserialize(new Uint8Array(bytes))
            },
            ListSectionsProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/ListSectionsProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListSectionsProductInfosRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListSectionsProductInfosRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListSectionsProductInfosResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListSectionsProductInfosResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateSectionsProductInfo: {
                path: "/core.product.v1.ProductInfoAPI/UpdateSectionsProductInfo",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateSectionsProductInfoRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateSectionsProductInfoRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateSectionsProductInfoResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateSectionsProductInfoResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract ListProductInfos(call: grpc_1.ServerUnaryCall<ListProductInfosRequest, ListProductInfosResponse>, callback: grpc_1.sendUnaryData<ListProductInfosResponse>): void;
        abstract GetProductInfo(call: grpc_1.ServerUnaryCall<GetProductInfoRequest, GetProductInfoResponse>, callback: grpc_1.sendUnaryData<GetProductInfoResponse>): void;
        abstract CreateProductInfo(call: grpc_1.ServerUnaryCall<CreateProductInfoRequest, CreateProductInfoResponse>, callback: grpc_1.sendUnaryData<CreateProductInfoResponse>): void;
        abstract CreateSectionsProductInfo(call: grpc_1.ServerUnaryCall<CreateSectionsProductInfoRequest, CreateSectionsProductInfoResponse>, callback: grpc_1.sendUnaryData<CreateSectionsProductInfoResponse>): void;
        abstract GetSectionsProductInfo(call: grpc_1.ServerUnaryCall<GetSectionsProductRequest, GetSectionsProductResponse>, callback: grpc_1.sendUnaryData<GetSectionsProductResponse>): void;
        abstract DeleteProductInfo(call: grpc_1.ServerUnaryCall<DeleteProductInfoRequest, DeleteProductInfoResponse>, callback: grpc_1.sendUnaryData<DeleteProductInfoResponse>): void;
        abstract DeleteSectionsProductInfo(call: grpc_1.ServerUnaryCall<DeleteSectionsProductInfoRequest, DeleteSectionsProductInfoResponse>, callback: grpc_1.sendUnaryData<DeleteSectionsProductInfoResponse>): void;
        abstract AddContractAddress(call: grpc_1.ServerUnaryCall<AddContractAddressRequest, AddContractAddressResponse>, callback: grpc_1.sendUnaryData<AddContractAddressResponse>): void;
        abstract ListSectionsProductInfo(call: grpc_1.ServerUnaryCall<ListSectionsProductInfosRequest, ListSectionsProductInfosResponse>, callback: grpc_1.sendUnaryData<ListSectionsProductInfosResponse>): void;
        abstract UpdateSectionsProductInfo(call: grpc_1.ServerUnaryCall<UpdateSectionsProductInfoRequest, UpdateSectionsProductInfoResponse>, callback: grpc_1.sendUnaryData<UpdateSectionsProductInfoResponse>): void;
    }
    export class ProductInfoAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static ListProductInfos = new grpc_web_1.MethodDescriptor<ListProductInfosRequest, ListProductInfosResponse>("/core.product.v1.ProductInfoAPI/ListProductInfos", grpc_web_1.MethodType.UNARY, ListProductInfosRequest, ListProductInfosResponse, (message: ListProductInfosRequest) => message.serialize(), ListProductInfosResponse.deserialize);
        ListProductInfos(message: ListProductInfosRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListProductInfosRequest, ListProductInfosResponse>(this._address + "/core.product.v1.ProductInfoAPI/ListProductInfos", message, metadata || {}, ProductInfoAPIClient.ListProductInfos);
        }
        private static GetProductInfo = new grpc_web_1.MethodDescriptor<GetProductInfoRequest, GetProductInfoResponse>("/core.product.v1.ProductInfoAPI/GetProductInfo", grpc_web_1.MethodType.UNARY, GetProductInfoRequest, GetProductInfoResponse, (message: GetProductInfoRequest) => message.serialize(), GetProductInfoResponse.deserialize);
        GetProductInfo(message: GetProductInfoRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetProductInfoRequest, GetProductInfoResponse>(this._address + "/core.product.v1.ProductInfoAPI/GetProductInfo", message, metadata || {}, ProductInfoAPIClient.GetProductInfo);
        }
        private static CreateProductInfo = new grpc_web_1.MethodDescriptor<CreateProductInfoRequest, CreateProductInfoResponse>("/core.product.v1.ProductInfoAPI/CreateProductInfo", grpc_web_1.MethodType.UNARY, CreateProductInfoRequest, CreateProductInfoResponse, (message: CreateProductInfoRequest) => message.serialize(), CreateProductInfoResponse.deserialize);
        CreateProductInfo(message: CreateProductInfoRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateProductInfoRequest, CreateProductInfoResponse>(this._address + "/core.product.v1.ProductInfoAPI/CreateProductInfo", message, metadata || {}, ProductInfoAPIClient.CreateProductInfo);
        }
        private static CreateSectionsProductInfo = new grpc_web_1.MethodDescriptor<CreateSectionsProductInfoRequest, CreateSectionsProductInfoResponse>("/core.product.v1.ProductInfoAPI/CreateSectionsProductInfo", grpc_web_1.MethodType.UNARY, CreateSectionsProductInfoRequest, CreateSectionsProductInfoResponse, (message: CreateSectionsProductInfoRequest) => message.serialize(), CreateSectionsProductInfoResponse.deserialize);
        CreateSectionsProductInfo(message: CreateSectionsProductInfoRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateSectionsProductInfoRequest, CreateSectionsProductInfoResponse>(this._address + "/core.product.v1.ProductInfoAPI/CreateSectionsProductInfo", message, metadata || {}, ProductInfoAPIClient.CreateSectionsProductInfo);
        }
        private static GetSectionsProductInfo = new grpc_web_1.MethodDescriptor<GetSectionsProductRequest, GetSectionsProductResponse>("/core.product.v1.ProductInfoAPI/GetSectionsProductInfo", grpc_web_1.MethodType.UNARY, GetSectionsProductRequest, GetSectionsProductResponse, (message: GetSectionsProductRequest) => message.serialize(), GetSectionsProductResponse.deserialize);
        GetSectionsProductInfo(message: GetSectionsProductRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetSectionsProductRequest, GetSectionsProductResponse>(this._address + "/core.product.v1.ProductInfoAPI/GetSectionsProductInfo", message, metadata || {}, ProductInfoAPIClient.GetSectionsProductInfo);
        }
        private static DeleteProductInfo = new grpc_web_1.MethodDescriptor<DeleteProductInfoRequest, DeleteProductInfoResponse>("/core.product.v1.ProductInfoAPI/DeleteProductInfo", grpc_web_1.MethodType.UNARY, DeleteProductInfoRequest, DeleteProductInfoResponse, (message: DeleteProductInfoRequest) => message.serialize(), DeleteProductInfoResponse.deserialize);
        DeleteProductInfo(message: DeleteProductInfoRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteProductInfoRequest, DeleteProductInfoResponse>(this._address + "/core.product.v1.ProductInfoAPI/DeleteProductInfo", message, metadata || {}, ProductInfoAPIClient.DeleteProductInfo);
        }
        private static DeleteSectionsProductInfo = new grpc_web_1.MethodDescriptor<DeleteSectionsProductInfoRequest, DeleteSectionsProductInfoResponse>("/core.product.v1.ProductInfoAPI/DeleteSectionsProductInfo", grpc_web_1.MethodType.UNARY, DeleteSectionsProductInfoRequest, DeleteSectionsProductInfoResponse, (message: DeleteSectionsProductInfoRequest) => message.serialize(), DeleteSectionsProductInfoResponse.deserialize);
        DeleteSectionsProductInfo(message: DeleteSectionsProductInfoRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteSectionsProductInfoRequest, DeleteSectionsProductInfoResponse>(this._address + "/core.product.v1.ProductInfoAPI/DeleteSectionsProductInfo", message, metadata || {}, ProductInfoAPIClient.DeleteSectionsProductInfo);
        }
        private static AddContractAddress = new grpc_web_1.MethodDescriptor<AddContractAddressRequest, AddContractAddressResponse>("/core.product.v1.ProductInfoAPI/AddContractAddress", grpc_web_1.MethodType.UNARY, AddContractAddressRequest, AddContractAddressResponse, (message: AddContractAddressRequest) => message.serialize(), AddContractAddressResponse.deserialize);
        AddContractAddress(message: AddContractAddressRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<AddContractAddressRequest, AddContractAddressResponse>(this._address + "/core.product.v1.ProductInfoAPI/AddContractAddress", message, metadata || {}, ProductInfoAPIClient.AddContractAddress);
        }
        private static ListSectionsProductInfo = new grpc_web_1.MethodDescriptor<ListSectionsProductInfosRequest, ListSectionsProductInfosResponse>("/core.product.v1.ProductInfoAPI/ListSectionsProductInfo", grpc_web_1.MethodType.UNARY, ListSectionsProductInfosRequest, ListSectionsProductInfosResponse, (message: ListSectionsProductInfosRequest) => message.serialize(), ListSectionsProductInfosResponse.deserialize);
        ListSectionsProductInfo(message: ListSectionsProductInfosRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListSectionsProductInfosRequest, ListSectionsProductInfosResponse>(this._address + "/core.product.v1.ProductInfoAPI/ListSectionsProductInfo", message, metadata || {}, ProductInfoAPIClient.ListSectionsProductInfo);
        }
        private static UpdateSectionsProductInfo = new grpc_web_1.MethodDescriptor<UpdateSectionsProductInfoRequest, UpdateSectionsProductInfoResponse>("/core.product.v1.ProductInfoAPI/UpdateSectionsProductInfo", grpc_web_1.MethodType.UNARY, UpdateSectionsProductInfoRequest, UpdateSectionsProductInfoResponse, (message: UpdateSectionsProductInfoRequest) => message.serialize(), UpdateSectionsProductInfoResponse.deserialize);
        UpdateSectionsProductInfo(message: UpdateSectionsProductInfoRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateSectionsProductInfoRequest, UpdateSectionsProductInfoResponse>(this._address + "/core.product.v1.ProductInfoAPI/UpdateSectionsProductInfo", message, metadata || {}, ProductInfoAPIClient.UpdateSectionsProductInfo);
        }
    }
}
