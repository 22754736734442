import * as Api from "@libs/proto/core/notification/v1/notification_api";
import * as Notification from "@libs/proto/core/notification/v1/notification";
import { AuthorizationStreamInterceptor, ERRORS_CODE_UNAUTHENTICATED, streamSubject } from "@libs/interceptors/interceptor-stream";
import { ClientReadableStream, RpcError } from "grpc-web";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";
import { Product } from "@libs/models/product";
import { ReplaySubject, Subject } from "rxjs";
import { SharedService } from "../shared/shared.service";
import { v4 as uuidv4 } from "uuid";
import { Auction } from "@libs/models/auction";

export interface EventAuctionStream {
  eventId: string;
  auction: Auction;
}

@Injectable({
  providedIn: "root",
})
export class GrpcNotificationService {
  private readonly client: Api.core.notification.v1.NotificationAPIClient;
  private readonly appName: "ecotas" | "token";

  productChangedEvent$: ReplaySubject<Product> = new ReplaySubject<Product>(1);
  bidChangedEvent$: ReplaySubject<any> = new ReplaySubject<any>(1);
  auctionChangedEvent$: ReplaySubject<EventAuctionStream> = new ReplaySubject<EventAuctionStream>(1);
  errorEvent$: ReplaySubject<any> = new ReplaySubject<any>(1);
  notificationSubs: ClientReadableStream<Api.core.notification.v1.SubscribeNotificationResponse>;
  isStreamActive = false;
  errorMessage: string | null;

  constructor(public mainService: MainService, public shared: SharedService) {
    this.client = new Api.core.notification.v1.NotificationAPIClient(mainService.endpointBase, null, {
      streamInterceptors: [new AuthorizationStreamInterceptor()],
    });
    this.appName = this.mainService.application;
    streamSubject.subscribe((newStream) => {
      console.log("Nova stream!!!!!!!!!!");
      this.stopStreamNotifications();
      this.notificationSubs = newStream;
      this.isStreamActive = true;
      this.notificationSubs.on("data", this.handleData);
      this.notificationSubs.on("error", this.handleError);
    });
  }

  initStreamNotifications() {
    let notification: Notification.core.notification.v1.SubscribeNotification[];
    if (this.appName === "token") {
      notification = [
        new Notification.core.notification.v1.SubscribeNotification({
          productChangedEvent: new Notification.core.notification.v1.ProductChangedEvent(),
        }),
      ];
    } else if (this.appName === "ecotas") {
      notification = [
        new Notification.core.notification.v1.SubscribeNotification({
          auctionChangedEvent: new Notification.core.notification.v1.AuctionChangedEvent(),
        }),
      ];
    } else {
      throw new Error("Invalid application name");
    }

    const request = new Api.core.notification.v1.SubscribeNotificationRequest({
      id: this.shared.user ? this.shared.user.id : uuidv4(),
      notification,
    });
    this.notificationSubs = this.client.Subscribe(request, {});
    this.isStreamActive = true;
    this.notificationSubs.on("data", this.handleData);
    this.notificationSubs.on("error", this.handleError);
  }

  private readonly handleData = (response) => {
    this.errorMessage = null;
    const res = response.toObject();
    console.log("handleData stream: ", res);
    const productData = res.notification.productChangedEvent;
    const auctionData = res.notification.auctionChangedEvent;
    this.errorEvent$.next(null);
    if (productData?.product) {
      this.productChangedEvent$.next(productData.product);
    }
    if (auctionData) {
      this.auctionChangedEvent$.next(auctionData);
    }
  };

  private readonly handleError = (error: any) => {
    console.log("handleError stream: ", error);

    if (!this.isStreamActive) {
      console.warn("Stream is inactive. Ignoring error.");
      return;
    }

    if (error instanceof RpcError) {
      if (!ERRORS_CODE_UNAUTHENTICATED.includes(error.code)) {
        this.errorEvent$.next(`Code: ${error.code || 0}. Message: ${error.message || "Erro desconhecido"}.`);
      }
    } else {
      // Handle outros tipos de erros
      this.errorEvent$.next(`Unexpected error: ${error}`);
    }
  };

  stopStreamNotifications() {
    if (this.notificationSubs && this.isStreamActive) {
      this.notificationSubs.cancel();
      this.isStreamActive = false;
    }
  }
}
