import * as AuctionApi from "@libs/proto/core/auction/v1/auction_api";
import * as CompositeFilter from "@libs/proto/core/common/v1/composite_filter";
import * as Bid from "@libs/proto/core/auction/v1/auction";
import { AuthorizationInterceptor } from "@libs/interceptors/interceptor";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";
import { ProductParams } from "@libs/models/product";
import { ParameterType } from "@libs/models/parameterType";

@Injectable({
  providedIn: "root",
})
export class GrpcAuctionService {
  private client: AuctionApi.core.auction.v1.AuctionAPIClient;

  constructor(public mainService: MainService) {
    this.client = new AuctionApi.core.auction.v1.AuctionAPIClient(mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
  }

  getProductFilters(productParams: ProductParams): CompositeFilter.core.common.v1.CompositeFilter[] {
    const listCompositeFilter: CompositeFilter.core.common.v1.CompositeFilter[] = [];

    if (productParams.userSub) {
      const userSubFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "bid.userSub",
        condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
          value: productParams.userSub,
        }),
      });
      listCompositeFilter.push(userSubFilter);
    }

    if (typeof productParams.isFinished === "boolean") {
      const isFinishedFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "finished",
        condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
          value: productParams.isFinished ? "true" : "false",
        }),
      });
      listCompositeFilter.push(isFinishedFilter);
    }

    if (productParams.category) {
      const idFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "metaData.categoria",
        condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
          value: productParams.category,
        }),
      });
      listCompositeFilter.push(idFilter);
    }

    if (productParams.auctionId) {
      const idFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "symbol",
        condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
          value: productParams.auctionId,
        }),
      });
      listCompositeFilter.push(idFilter);
    }

    if (productParams.dueDateStart) {
      const dueDateStartFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "metaData.data_de_encerramento_do_leilao",
        condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_GREATER_THAN_OR_EQUAL_TO,
          value: this.googleTimestampToISOString(productParams.dueDateStart),
        }),
      });
      listCompositeFilter.push(dueDateStartFilter);
    }
    if (productParams.dueDateEnd) {
      const dueDateEndFilter = new CompositeFilter.core.common.v1.CompositeFilter({
        key: "metaData.data_de_encerramento_do_leilao",
        condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
        value: new CompositeFilter.core.common.v1.Filter({
          operator: CompositeFilter.core.common.v1.Operator.OPERATOR_LESS_THAN_OR_EQUAL_TO,
          value: this.googleTimestampToISOString(productParams.dueDateEnd),
        }),
      });
      listCompositeFilter.push(dueDateEndFilter);
    }

    return listCompositeFilter;
  }

  listBids(externalId?: string, page?: number, itemPerPage?: number) {
    const request = new AuctionApi.core.auction.v1.ListBidsRequest({
      externalId,
      page,
      itemPerPage,
    });
    return this.client.ListBids(request, {});
  }

  listAuctions(page?: number, itemPerPage?: number, productParams?: ProductParams) {
    const request = new AuctionApi.core.auction.v1.ListAuctionRequest({
      page,
      itemPerPage,
      filters: productParams ? this.getProductFilters(productParams) : null,
    });
    return this.client.ListAuction(request, {});
  }

  listAuctionSummary() {
    const summaryFilter = new CompositeFilter.core.common.v1.CompositeFilter({
      key: "bid.userSub",
      condition: CompositeFilter.core.common.v1.Condition.CONDITION_AND,
      value: new CompositeFilter.core.common.v1.Filter({
        operator: CompositeFilter.core.common.v1.Operator.OPERATOR_EQUALS,
        value: "$user",
      }),
    });

    const request = new AuctionApi.core.auction.v1.ListAuctionSummaryRequest({
      filters: [summaryFilter],
    });

    return this.client.ListAuctionSummary(request, {});
  }

  makeBid(bidValue: number, externalId: string, managementDocument: string) {
    const objectBid = {
      bidValue,
      externalId,
      managementDocument,
    };

    const request = new AuctionApi.core.auction.v1.CreateBidRequest(objectBid);
    return this.client.CreateBid(request, {});
  }

  getAuctionById(id: string) {
    const request = new AuctionApi.core.auction.v1.GetAuctionRequest({
      id,
    });
    return this.client.GetAuction(request, {});
  }

  googleTimestampToISOString(timestampObj) {
    const seconds = timestampObj.array[0]; // Primeiro valor é o número de segundos
    const nanos = timestampObj.array[1]; // Segundo valor é o número de nanossegundos

    // Converter segundos e nanossegundos para milissegundos
    const date = new Date(seconds * 1000 + nanos / 1000000);

    // Retornar a data no formato ISO String
    return date.toISOString();
  }
}
