/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/notification/v1/notification.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as dependency_2 from "./../../product/v1/product";
import * as dependency_3 from "./../../auction/v1/auction";
import * as pb_1 from "google-protobuf";
export namespace core.notification.v1 {
    export class Notification extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3, 4, 5]];
        constructor(data?: any[] | ({} & (({
            ackEvent?: AckEvent;
            pingPongEvent?: never;
            productChangedEvent?: never;
            bidReceivedEvent?: never;
            auctionChangedEvent?: never;
        } | {
            ackEvent?: never;
            pingPongEvent?: PingPongEvent;
            productChangedEvent?: never;
            bidReceivedEvent?: never;
            auctionChangedEvent?: never;
        } | {
            ackEvent?: never;
            pingPongEvent?: never;
            productChangedEvent?: ProductChangedEvent;
            bidReceivedEvent?: never;
            auctionChangedEvent?: never;
        } | {
            ackEvent?: never;
            pingPongEvent?: never;
            productChangedEvent?: never;
            bidReceivedEvent?: BidReceivedEvent;
            auctionChangedEvent?: never;
        } | {
            ackEvent?: never;
            pingPongEvent?: never;
            productChangedEvent?: never;
            bidReceivedEvent?: never;
            auctionChangedEvent?: AuctionChangedEvent;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ackEvent" in data && data.ackEvent != undefined) {
                    this.ackEvent = data.ackEvent;
                }
                if ("pingPongEvent" in data && data.pingPongEvent != undefined) {
                    this.pingPongEvent = data.pingPongEvent;
                }
                if ("productChangedEvent" in data && data.productChangedEvent != undefined) {
                    this.productChangedEvent = data.productChangedEvent;
                }
                if ("bidReceivedEvent" in data && data.bidReceivedEvent != undefined) {
                    this.bidReceivedEvent = data.bidReceivedEvent;
                }
                if ("auctionChangedEvent" in data && data.auctionChangedEvent != undefined) {
                    this.auctionChangedEvent = data.auctionChangedEvent;
                }
            }
        }
        get ackEvent() {
            return pb_1.Message.getWrapperField(this, AckEvent, 1) as AckEvent;
        }
        set ackEvent(value: AckEvent) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get hasAckEvent() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get pingPongEvent() {
            return pb_1.Message.getWrapperField(this, PingPongEvent, 2) as PingPongEvent;
        }
        set pingPongEvent(value: PingPongEvent) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get hasPingPongEvent() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get productChangedEvent() {
            return pb_1.Message.getWrapperField(this, ProductChangedEvent, 3) as ProductChangedEvent;
        }
        set productChangedEvent(value: ProductChangedEvent) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get hasProductChangedEvent() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get bidReceivedEvent() {
            return pb_1.Message.getWrapperField(this, BidReceivedEvent, 4) as BidReceivedEvent;
        }
        set bidReceivedEvent(value: BidReceivedEvent) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
        }
        get hasBidReceivedEvent() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get auctionChangedEvent() {
            return pb_1.Message.getWrapperField(this, AuctionChangedEvent, 5) as AuctionChangedEvent;
        }
        set auctionChangedEvent(value: AuctionChangedEvent) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
        }
        get hasAuctionChangedEvent() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get event() {
            const cases: {
                [index: number]: "none" | "ackEvent" | "pingPongEvent" | "productChangedEvent" | "bidReceivedEvent" | "auctionChangedEvent";
            } = {
                0: "none",
                1: "ackEvent",
                2: "pingPongEvent",
                3: "productChangedEvent",
                4: "bidReceivedEvent",
                5: "auctionChangedEvent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5])];
        }
        static fromObject(data: {
            ackEvent?: ReturnType<typeof AckEvent.prototype.toObject>;
            pingPongEvent?: ReturnType<typeof PingPongEvent.prototype.toObject>;
            productChangedEvent?: ReturnType<typeof ProductChangedEvent.prototype.toObject>;
            bidReceivedEvent?: ReturnType<typeof BidReceivedEvent.prototype.toObject>;
            auctionChangedEvent?: ReturnType<typeof AuctionChangedEvent.prototype.toObject>;
        }): Notification {
            const message = new Notification({});
            if (data.ackEvent != null) {
                message.ackEvent = AckEvent.fromObject(data.ackEvent);
            }
            if (data.pingPongEvent != null) {
                message.pingPongEvent = PingPongEvent.fromObject(data.pingPongEvent);
            }
            if (data.productChangedEvent != null) {
                message.productChangedEvent = ProductChangedEvent.fromObject(data.productChangedEvent);
            }
            if (data.bidReceivedEvent != null) {
                message.bidReceivedEvent = BidReceivedEvent.fromObject(data.bidReceivedEvent);
            }
            if (data.auctionChangedEvent != null) {
                message.auctionChangedEvent = AuctionChangedEvent.fromObject(data.auctionChangedEvent);
            }
            return message;
        }
        toObject() {
            const data: {
                ackEvent?: ReturnType<typeof AckEvent.prototype.toObject>;
                pingPongEvent?: ReturnType<typeof PingPongEvent.prototype.toObject>;
                productChangedEvent?: ReturnType<typeof ProductChangedEvent.prototype.toObject>;
                bidReceivedEvent?: ReturnType<typeof BidReceivedEvent.prototype.toObject>;
                auctionChangedEvent?: ReturnType<typeof AuctionChangedEvent.prototype.toObject>;
            } = {};
            if (this.ackEvent != null) {
                data.ackEvent = this.ackEvent.toObject();
            }
            if (this.pingPongEvent != null) {
                data.pingPongEvent = this.pingPongEvent.toObject();
            }
            if (this.productChangedEvent != null) {
                data.productChangedEvent = this.productChangedEvent.toObject();
            }
            if (this.bidReceivedEvent != null) {
                data.bidReceivedEvent = this.bidReceivedEvent.toObject();
            }
            if (this.auctionChangedEvent != null) {
                data.auctionChangedEvent = this.auctionChangedEvent.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasAckEvent)
                writer.writeMessage(1, this.ackEvent, () => this.ackEvent.serialize(writer));
            if (this.hasPingPongEvent)
                writer.writeMessage(2, this.pingPongEvent, () => this.pingPongEvent.serialize(writer));
            if (this.hasProductChangedEvent)
                writer.writeMessage(3, this.productChangedEvent, () => this.productChangedEvent.serialize(writer));
            if (this.hasBidReceivedEvent)
                writer.writeMessage(4, this.bidReceivedEvent, () => this.bidReceivedEvent.serialize(writer));
            if (this.hasAuctionChangedEvent)
                writer.writeMessage(5, this.auctionChangedEvent, () => this.auctionChangedEvent.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Notification {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Notification();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.ackEvent, () => message.ackEvent = AckEvent.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.pingPongEvent, () => message.pingPongEvent = PingPongEvent.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.productChangedEvent, () => message.productChangedEvent = ProductChangedEvent.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.bidReceivedEvent, () => message.bidReceivedEvent = BidReceivedEvent.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.auctionChangedEvent, () => message.auctionChangedEvent = AuctionChangedEvent.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Notification {
            return Notification.deserialize(bytes);
        }
    }
    export class SubscribeNotification extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3]];
        constructor(data?: any[] | ({} & (({
            productChangedEvent?: ProductChangedEvent;
            bidReceivedEvent?: never;
            auctionChangedEvent?: never;
        } | {
            productChangedEvent?: never;
            bidReceivedEvent?: BidReceivedEvent;
            auctionChangedEvent?: never;
        } | {
            productChangedEvent?: never;
            bidReceivedEvent?: never;
            auctionChangedEvent?: AuctionChangedEvent;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productChangedEvent" in data && data.productChangedEvent != undefined) {
                    this.productChangedEvent = data.productChangedEvent;
                }
                if ("bidReceivedEvent" in data && data.bidReceivedEvent != undefined) {
                    this.bidReceivedEvent = data.bidReceivedEvent;
                }
                if ("auctionChangedEvent" in data && data.auctionChangedEvent != undefined) {
                    this.auctionChangedEvent = data.auctionChangedEvent;
                }
            }
        }
        get productChangedEvent() {
            return pb_1.Message.getWrapperField(this, ProductChangedEvent, 1) as ProductChangedEvent;
        }
        set productChangedEvent(value: ProductChangedEvent) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get hasProductChangedEvent() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get bidReceivedEvent() {
            return pb_1.Message.getWrapperField(this, BidReceivedEvent, 2) as BidReceivedEvent;
        }
        set bidReceivedEvent(value: BidReceivedEvent) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get hasBidReceivedEvent() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get auctionChangedEvent() {
            return pb_1.Message.getWrapperField(this, AuctionChangedEvent, 3) as AuctionChangedEvent;
        }
        set auctionChangedEvent(value: AuctionChangedEvent) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get hasAuctionChangedEvent() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get kind_event() {
            const cases: {
                [index: number]: "none" | "productChangedEvent" | "bidReceivedEvent" | "auctionChangedEvent";
            } = {
                0: "none",
                1: "productChangedEvent",
                2: "bidReceivedEvent",
                3: "auctionChangedEvent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])];
        }
        static fromObject(data: {
            productChangedEvent?: ReturnType<typeof ProductChangedEvent.prototype.toObject>;
            bidReceivedEvent?: ReturnType<typeof BidReceivedEvent.prototype.toObject>;
            auctionChangedEvent?: ReturnType<typeof AuctionChangedEvent.prototype.toObject>;
        }): SubscribeNotification {
            const message = new SubscribeNotification({});
            if (data.productChangedEvent != null) {
                message.productChangedEvent = ProductChangedEvent.fromObject(data.productChangedEvent);
            }
            if (data.bidReceivedEvent != null) {
                message.bidReceivedEvent = BidReceivedEvent.fromObject(data.bidReceivedEvent);
            }
            if (data.auctionChangedEvent != null) {
                message.auctionChangedEvent = AuctionChangedEvent.fromObject(data.auctionChangedEvent);
            }
            return message;
        }
        toObject() {
            const data: {
                productChangedEvent?: ReturnType<typeof ProductChangedEvent.prototype.toObject>;
                bidReceivedEvent?: ReturnType<typeof BidReceivedEvent.prototype.toObject>;
                auctionChangedEvent?: ReturnType<typeof AuctionChangedEvent.prototype.toObject>;
            } = {};
            if (this.productChangedEvent != null) {
                data.productChangedEvent = this.productChangedEvent.toObject();
            }
            if (this.bidReceivedEvent != null) {
                data.bidReceivedEvent = this.bidReceivedEvent.toObject();
            }
            if (this.auctionChangedEvent != null) {
                data.auctionChangedEvent = this.auctionChangedEvent.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasProductChangedEvent)
                writer.writeMessage(1, this.productChangedEvent, () => this.productChangedEvent.serialize(writer));
            if (this.hasBidReceivedEvent)
                writer.writeMessage(2, this.bidReceivedEvent, () => this.bidReceivedEvent.serialize(writer));
            if (this.hasAuctionChangedEvent)
                writer.writeMessage(3, this.auctionChangedEvent, () => this.auctionChangedEvent.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscribeNotification {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscribeNotification();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.productChangedEvent, () => message.productChangedEvent = ProductChangedEvent.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.bidReceivedEvent, () => message.bidReceivedEvent = BidReceivedEvent.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.auctionChangedEvent, () => message.auctionChangedEvent = AuctionChangedEvent.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SubscribeNotification {
            return SubscribeNotification.deserialize(bytes);
        }
    }
    export class AckEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            time?: dependency_1.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("time" in data && data.time != undefined) {
                    this.time = data.time;
                }
            }
        }
        get time() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 1) as dependency_1.google.protobuf.Timestamp;
        }
        set time(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasTime() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            time?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
        }): AckEvent {
            const message = new AckEvent({});
            if (data.time != null) {
                message.time = dependency_1.google.protobuf.Timestamp.fromObject(data.time);
            }
            return message;
        }
        toObject() {
            const data: {
                time?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.time != null) {
                data.time = this.time.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasTime)
                writer.writeMessage(1, this.time, () => this.time.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AckEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AckEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.time, () => message.time = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AckEvent {
            return AckEvent.deserialize(bytes);
        }
    }
    export class PingPongEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            time?: dependency_1.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("time" in data && data.time != undefined) {
                    this.time = data.time;
                }
            }
        }
        get time() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 1) as dependency_1.google.protobuf.Timestamp;
        }
        set time(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasTime() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            time?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
        }): PingPongEvent {
            const message = new PingPongEvent({});
            if (data.time != null) {
                message.time = dependency_1.google.protobuf.Timestamp.fromObject(data.time);
            }
            return message;
        }
        toObject() {
            const data: {
                time?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.time != null) {
                data.time = this.time.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasTime)
                writer.writeMessage(1, this.time, () => this.time.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PingPongEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PingPongEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.time, () => message.time = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PingPongEvent {
            return PingPongEvent.deserialize(bytes);
        }
    }
    export class ProductChangedEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            eventId?: string;
            product?: dependency_2.core.product.v1.Product;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("eventId" in data && data.eventId != undefined) {
                    this.eventId = data.eventId;
                }
                if ("product" in data && data.product != undefined) {
                    this.product = data.product;
                }
            }
        }
        get eventId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set eventId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get product() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.product.v1.Product, 2) as dependency_2.core.product.v1.Product;
        }
        set product(value: dependency_2.core.product.v1.Product) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasProduct() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            eventId?: string;
            product?: ReturnType<typeof dependency_2.core.product.v1.Product.prototype.toObject>;
        }): ProductChangedEvent {
            const message = new ProductChangedEvent({});
            if (data.eventId != null) {
                message.eventId = data.eventId;
            }
            if (data.product != null) {
                message.product = dependency_2.core.product.v1.Product.fromObject(data.product);
            }
            return message;
        }
        toObject() {
            const data: {
                eventId?: string;
                product?: ReturnType<typeof dependency_2.core.product.v1.Product.prototype.toObject>;
            } = {};
            if (this.eventId != null) {
                data.eventId = this.eventId;
            }
            if (this.product != null) {
                data.product = this.product.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.eventId.length)
                writer.writeString(1, this.eventId);
            if (this.hasProduct)
                writer.writeMessage(2, this.product, () => this.product.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProductChangedEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProductChangedEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.eventId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.product, () => message.product = dependency_2.core.product.v1.Product.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ProductChangedEvent {
            return ProductChangedEvent.deserialize(bytes);
        }
    }
    export class BidReceivedEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            eventId?: string;
            bid?: dependency_3.core.auction.v1.Bid;
            isFinished?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("eventId" in data && data.eventId != undefined) {
                    this.eventId = data.eventId;
                }
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
                if ("isFinished" in data && data.isFinished != undefined) {
                    this.isFinished = data.isFinished;
                }
            }
        }
        get eventId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set eventId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get bid() {
            return pb_1.Message.getWrapperField(this, dependency_3.core.auction.v1.Bid, 2) as dependency_3.core.auction.v1.Bid;
        }
        set bid(value: dependency_3.core.auction.v1.Bid) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasBid() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get isFinished() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set isFinished(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            eventId?: string;
            bid?: ReturnType<typeof dependency_3.core.auction.v1.Bid.prototype.toObject>;
            isFinished?: boolean;
        }): BidReceivedEvent {
            const message = new BidReceivedEvent({});
            if (data.eventId != null) {
                message.eventId = data.eventId;
            }
            if (data.bid != null) {
                message.bid = dependency_3.core.auction.v1.Bid.fromObject(data.bid);
            }
            if (data.isFinished != null) {
                message.isFinished = data.isFinished;
            }
            return message;
        }
        toObject() {
            const data: {
                eventId?: string;
                bid?: ReturnType<typeof dependency_3.core.auction.v1.Bid.prototype.toObject>;
                isFinished?: boolean;
            } = {};
            if (this.eventId != null) {
                data.eventId = this.eventId;
            }
            if (this.bid != null) {
                data.bid = this.bid.toObject();
            }
            if (this.isFinished != null) {
                data.isFinished = this.isFinished;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.eventId.length)
                writer.writeString(1, this.eventId);
            if (this.hasBid)
                writer.writeMessage(2, this.bid, () => this.bid.serialize(writer));
            if (this.isFinished != false)
                writer.writeBool(3, this.isFinished);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BidReceivedEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BidReceivedEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.eventId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.bid, () => message.bid = dependency_3.core.auction.v1.Bid.deserialize(reader));
                        break;
                    case 3:
                        message.isFinished = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BidReceivedEvent {
            return BidReceivedEvent.deserialize(bytes);
        }
    }
    export class AuctionChangedEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            eventId?: string;
            auction?: dependency_3.core.auction.v1.Auction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("eventId" in data && data.eventId != undefined) {
                    this.eventId = data.eventId;
                }
                if ("auction" in data && data.auction != undefined) {
                    this.auction = data.auction;
                }
            }
        }
        get eventId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set eventId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get auction() {
            return pb_1.Message.getWrapperField(this, dependency_3.core.auction.v1.Auction, 2) as dependency_3.core.auction.v1.Auction;
        }
        set auction(value: dependency_3.core.auction.v1.Auction) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasAuction() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            eventId?: string;
            auction?: ReturnType<typeof dependency_3.core.auction.v1.Auction.prototype.toObject>;
        }): AuctionChangedEvent {
            const message = new AuctionChangedEvent({});
            if (data.eventId != null) {
                message.eventId = data.eventId;
            }
            if (data.auction != null) {
                message.auction = dependency_3.core.auction.v1.Auction.fromObject(data.auction);
            }
            return message;
        }
        toObject() {
            const data: {
                eventId?: string;
                auction?: ReturnType<typeof dependency_3.core.auction.v1.Auction.prototype.toObject>;
            } = {};
            if (this.eventId != null) {
                data.eventId = this.eventId;
            }
            if (this.auction != null) {
                data.auction = this.auction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.eventId.length)
                writer.writeString(1, this.eventId);
            if (this.hasAuction)
                writer.writeMessage(2, this.auction, () => this.auction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuctionChangedEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuctionChangedEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.eventId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.auction, () => message.auction = dependency_3.core.auction.v1.Auction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AuctionChangedEvent {
            return AuctionChangedEvent.deserialize(bytes);
        }
    }
}
