import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "toast",
  templateUrl: "./toast.component.html",
  styleUrl: "./toast.component.scss",
})
export class ToastComponent {
  constructor(public snackBarRef: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit() {}
}
