/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.20.3
 * source: core/common/v1/composite_filter.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace core.common.v1 {
    export enum Condition {
        CONDITION_UNSPECIFIED = 0,
        CONDITION_OR = 1,
        CONDITION_AND = 2
    }
    export enum Operator {
        OPERATOR_UNSPECIFIED = 0,
        OPERATOR_EQUALS = 1,
        OPERATOR_GREATER_THAN = 2,
        OPERATOR_GREATER_THAN_OR_EQUAL_TO = 3,
        OPERATOR_LESS_THAN = 4,
        OPERATOR_LESS_THAN_OR_EQUAL_TO = 5,
        OPERATOR_IN = 6,
        OPERATOR_OR = 7
    }
    export class CompositeFilter extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            key?: string;
            value?: Filter;
            filtersList?: CompositeFilter[];
            condition?: Condition;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("key" in data && data.key != undefined) {
                    this.key = data.key;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("filtersList" in data && data.filtersList != undefined) {
                    this.filtersList = data.filtersList;
                }
                if ("condition" in data && data.condition != undefined) {
                    this.condition = data.condition;
                }
            }
        }
        get key() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set key(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get value() {
            return pb_1.Message.getWrapperField(this, Filter, 2) as Filter;
        }
        set value(value: Filter) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasValue() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get filtersList() {
            return pb_1.Message.getRepeatedWrapperField(this, CompositeFilter, 3) as CompositeFilter[];
        }
        set filtersList(value: CompositeFilter[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        get condition() {
            return pb_1.Message.getFieldWithDefault(this, 4, Condition.CONDITION_UNSPECIFIED) as Condition;
        }
        set condition(value: Condition) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            key?: string;
            value?: ReturnType<typeof Filter.prototype.toObject>;
            filtersList?: ReturnType<typeof CompositeFilter.prototype.toObject>[];
            condition?: Condition;
        }): CompositeFilter {
            const message = new CompositeFilter({});
            if (data.key != null) {
                message.key = data.key;
            }
            if (data.value != null) {
                message.value = Filter.fromObject(data.value);
            }
            if (data.filtersList != null) {
                message.filtersList = data.filtersList.map(item => CompositeFilter.fromObject(item));
            }
            if (data.condition != null) {
                message.condition = data.condition;
            }
            return message;
        }
        toObject() {
            const data: {
                key?: string;
                value?: ReturnType<typeof Filter.prototype.toObject>;
                filtersList?: ReturnType<typeof CompositeFilter.prototype.toObject>[];
                condition?: Condition;
            } = {};
            if (this.key != null) {
                data.key = this.key;
            }
            if (this.value != null) {
                data.value = this.value.toObject();
            }
            if (this.filtersList != null) {
                data.filtersList = this.filtersList.map((item: CompositeFilter) => item.toObject());
            }
            if (this.condition != null) {
                data.condition = this.condition;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.key.length)
                writer.writeString(1, this.key);
            if (this.hasValue)
                writer.writeMessage(2, this.value, () => this.value.serialize(writer));
            if (this.filtersList.length)
                writer.writeRepeatedMessage(3, this.filtersList, (item: CompositeFilter) => item.serialize(writer));
            if (this.condition != Condition.CONDITION_UNSPECIFIED)
                writer.writeEnum(4, this.condition);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CompositeFilter {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CompositeFilter();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.key = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.value, () => message.value = Filter.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.filtersList, () => pb_1.Message.addToRepeatedWrapperField(message, 3, CompositeFilter.deserialize(reader), CompositeFilter));
                        break;
                    case 4:
                        message.condition = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CompositeFilter {
            return CompositeFilter.deserialize(bytes);
        }
    }
    export class Filter extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            operator?: Operator;
            value?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("operator" in data && data.operator != undefined) {
                    this.operator = data.operator;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
        }
        get operator() {
            return pb_1.Message.getFieldWithDefault(this, 1, Operator.OPERATOR_UNSPECIFIED) as Operator;
        }
        set operator(value: Operator) {
            pb_1.Message.setField(this, 1, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            operator?: Operator;
            value?: string;
        }): Filter {
            const message = new Filter({});
            if (data.operator != null) {
                message.operator = data.operator;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: {
                operator?: Operator;
                value?: string;
            } = {};
            if (this.operator != null) {
                data.operator = this.operator;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.operator != Operator.OPERATOR_UNSPECIFIED)
                writer.writeEnum(1, this.operator);
            if (this.value.length)
                writer.writeString(2, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Filter {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Filter();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.operator = reader.readEnum();
                        break;
                    case 2:
                        message.value = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Filter {
            return Filter.deserialize(bytes);
        }
    }
}
