import * as Account from "@libs/proto/core/account/v1/account";
import * as core from "@libs/proto/core/custom_attributes";
import * as AccountApi from "@libs/proto/core/account/v1/account_api";
import { AuthorizationInterceptor } from "@libs/interceptors/interceptor";
import { Company } from "@libs/models/company";
import { Injectable } from "@angular/core";
import { removeSymbol } from "@libs/util/util";
import { User } from "@libs/models/user";
import { MainService } from "@libs/main.service";

@Injectable({
  providedIn: "root",
})
export class GrpcAccountService {
  private client: AccountApi.core.account.v1.AccountServiceClient;

  constructor(public mainService: MainService) {
    this.client = new AccountApi.core.account.v1.AccountServiceClient(this.mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
  }

  /**
   * CRUD Company
   */

  async createCompany(companyFront: any) {

    let customNetWorkId = new core.core.CustomAttributes({name: 'BLOCKCHAIN_NETWORK_ID', value: companyFront.walletCustodyServiceUrl});
    let customUrlCustody = new core.core.CustomAttributes({name: 'BLOCKCHAIN_WALLET_CUSTODY_SERVICE_URL', value: companyFront.networkId});

    const company: Account.core.account.v1.Company = new Account.core.account.v1.Company({
      name: companyFront.name,
      document: removeSymbol(companyFront.document),
      enabled: true,
      configSettings: [customNetWorkId, customUrlCustody]
    });
    const request = new AccountApi.core.account.v1.CreateCompanyRequest({
      company,
    });
    return this.client.CreateCompany(request, {});
  }

  async getCompanyList(itemsPerPage?, page?) {
    const request = new AccountApi.core.account.v1.ListCompaniesRequest({
      itemsPerPage: itemsPerPage ? itemsPerPage : 10,
      page: page ? page : 1,
    });
    return this.client.ListCompanies(request, {});
  }

  async getCompany(id: string) {
    const request = new AccountApi.core.account.v1.GetCompanyRequest({
      id,
    });
    return this.client.GetCompany(request, {});
  }

  

  async resendPasswordEmail(username: string) {
    const request = new AccountApi.core.account.v1.GenerateTemporaryPasswordRequest({
      username,
    });
    return this.client.GenerateTemporaryPassword(request, {});
  }

  async saveWebHook(form: any) {
    console.log('formulario', form);
    const request = new AccountApi.core.account.v1.SetCompanyWebhookRequest({
      companyId: form.companyId,
      webhookFormat: form.webhookFormat,
      webhookSecret: form.webhookSecret,
      webhookUrl: form.webhookUrl
    });
    return this.client.SetCompanyWebhook(request, {});
  }

  async getWeyHookById(companyId: any) {
    const request = new AccountApi.core.account.v1.GetCompanyWebhookRequest({
      companyId
    });
    return this.client.GetCompanyWebhook(request, {});
  }

  async updateCompany(companyId: string, companyFront: Company) {
    const company: Account.core.account.v1.Company = new Account.core.account.v1.Company({
      id: companyId,
      name: companyFront.name,
      document: removeSymbol(companyFront.document),
      enabled: true,
    });
    const request = new AccountApi.core.account.v1.UpdateCompanyRequest({
      company,
    });
    return this.client.UpdateCompany(request, {});
  }

  async removeCompany(companyId: string) {
    const request = new AccountApi.core.account.v1.DeleteCompanyRequest({
      companyId,
    });
    return this.client.DeleteCompany(request, {});
  }

  /**
   * CRUD User
   */

  async createUser(localUser: User) {
    const user: Account.core.account.v1.User = new Account.core.account.v1.User({
      sub: localUser.sub,
      name: localUser.name,
      email: localUser.email,
      companyId: localUser.companyId,
      username: localUser.document ?? localUser.username,
      network: localUser.permissions?.network === "1" ? true : false,
      template: localUser.permissions?.template === "1" ? true : false,
      transfer: localUser.permissions?.transfer === "1" ? true : false,
      users: localUser.permissions?.users === "1" ? true : false,
      workflow: localUser.permissions?.workflow === "1" ? true : false,
    });

    const request = new AccountApi.core.account.v1.CreateUserRequest({
      user,
    });
    return this.client.CreateUser(request, {});
  }

  async createCustomer(localUser: Account.core.account.v1.Customer, isAccountCreation?: boolean, password?: string) {
    const request = new AccountApi.core.account.v1.CreateCustomerRequest({
      customer: localUser,
      createCustomerStep: isAccountCreation
        ? AccountApi.core.account.v1.CreateCustomerStep.CREATE_CUSTOMER_FINISH
        : AccountApi.core.account.v1.CreateCustomerStep.CREATE_CUSTOMER_INITIAL,
      password,
    });

    return this.client.CreateCustomer(request, {});
  }

  async createFdic(management: Account.core.account.v1.Management) {
    const request = new AccountApi.core.account.v1.CreateManagementRequest({
      management,
    });

    return this.client.CreateManagement(request, {});
  }

  async editFdic(management: Account.core.account.v1.Management) {
    const request = new AccountApi.core.account.v1.UpdateManagementRequest({
      management,
    });

    return this.client.UpdateManagement(request, {});
  }

  async listFdic() {
    const request = new AccountApi.core.account.v1.ListManagementRequest({
      lastCnpj: null,
      limit: 0,
    });

    return this.client.ListManagement(request, {});
  }

  async deleteFdic(cnpj: string) {
    const request = new AccountApi.core.account.v1.DeleteManagementRequest({
      cnpj,
    });

    return this.client.DeleteManagement(request, {});
  }

  async getUserList(companyId, itemsPerPage?, page?) {
    const request = new AccountApi.core.account.v1.ListUsersRequest({
      companyId,
      itemsPerPage: itemsPerPage ? itemsPerPage : 10,
      page: page ? page : 1,
    });
    return this.client.ListUsers(request, {});
  }

  async getUser(username: string) {
    const request = new AccountApi.core.account.v1.GetUserRequest({
      username,
    });
    return this.client.GetUser(request, {});
  }

  async removeUser(sub: string) {
    const request = new AccountApi.core.account.v1.DeleteUserRequest({
      sub,
    });
    return this.client.DeleteUser(request, {});
  }

  async updateUser(localUser: User) {
    const user: Account.core.account.v1.User = new Account.core.account.v1.User({
      sub: localUser.sub,
      name: localUser.name,
      email: localUser.email,
      username: localUser.document ?? localUser.username,
      enabled: localUser.enabled === "1" ? true : false,
      network: localUser.permissions?.network === "1" ? true : false,
      template: localUser.permissions?.template === "1" ? true : false,
      transfer: localUser.permissions?.transfer === "1" ? true : false,
      users: localUser.permissions?.users === "1" ? true : false,
      workflow: localUser.permissions?.workflow === "1" ? true : false,
      // companyId: localUser.company_id,
      // password: localUser.password,
    });

    const request = new AccountApi.core.account.v1.UpdateUserRequest({
      user,
    });
    return this.client.UpdateUser(request, {});
  }

  async updateEmail(currentEmail: string, newEmail: string, username: string) {
    const request = new AccountApi.core.account.v1.UpdateUserEmailRequest({
      currentEmail,
      newEmail,
      username,
    });
    return this.client.UpdateEmail(request, {});
  }

  async getTerms() {
    const request = new AccountApi.core.account.v1.GetTermsOfServiceRequest({});
    return this.client.GetTermsOfService(request, {});
  }

  createClientApp(companyId, clientName) {
    const clientApi = new AccountApi.core.account.v1.ClientApi({
      companyId,
      clientName,
    });
    const request = new AccountApi.core.account.v1.CreateClientAppRequest({
      clientApi,
    });
    return this.client.CreateClientApp(request, {});
  }

  removeClientApp(clientId: string) {
    const request = new AccountApi.core.account.v1.DeleteClientAppRequest({
      clientId,
    });
    return this.client.DeleteClientApp(request, {});
  }

  listClientApp(companyId) {
    const request = new AccountApi.core.account.v1.ListClientAppRequest({
      companyId,
      page: 1,
      limit: 10,
    });
    return this.client.ListClientApp(request, {});
  }
}
